import { Button, Modal, TextField } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import SelectBuyerModal from "../selectBuyer/SelectBuyerModal";
import { convertTimeToUTC } from "utils/convertTimeToUTC";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "15px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "400px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
    flexDirection: "column",
  },

  button: {
    marginTop: "10px",
    width: "150px",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    margin: theme.spacing(1),
    minWidth: 100,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 120,
  },
}));

const EditLead = ({
  open,
  handleClose,
  patchData,
  buyerMatched,
  setPatchData,
  handleConfirm,
  state,
  infoBuyer,
  getRemainCount,
  getDispute,
  getBuyerSubscriptionPrefix,
}) => {
  const classes = useStyles();

  const handeChangeTime = (e) => {
    console.log(e.target.value);
    setPatchData({
      ...patchData,
      emailDate: convertTimeToUTC(e.target.value),
    });
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        className={classes.modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2
              style={{
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontSize: "20px",
              }}
              id="simple-modal-title"
            >
              Edit Lead
            </h2>
            <div>
              <p
                style={{
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  fontSize: "16px",
                }}
              >
                State : {state}
              </p>
            </div>
            <form className={classes.container}>
              <TextField
                id="time"
                type="time"
                defaultValue={patchData.emailDate}
                onChange={handeChangeTime}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
            </form>
            <SelectBuyerModal
              buyerMatched={buyerMatched}
              patchData={patchData}
              setPatchData={setPatchData}
              getRemainCount={getRemainCount}
              getDispute={getDispute}
              getBuyerSubscriptionPrefix={getBuyerSubscriptionPrefix}
            />
            <div style={{ display: "flex", gap: "10px" }}>
              <Button
                onClick={handleConfirm}
                variant="contained"
                color="primary"
                size="small"
                //className={classes.button}
              >
                Confirm
              </Button>
              <Button
                onClick={handleClose}
                variant="outline"
                color="primary"
                size="small"
                //className={classes.button}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default EditLead;

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import Axios from "axios";
import { history } from "App";
import moment from "moment";
import {
  Grid,
  Button,
  Dialog,
  TextField,
  DialogActions,
  DialogTitle,
  Tooltip,
} from "@material-ui/core";
import { NotificationManager } from "react-notifications";
import { Input, Space, Table } from "antd";
import Swal from "sweetalert2";
import "./style.css";
import { SearchOutlined } from "@ant-design/icons";
import { useRef } from "react";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import Highlighter from "react-highlight-words";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
    //width: "100%",
    height: "50px",
  },

  returnFormControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
}));

const LeadTable = ({ buyerId, roles }) => {
  const searchInput = useRef(null);

  const classes = useStyles();
  const [leads, setLeads] = useState(-1);
  const [count, setCount] = useState(0);
  const [filterSent, setFilterSent] = useState("all");
  const [searchWord, setSearchWord] = useState("");
  const [refreshKey, setRefreshKey] = useState("xxx");
  const [buyer, setBuyer] = useState(-1);
  const [showEditor, setShowEditor] = useState(false);
  const [editorValue, setEditorValue] = useState("");
  const [selectedReturnLead, setSelectedReturnLead] = useState(-1);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  let [filteredLeads, setFilteredLeads] = useState([]);

  const token = localStorage.getItem("token");
  const { name } = JSON.parse(token);

  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("rowsPerPage")
      ? localStorage.getItem("rowsPerPage")
      : 25
  );
  const [page, setPage] = useState(
    localStorage.getItem("buyerpage") ? localStorage.getItem("buyerpage") : 0
  );
  const [order, setOrder] = useState(
    localStorage.getItem("buyerorder")
      ? localStorage.getItem("buyerorder")
      : "desc"
  );
  const [orderBy, setOrderBy] = useState("emailDate");

  useEffect(() => { }, [leads]);
  useEffect(() => { }, [selectedReturnLead]);

  useEffect(() => {
    getBuyerLeads(buyerId);
  }, []);

  const getBuyerLeads = (buyerId) => {
    Axios.get(
      `${process.env.REACT_APP_BACKEND_API
      }/buyerleadlist/${buyerId}?order=${order}&orderBy=${orderBy}&offset=${page *
      rowsPerPage}&limit=200&filterSent=sent`
    )
      .then((response) => {
        setLeads(response.data.leads);
        setCount(response.data.count);
        setFilteredLeads(response.data.leads);
      })
      .catch(function (error) {
        history.push("/sign-in");
      });
  };

  const handleSubmitReason = (id) => {
    let reason = `Returned by ${name} role: ${roles}`;

    const params = {
      leadReturned: true,
      returnReason: reason,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You're going to return this lead",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3f51b5",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      customClass: {
        title: "my-swal-title-class",
        text: "my-swal-text-class",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          Axios.patch(`${process.env.REACT_APP_BACKEND_API}/lead/${id}`, params)
            .then((response) => {
              NotificationManager.success("Return lead success");
              history.go();
            })
            .catch(function (error) {
              NotificationManager.error(error);
            });
        } catch (error) {
          throw error;
        }
      }
    });
  };

  const handleSearchInputChange = (e) => {
    const searchText = e.target.value.toLowerCase();
    setSearchWord(searchText);

    const filteredData = leads.filter((lead) =>
      lead.name.toLowerCase().includes(searchText)
    );

    setFilteredLeads(filteredData);
  };

  const canReturnUnableToContact = () => {
    if (selectedReturnLead === -1 || selectedReturnLead.leadReturned)
      return true;
    const today = new Date();

    const days = Math.round(
      (today - moment(selectedReturnLead.emailDate).toDate()) /
      (1000 * 60 * 60 * 24)
    );
    if (days >= 5 && days <= 7) {
      return true;
    }

    return false;
  };

  const handleEditorNO = () => {
    setShowEditor(false);
  };

  const handleChangeEditor = (e) => {
    setEditorValue(e.target.value);
  };

  const handleEditorYES = () => {
    setShowEditor(false);
    const params = {
      fromEmail: "newcompasslead@gmail.com",
      toEmail: "compassdatapro@gmail.com",
      subject: `${buyer.name}(${buyer.email}) sent a ticket - ${moment(
        new Date()
      ).format("MM/DD/YY")}`,
      content: editorValue,
    };
    Axios.post(`${process.env.REACT_APP_BACKEND_API}/buyer/ticket`, params)
      .then((response) => {
        NotificationManager.success("Send ticket success");
      })
      .catch(function (error) {
        NotificationManager.error("failed send ticket");
      });
  };

  if (leads === -1) {
    return <div>loading...</div>;
  }

  let dataSource = [];

  if (Array.isArray(filteredLeads)) {
    dataSource = filteredLeads?.map((lead) => {
      return {
        key: lead.id,
        name: lead.name,
        phone: lead.phone,
        email: lead.email,
        address: lead.address,
        city: lead.city,
        state: lead.state,
        zipcode: lead.zipcode,
        county: lead.county,
        notes: lead.notes,
        feedback: lead.feedback,
        leadReturned: lead.leadReturned,
        returnReason: lead.returnReason,
        emailDate: lead.emailDate,
      };
    });
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Contact information",
      dataIndex: "phone",
      key: "phone",
      width: 200,
      render: (lead, record) => {
        return <span>{`${record.phone}, ${record.email}`}</span>;
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "4",
      render: (lead, record) => {
        return (
          <span>
            {`${record.address} -
           ${record.city} -
            ${record.state} ${record.zipcode}`}{" "}
          </span>
        );
      },
    },
    {
      title: "County",
      dataIndex: "county",
      key: "8",
    },
    {
      title: "Received",
      dataIndex: "emailDate",
      key: "emailDate",
      sorter: (a, b) => moment(a.emailDate).unix() - moment(b.emailDate).unix(),
      render: (emailDate) => {
        return moment(emailDate).format("MM/DD/YY");
      },
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "9",
      render: (notes, record) => {
        return (
          <span style={{ width: 80, display: "inline-block" }}>{notes}</span>
        );
      },
    },
    {
      title: "Return Reason",
      dataIndex: "returnReason",
      key: "10",
      render: (returnReason, record) => {
        const truncatedReason = returnReason.slice(0, 50);
        return (
          <span style={{ width: 80, display: "inline-block" }}>
            {truncatedReason}
          </span>
        );
      },
    },
    {
      title: "Returned",
      dataIndex: "leadReturned",
      key: "11",
      render: (lead, record) => {
        const { key } = record;

        return (
          <Button
            disabled={record.leadReturned ? true : false}
            variant="outlined"
            size="small"
            onClick={() => handleSubmitReason(key)}
          >
            Return
          </Button>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "12",
      render: (lead, record) => {
        return (
          <Tooltip title="Lead detail">
            <Button
              variant="outlined"
              size="small"
              onClick={(event) => {
                console.log("record", record);
                history.push(`/addlead?id=${record.key}`);
              }}
            >
              <AssignmentIndIcon />
            </Button>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <div className={classes.root} key={refreshKey}>
      <Grid lg={12} md={12} xl={12} xs={12}>
        <Input
          type="text"
          placeholder="Search name"
          onChange={handleSearchInputChange}
          value={searchWord}
          allowClear
          prefix={<SearchOutlined />}
          style={{ marginBottom: "10px" }}
        />
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={{ pageSize: 100 }}
          scroll={{ y: 700, x: true }}
        />
      </Grid>
      <Dialog
        open={showEditor}
        keepMounted
        onClose={handleEditorNO}
        fullWidth
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Do you want to submit a Ticket?
        </DialogTitle>
        <Grid md={12} xs={12} style={{ padding: "20px" }}>
          <TextField
            label="ticket details"
            multiline
            rows={4}
            fullWidth
            name="ticket details"
            variant="outlined"
            onChange={handleChangeEditor}
            value={editorValue}
          />
        </Grid>
        <DialogActions>
          <Button onClick={handleEditorYES} color="primary">
            YES
          </Button>
          <Button onClick={handleEditorNO} color="primary">
            NO
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LeadTable;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { NotificationManager } from "react-notifications";
import Axios from "axios";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  Dialog,
  DialogActions,
  Paper,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Grid,
  TableRow,
  TextField,
  TableContainer,
  TablePagination,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import parse from "csv-parse";
import { history } from "App";
import { clear } from "i/lib/inflections";

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  pasteButton: {
    marginRight: theme.spacing(1),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },

  exportButton: {
    marginRight: theme.spacing(1),
  },
  paper1: {
    padding: theme.spacing(2),
    textAlign: "center",
    fontWeight: "bold",
    marginRight: theme.spacing(1),
    color: "#FF4444",
  },
  paper2: {
    fontWeight: "bold",
    padding: theme.spacing(2),
    textAlign: "center",
    marginRight: theme.spacing(1),
    color: "#8BDD64",
  },
  divGrid: {
    marginLeft: "30%",
    height: "52px",
    marginTop: theme.spacing(1),
  },
  grid: {
    marginBottom: "1%",
  },
}));

const LeadsToolbar = (props) => {
  const { className, stopApp, setStopApp, role, setLeads, ...rest } = props;
  const [uploadedFile, setUploadFile] = useState(null);
  const [parsedCSV, setParsedCSV] = useState([]);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [showCSV, setShowCSV] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [editorValue, setEditorValue] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const stopAppBack = () => {
    Axios.post(`${process.env.REACT_APP_BACKEND_API}/stop`)
      .then((response) => {
        if (response.data.stop === "true") {
          localStorage.setItem("stopApp", true);
          setStopApp(true);
        }
        NotificationManager.success("Stop app");
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  const startAppBack = () => {
    Axios.post(`${process.env.REACT_APP_BACKEND_API}/start`)
      .then((response) => {
        if (response.data.stop === "false") {
          localStorage.setItem("stopApp", false);
          setStopApp(false);
        }
        NotificationManager.success("Start app");
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  const handleCSVSubmit = (event) => {
    event.preventDefault();

    const reader = new FileReader();
    reader.readAsText(uploadedFile, "UTF-8");
    console.log("uploadedFile parsing");
    reader.onload = function(event) {
      parse(
        event.target.result,
        { columns: true, trim: true },
        (err, parsedCSV) => {
          if (err) {
            NotificationManager.error("Error parsing CSV");
            return;
          }
          setParsedCSV(parsedCSV);

          if (parsedCSV && parsedCSV.length > 0) {
            setOpen(true);
          }
        }
      );
    };
  };

  const handleChangeEditor = (e) => {
    setEditorValue(e.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditorNO = () => {
    setShowEditor(false);
  };

  const handleEditorYES = () => {
    setShowEditor(false);
    let str = editorValue;
    str = `"${str}`;
    str = str.replace(/\t/g, '", "');
    str = str.replace(/\n/g, '",,,\n"');
    str = str.substring(0, str.length - 2);
    parse(str, { columns: true, trim: true }, (err, parsedCSV) => {
      setParsedCSV(parsedCSV);

      if (parsedCSV && parsedCSV.length > 0) {
        setOpen(true);
      }
    });
  };

  const handleYES = () => {
    setOpen(false);
    setShowCSV(true);
  };

  const handleNO = async () => {
    setOpen(false);
    const params = {
      parsedCSV,
    };

    postCSV(params);
  };

  const sendLead = async (index) => {
    const params = {
      parsedCSV: [parsedCSV[index]],
    };
    try {
      await postCSV(params);

      let data = [...parsedCSV];
      data.splice(index, 1);
      setParsedCSV(data);
    } catch (error) {}
  };

  const sendTrash = (index) => {
    let data = [...parsedCSV];
    data.splice(index, 1);
    setParsedCSV(data);
  };

  const postCSV = (params) => {
    Axios.post(`${process.env.REACT_APP_BACKEND_API}/lead/importCSV`, params)
      .then((response) => {
        console.log(response.data);
        localStorage.removeItem("leads");
        localStorage.removeItem("buyers");
        setLeads(null);
        NotificationManager.success("import Lead success");
      })
      .catch(function(error) {
        localStorage.removeItem("leads");
        localStorage.removeItem("buyers");
        setLeads(null);
        NotificationManager.error("Error import Lead", error);
      })
      .finally(() => {
        localStorage.removeItem("leads");
        localStorage.removeItem("buyers");
        setLeads(null);
      });
  };

  const handleCloseCSV = () => {
    setShowCSV(false);
  };

  const handleAddLead = () => {
    history.push("/addlead");
  };

  const clearLocalStorage = () => {
    localStorage.removeItem("leads");
    localStorage.removeItem("buyers");
  };

  const handleOpenImport = () => {
    setEditorValue("");
    setShowEditor(true);
  };

  var stoped = "The application is stopped";
  var started = "The application is started";

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      {role === "ROLE_ADMIN" && (
        <div className={classes.row}>
          {stopApp === true && (
            <Paper className={classes.paper1}>{stoped}</Paper>
          )}
          {stopApp === false && (
            <Paper className={classes.paper2}>{started}</Paper>
          )}
          <Button
            disabled={ stopApp === true ? true : false }
            onClick={stopAppBack}
            variant="outlined"
            size="small"
            color="primary"
            className={classes.importButton}>
            Stop app
          </Button>
          <Button
            disabled={ stopApp === false ? true : false }
            onClick={ startAppBack }
            variant="outlined"
            size="small"
            color="primary"
            className={classes.importButton}>
            Start app
          </Button>
          <span className={classes.spacer} />
          <input
            id="file"
            name="file"
            type="file"
            onChange={(event) => {
              setUploadFile(event.currentTarget.files[0]);
            }}
            className="form-control"
          />
          <Button
            disabled={uploadedFile ? false : true}
            onClick={(e) => handleCSVSubmit(e)}
            variant="outlined"
            size="small"
            startIcon={<ImportExportIcon />}
            className={classes.importButton}
          >
            CSV Import
          </Button>
          <Button
            onClick={handleOpenImport}
            className={classes.pasteButton}
            color="secondary"
            variant="outlined"
            startIcon={<FileCopyIcon />}
            size="small"
          >
            Import
          </Button>
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={handleAddLead}
            startIcon={<AddIcon />}
          >
            Add Lead
          </Button>
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={clearLocalStorage}
            // startIcon={<AddIcon />}
          >
            Clear Local Storage
          </Button>
        </div>
      )}

      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Leads to Verify?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleYES} color="primary">
            YES
          </Button>
          <Button onClick={handleNO} color="primary">
            NO
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showEditor}
        keepMounted
        onClose={handleEditorNO}
        fullWidth
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Paste Leads from spreadsheet?
        </DialogTitle>
        <Grid>
          <TextField
            label="Paste"
            multiline
            minRows={4}
            fullWidth
            name="paste"
            variant="outlined"
            onChange={handleChangeEditor}
            value={editorValue}
          />
        </Grid>
        <DialogActions>
          <Button onClick={handleEditorYES} color="primary">
            YES
          </Button>
          <Button onClick={handleEditorNO} color="primary">
            NO
          </Button>
        </DialogActions>
      </Dialog>

      {showCSV && (
        <Dialog
          open={showCSV}
          keepMounted
          onClose={handleCloseCSV}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <Paper className={classes.paper}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Phone Number</TableCell>
                    <TableCell>Email Address</TableCell>
                    <TableCell>Address</TableCell>
                    <TableCell>City</TableCell>
                    <TableCell>State</TableCell>
                    <TableCell>Zip Code</TableCell>
                    <TableCell>County</TableCell>
                    <TableCell>Public Notes</TableCell>
                    <TableCell>Private Notes</TableCell>
                    <TableCell>Sell</TableCell>
                    <TableCell>Trash</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {parsedCSV.map((lead, index) => {
                    return (
                      <TableRow className={classes.tableRow} key={index} hover>
                        <TableCell>{lead["Name"]}</TableCell>
                        <TableCell>{lead["Phone Number"]}</TableCell>
                        <TableCell>{lead["Email Address"]}</TableCell>
                        <TableCell>{lead["Address"]}</TableCell>
                        <TableCell>{lead["City"]}</TableCell>
                        <TableCell>{lead["State"]}</TableCell>
                        <TableCell>{lead["Zip Code"]}</TableCell>
                        <TableCell>{lead["County"]}</TableCell>
                        <TableCell>{lead["Public Notes"]}</TableCell>
                        <TableCell>{lead["Private Notes"]}</TableCell>
                        <TableCell>
                          <Button
                            variant="outlined"
                            size="small"
                            disabled={lead.leadSold}
                            onClick={() => sendLead(index)}
                          >
                            Sell
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="outlined"
                            size="small"
                            disabled={lead.leadSold}
                            onClick={() => sendTrash(index)}
                          >
                            Trash
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={parsedCSV.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </Dialog>
      )}
    </div>
  );
};

LeadsToolbar.propTypes = {
  className: PropTypes.string,
};

export default LeadsToolbar;

/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { confirmAlert } from "react-confirm-alert";
import Axios from "axios";
import { NotificationManager } from "react-notifications";
import { makeStyles } from "@material-ui/styles";
import { history } from "App";
import {
  getBuyersFromCounty,
  truncateString,
  getBackgroundColor,
  getRemainCount,
  getDispute,
  getLastLead,
} from "../../../../common/utils";
import { feedback } from "../../../../common/constants";
import moment from "moment";
import PopupState, { bindTrigger } from "material-ui-popup-state";
import {
  Card,
  DialogContentText,
  CardContent,
  Select,
  FormControl,
  Table,
  DialogContent,
  TableBody,
  TableCell,
  Dialog,
  TableRow,
  Button,
  DialogTitle,
  TablePagination,
  Tooltip,
  TableContainer,
  DialogActions,
  Paper,
  TextField,
  MenuItem,
  IconButton,
  Checkbox,
  Popover,
  List,
  ListItem,
  Icon,
} from "@material-ui/core";
import { Delete as DeleteIcon, Send as SendIcon } from "@material-ui/icons";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import EnhancedTableHead, {
  headCellsBuyer,
  headCellsLead,
  headCellsZZZ,
  headCellsUser,
} from "./EnhancedTableHead";
import SelectBuyer from "../Select/SelectBuyer";
import TimePickers from "../TimePicker/TimePicker";
import { getTimeZone } from "helpers/getTimeZone";
import orderLeads from "utils/orderLeads";
//import SendIcon from "@material-ui/icons/Send";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  head: {
    textAlign: "center",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableCell: {
    maxWidth: "100%",
    padding: "1px",
    overflowWrap: "break-word",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  timepickRadio: {
    padding: "20px",
  },
  container: {
    maxHeight: 640,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    maxWidth: "100px",
  },
  returnFormControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  capTextTransform: {
    textTransform: "capitalize",
  },
  select: {
    maxWidth: 160,
    padding: "2px",
    overflowWrap: "break-word",
    marginRight: "30px",
  },
  pickers: {
    width: 50,
    padding: "2px",
  },
  buttonSend: {
    width: 100,
    padding: "20px",
    marginLeft: "5px",
  },
}));

const LeadsTable = (props) => {
  const {
    className,
    searchWord,
    searchBuyer,
    leads,
    filterSent,
    onChangeFilterSent,
    filterBilling,
    onChangeFilterBilling,
    onChangeSearch,
    onChangeSearchBuyer,
    onSendLead,
    onChangeBuyer,
    onChangeFeedback,
    buyerId,
    isBuyer,
    allowReturn,
    onChangeTrash,
    onSendLeadToForm,
    includeTrash,
    filterSubscription,
    onChangeFilterSubscription,
    count,
    rowsPerPage,
    page,
    setPage,
    setRowsPerPage,
    order,
    orderBy,
    setOrder,
    setOrderBy,
    setLeads,
    buyers,
    role,
    ...rest
  } = props;

  const classes = useStyles();

  const [headCells, setHeadCells] = React.useState(headCellsLead);
  const [reasonOpen, setReasonOpen] = useState(false);
  const [editLeadId, setEditLeadId] = useState(-1);
  const [selectedReturnLead, setSelectedReturnLead] = useState(-1);
  const [editLeadValue, setEditLeadValue] = useState("");
  const [returnReason, setReturnReason] = useState("");
  const [returnReasonOption, setReturnReasonOption] = useState("");
  const [selectedData, setSelectedData] = useState({});
  const [selected, setSelected] = React.useState([]);
  
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [sendLeaderData, setSendLeaderData] = useState({
    id: "",
    dateSent: "",
  });

  const [selectedLead, setSelectedLead] = useState(-1);
  const [isApi, setIsApi] = useState(false);

  useEffect(() => {
    let headcell = buyerId ? headCellsBuyer : headCellsLead;
    headcell = isBuyer ? headCellsUser : headcell;
    headcell = buyerId === "42" || buyerId === "58" ? headCellsZZZ : headcell;

    const objetPrivate = {
      id: "privateNotes",
      numeric: false,
      label: "Private Notes",
      selected: true,
    };

    const objetAction = {
      id: "action",
      numeric: true,
      label: "Action",
      selected: true,
    };

    const existObjetPrivate =
      headcell.find((item) => item.id === objetPrivate.id) !== undefined;

    const existObjetActione =
      headcell.find((item) => item.id === objetPrivate.id) !== undefined;

    if (!existObjetPrivate && role === "ROLE_ADMIN" && !existObjetActione) {
      headcell.splice(8, 0, objetPrivate);
      headcell.splice(10, 0, objetAction);
    }
    setHeadCells(headcell);
  }, [buyerId, isBuyer]);

  const handleRequestSort = async (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    await setOrder(isAsc ? "desc" : "asc");
    await setOrderBy(property);

    await orderLeads(property);

    setLeads(JSON.parse(localStorage.getItem("leads")));
  };

  const handleClick = (event, name) => {
    event.preventDefault();
    history.push(`/addlead?id=${name}&isBuyer=${isBuyer}`);
  };

  const handleTrashClick = (event, leadId) => {
    event.preventDefault();
    const params = {
      verify: 3,
    };

    if (leadId !== -1) {
      Axios.patch(`${process.env.REACT_APP_BACKEND_API}/lead/${leadId}`, params)
        .then((response) => {
          NotificationManager.success("Lead deleted")
          localStorage.removeItem('leads');
          localStorage.removeItem('buyers');
          setLeads(null);
        })
        .catch((error) => {
          NotificationManager.error("Trash Failed");
        });
    }
  };

  const handleChangePage = (event, newPage) => {
    localStorage.removeItem('leads')
    localStorage.removeItem('buyers')
    setLeads(null)
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    localStorage.removeItem('leads')
    localStorage.removeItem('buyers')
    setLeads(null)
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseReason = () => {
    setReasonOpen(false);
  };

  const handleSubmitReason = () => {
    let reason = returnReason;
    if (returnReasonOption !== "Other") {
      reason = returnReasonOption;
    } else {
      if (reason.length < 10 || reason.length > 255) {
        NotificationManager.error("Please type more than 10 characters");
        return;
      }
    }
    if (reason === "") {
      NotificationManager.error("Please type more than 10 characters");
      return;
    }
    const params = {
      leadReturned: true,
      returnReason: reason,
    };

    Axios.patch(
      `${process.env.REACT_APP_BACKEND_API}/lead/${selectedReturnLead.id}`,
      params
    )
      .then((response) => {
        NotificationManager.success("Return lead success");
        history.go();
      })
      .catch(function(error) {
        NotificationManager.error(error);
      });
  };

  const handleChangeBuyer = (id, buyerId) => {
    onChangeBuyer(id, buyerId);
  };

  const handleSendScheduleEmail = (leadId, scheduleOption, popupState) => {
    if (popupState) {
      popupState.close();
    }
    let dateSent = new Date();
    if (scheduleOption === 1) {
      dateSent.setMinutes(dateSent.getMinutes() + 53);
    } else if (scheduleOption === 2) {
      dateSent.setHours(dateSent.getHours() + 1);
      dateSent.setMinutes(dateSent.getMinutes() + 23);
    } else if (scheduleOption === 3) {
      dateSent.setHours(dateSent.getHours() + 1);
      dateSent.setMinutes(dateSent.getMinutes() + 47);
    } else if (scheduleOption === 4) {
      dateSent.setHours(dateSent.getHours() + 2);
      dateSent.setMinutes(dateSent.getMinutes() + 11);
    } else if (scheduleOption === 5) {
      dateSent.setHours(dateSent.getHours() + 2);
      dateSent.setMinutes(dateSent.getMinutes() + 39);
    } else if (scheduleOption === 6) {
      dateSent.setHours(dateSent.getHours() + 3);
      dateSent.setMinutes(dateSent.getMinutes() + 17);
    } else if (scheduleOption === 7) {
      dateSent.setHours(dateSent.getHours() + 3);
      dateSent.setMinutes(dateSent.getMinutes() + 47);
    } else if (scheduleOption === 8) {
      dateSent.setHours(dateSent.getHours() + 4);
      dateSent.setMinutes(dateSent.getMinutes() + 27);
    } else if (scheduleOption === 9) {
      dateSent.setHours(dateSent.getHours() + 5);
      dateSent.setMinutes(dateSent.getMinutes() + 12);
    } else if (scheduleOption === 10) {
      dateSent.setHours(dateSent.getHours() + 5);
      dateSent.setMinutes(dateSent.getMinutes() + 51);
    } else if (scheduleOption === 11) {
      dateSent.setHours(dateSent.getHours() + 6);
      dateSent.setMinutes(dateSent.getMinutes() + 37);
    } else if (scheduleOption === 12) {
      dateSent.setHours(dateSent.getHours() + 7);
      dateSent.setMinutes(dateSent.getMinutes() + 21);
    }

    onSendLead(leadId, dateSent.toUTCString());
  };

  const getVerify = (verify) => {
    switch (verify) {
      case 0:
        return "NO";
      case 1:
        return "Not - verified";
      case 2:
        return "Yes - verified";
      case 3:
        return "Trash";
      default:
        return "NO";
    }
  };

  const getReturned = (returned) => {
    switch (returned) {
      case false:
        return "NO";
      case true:
        return "YES";
      default:
        return "NO";
    }
  };

  const handleSubmit = (id) => {
    const params = {
      notes: editLeadValue,
    };

    if (id !== -1) {
      Axios.patch(`${process.env.REACT_APP_BACKEND_API}/lead/${id}`, params)
        .then((response) => {
          history.go();
        })
        .catch(function(error) {
          NotificationManager.error(error);
        });
    }
  };

  const getReturnDisable = (lead) => {
    if (lead.leadReturned) return true;
    const today = new Date();

    const days = Math.round(
      (today - moment(lead.emailDate).toDate()) / (1000 * 60 * 60 * 24)
    );

    if (days > 7) {
      return true;
    } else if (days >= 0 && days <= 7) {
      return false;
    } else {
      return true;
    }
  };
 const handleReturn = (lead) => {
  // return reason: the lead returned for <name-admin-buyer>
  const buyerLogued = JSON.parse(localStorage.getItem('token'))

  lead.returnReason = `Lead returned by ${buyerLogued.name}`
  lead.leadSold = false;
  lead.emailSent = false;
  lead.smsSent = false;
  
  Axios.patch(`${process.env.REACT_APP_BACKEND_API}/leadReturned/${lead.id}`, lead)
  .then((res) => {
    if(res.data.msg === "Success!!"){
      localStorage.removeItem('leads');
      localStorage.removeItem('buyers');
      NotificationManager.success(res.data.msg);
      setLeads(null)
    } else {
      NotificationManager.error("Something is weird");
    }
  })
  .catch((error) => {
    console.log(error)
    NotificationManager.error('Error');
    if(error.response.status === 429){
      history.push("too-many-request");
    }
  })
 } 
  const onChangeFilterHead = (event) => {
    const heads = headCells.map((e) => {
      if (e.id === event.target.name) {
        e.selected = !e.selected;
        // console.log(e.selected);
      }
      return e;
    });
    setHeadCells(heads);
  };

  const getBuyerSubscriptionPrefix = (buyer) => {
    if (buyer.subscriptions && buyer.subscriptions[0]) {
      const subscriptionName = buyer.subscriptions[0].name;
      if (subscriptionName === "NO") return "";
      return `(${subscriptionName.substring(0, 1).toUpperCase()})`;
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = leads.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleCheckClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const onDeleteAll = () => {
    const params = {
      verify: 3,
      selected,
    };

    if (selected.length > 0) {
      Axios.post(`${process.env.REACT_APP_BACKEND_API}/trashlead`, params)
        .then((response) => {
          if (response.status === 200) {
            NotificationManager.success("Leads deleted");
            localStorage.removeItem("leads");
            localStorage.removeItem("buyers");
            setLeads(null);
          }
        })
        .catch((error) => {
          NotificationManager.error("Trash Failed");
          if(error.response.status === 429){
            history.push("too-many-request");
          }
        });
    }
  };

  const handleReturnReasonOptionChange = (e) => {
    if (e.target.value === "Unable to Contact") {
      confirmAlert({
        title: "Alert",
        message: `Have you called the lead at least 12 times sent 5 text messages and 2 emails?`,
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              setReturnReasonOption(e.target.value);
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    } else {
      setReturnReasonOption(e.target.value);
    }
  };

  const canReturnUnableToContact = () => {
    if (selectedReturnLead === -1 || selectedReturnLead.leadReturned)
      return true;
    const today = new Date();

    const days = Math.round(
      (today - moment(selectedReturnLead.emailDate).toDate()) /
        (1000 * 60 * 60 * 24)
    );
    if (days >= 5 && days <= 7) {
      return true;
    }

    return false;
  };

  const isSelected = (id) => {
    if (!selected) return false;
    return selected.indexOf(id) !== -1;
  };

  // const handleBuyerSelectOpen = (event, lead) => {
  //   setAnchorEl(event.currentTarget);
  //   setSelectedLead(lead);
  // };

  const handleBuyerSelectClose = () => {
    setAnchorEl(null);
    setSelectedLead(-1);
  };

  const convertTimeToUTC = (timeStr) => {
    const referenceDate = new Date();

    const [hours, minutes] = timeStr.split(":").map(Number);

    const date = new Date(referenceDate);

    date.setUTCHours(hours);
    date.setUTCMinutes(minutes);

    const utcTimeString = date.toUTCString();

    return utcTimeString;
  };

  const postLeader = async (lead, index) => {
    //await handleUpdateLeadsLocal(index);

    if (sendLeaderData.id === "" && sendLeaderData.dateSent === "") {
      const updatedSendLeaderData = {
        id: lead.buyerMatched[0].id,
        dateSent: convertTimeToUTC(lead.time),
      };

      await onChangeBuyer(
        lead.id,
        updatedSendLeaderData.id,
        updatedSendLeaderData.dateSent
      );

      setSendLeaderData({
        id: "",
        dateSent: "",
      });
      return;
    }

    if (sendLeaderData.dateSent === "") {
      const updatedSendLeaderData = {
        id: sendLeaderData.id,
        dateSent: convertTimeToUTC(lead.time),
      };

      await onChangeBuyer(
        lead.id,
        updatedSendLeaderData.id,
        updatedSendLeaderData.dateSent
      );
      setSendLeaderData({
        id: "",
        dateSent: "",
      });
      return;
    }

    if (sendLeaderData.id === "") {
      const updatedSendLeaderData = {
        id: lead.buyerMatched[0].id,
        dateSent: sendLeaderData.dateSent,
      };

      await onChangeBuyer(
        lead.id,
        updatedSendLeaderData.id,
        updatedSendLeaderData.dateSent
      );
      setSendLeaderData({
        id: "",
        dateSent: "",
      });
      return;
    }

    await onChangeBuyer(lead.id, sendLeaderData.id, sendLeaderData.dateSent);
    setSendLeaderData({
      id: "",
      dateSent: "",
    });
  };

  /**
   * Update the ocal storage with the index
   * of lead, after update the array of leads
   */

  const handleUpdateLeadsLocal = async (index) => {
    
    await leads.splice(index, 1);
    localStorage.setItem("leads", JSON.stringify(leads));
  };

  const postLeadNow = async (lead, index) => {
    const zoneTime = getTimeZone(lead.state);
    let dateSent = new Date();
    dateSent.setHours(dateSent.getHours() - 1 + zoneTime);

    await handleUpdateLeadsLocal(index);

    if (sendLeaderData.id == "" && sendLeaderData.dateSent == "") {
      const updatedSendLeaderData = {
        id: lead.buyerMatched[0].id,
        dateSent: convertTimeToUTC(lead.time),
      };

      await onChangeBuyer(
        lead.id,
        updatedSendLeaderData.id,
        dateSent.toUTCString()
      );

      setSendLeaderData({
        id: "",
        dateSent: "",
      });
      return;
    }

    await onChangeBuyer(lead.id, sendLeaderData.id, dateSent);
    setSendLeaderData({
      id: "",
      dateSent: "",
    });
  };

  const postLeadInApi = async (lead) => {
    try {
      const body = lead;

      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/lead/sendLeadsApi`,
        body
      );

      NotificationManager.success(`${response.data.msg}`);

      return response;
    } catch (error) {
      // console.log(error);
      NotificationManager.error("Send Lead error");
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  function formatPhoneNumber(phoneNumber) {
    const areaCode = phoneNumber.slice(0, 3);
    const firstPart = phoneNumber.slice(3, 6);
    const secondPart = phoneNumber.slice(6, 10);
    return `${areaCode}-${firstPart}-${secondPart}`;
  }
  const cellStyle = {
    whiteSpace: "nowrap",
  };

  if (headCells.length === 0) return <div />;
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <Paper className={classes.paper}>
          <EnhancedTableToolbar
            role={role}
            searchWord={searchWord}
            onChange={onChangeSearch}
            searchBuyer={searchBuyer}
            onChangeBuyer={onChangeSearchBuyer}
            onChangeFilterSent={onChangeFilterSent}
            filterSent={filterSent}
            onChangeTrash={onChangeTrash}
            includeTrash={includeTrash}
            filterBilling={filterBilling}
            isBuyer={isBuyer}
            onChangeFilterBilling={onChangeFilterBilling}
            filterSubscription={filterSubscription}
            onChangeFilterSubscription={onChangeFilterSubscription}
            headCells={headCells}
            onChangeFilterHead={onChangeFilterHead}
            numSelected={selected.length}
            onDeleteAll={onDeleteAll}
          />
          <TableContainer className={classes.container}>
            <Table className={classes.table} stickyHeader>
              <EnhancedTableHead
                leads={leads}
                setLeads={setLeads}
                classes={classes}
                order={order}
                orderBy={orderBy}
                buyerId={buyerId}
                isBuyer={isBuyer}
                onSelectAllClick={handleSelectAllClick}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                rowCount={leads ? leads.length : 0}
                headCells={headCells}
              />
              <TableBody>
                {leads &&
                  leads.map((lead, index) => {
                    const isItemSelected = isSelected(lead.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        className={classes.tableRow}
                        key={lead.id}
                        role="checkbox"
                        tabIndex={-1}
                        hover
                        style={{ backgroundColor: getBackgroundColor(lead) }}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onClick={(event) =>
                              handleCheckClick(event, lead.id)
                            }
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          onClick={(event) => handleClick(event, lead.id)}
                        >
                          {lead.name}
                        </TableCell>

                        {!isBuyer && (
                          <TableCell
                            className={classes.tableCell}
                            onClick={(event) => handleClick(event, lead.id)}
                          >
                            {formatPhoneNumber(lead.phone)}
                            <br />
                            <span style={cellStyle}>{lead.email.toLowerCase()}</span>
                          </TableCell>
                        )}
                        {!isBuyer && (
                          <TableCell
                            className={classes.tableCell}
                            onClick={(event) => handleClick(event, lead.id)}
                          >
                            <span className={cellStyle}>
                              {lead.address},<br />
                            </span>
                            <span className={cellStyle}>
                              {lead.city}, {lead.state} {lead.zipcode}
                            </span>
                          </TableCell>
                        )}
                        <TableCell
                          className={classes.tableCell}
                          onClick={(event) => handleClick(event, lead.id)}
                        >
                          {lead.county}
                        </TableCell>

                        {!isBuyer && role === "ROLE_ADMIN" && (
                          <Tooltip title={lead.privateNotes}>
                            <TableCell
                              className={classes.tableCell}
                              onClick={(event) => handleClick(event, lead.id)}
                            >
                              {truncateString(lead.privateNotes)}
                            </TableCell>
                          </Tooltip>
                        )}

                        {!lead.buyerMatched && (
                          <TableCell className={classes.select}>
                            {lead.buyers[0].name}
                          </TableCell>
                        )}
                        {!buyerId && !isBuyer && lead.buyerMatched && (
                          <TableCell className={classes.select}>
                            <SelectBuyer
                              key={index + lead.id}
                              role={role}
                              selectedLead={selectedLead}
                              lead={lead}
                              leads={leads}
                              setLeads={setLeads}
                              getBuyerSubscriptionPrefix={
                                getBuyerSubscriptionPrefix
                              }
                              handleChangeBuyer={handleChangeBuyer}
                              onChangeBuyer={onChangeBuyer}
                              setSelectedData={setSelectedData}
                              selectedData={selectedData}
                              setSendLeaderData={setSendLeaderData}
                              sendLeaderData={sendLeaderData}
                              setIsApi={setIsApi}
                              isApi={isApi}
                            />
                          </TableCell>
                        )}

                        {!isBuyer && role === "ROLE_ADMIN" && (
                          <TableCell className={classes.tableCell}>
                            <PopupState
                              key={lead.id}
                              variant="popover"
                              popupId="demo-popup-menu"
                            >
                              {(popupState) => (
                                <div>
                                  <IconButton
                                    aria-label="Trash"
                                    size="small"
                                    style={{ marginLeft: "10px" }}
                                    disabled={!lead.buyerMatched || lead.buyerMatched[0].name === "" ? true : false}
                                    {...bindTrigger(popupState)}
                                  >
                                    <SendIcon
                                      onClick={() => postLeader(lead)}
                                    />
                                  </IconButton>
                                  <TimePickers
                                    lead={lead}
                                    leads={leads}
                                    index={index}
                                    setLeads={setLeads}
                                    setSendLeaderData={setSendLeaderData}
                                    sendLeaderData={sendLeaderData}
                                    convertTimeToUTC={convertTimeToUTC}
                                  />
                                </div>
                              )}
                            </PopupState>
                          </TableCell>
                        )}
                        {!isBuyer &&
                          role === "ROLE_ADMIN" &&
                          headCells.filter((e) => e.id === "sendNow")[0]
                            .selected && (
                            <TableCell className={classes.buttonSend}>
                              <Button
                                variant="contained"
                                color="primary"
                                disabled={!lead.buyerMatched || lead.buyerMatched[0].name === "" ? true : false}
                                onClick={() => postLeadNow(lead, index)}
                                size="small"
                              >
                                Send Now
                              </Button>
                            </TableCell>
                          )}

                        {!isBuyer &&
                          headCells.filter((e) => e.id === "notes")[0]
                            .selected && (
                            <Tooltip title={lead.notes ? lead.notes : ""}>
                              <TableCell
                                className={classes.tableCell}
                                onClick={(event) => handleClick(event, lead.id)}
                              >
                                {truncateString(lead.notes)}
                              </TableCell>
                            </Tooltip>
                          )}
                        
                        {!isBuyer && (
                          <TableCell
                            className={classes.tableCell}
                            onClick={(event) => handleClick(event, lead.id)}
                          >
                            {getVerify(lead.verify)}
                          </TableCell>
                        )}
                        <TableCell
                          className={classes.tableCell}
                          onClick={(event) => handleClick(event, lead.id)}
                        >
                          {lead.emailDate
                            ? isBuyer
                              ? moment(lead.emailDate).format("MM/DD/YY")
                              : moment(lead.emailDate).format("MM/DD/YY HH:mm")
                            : ""}
                        </TableCell>
                        {buyerId && (
                          <TableCell>
                            {lead.subscriptions && lead.subscriptions[0]
                              ? lead.subscriptions[0].name
                              : "NO"}
                          </TableCell>
                        )}
                        {isBuyer && (
                          <TableCell className={classes.tableCell}>
                            {getReturned(lead.leadSold)}
                          </TableCell>
                        )}
                        <TableCell className={classes.tableCell}>
                          {getReturned(
                            lead.buyers &&
                              lead.buyers.length > 0 &&
                              lead.buyers[0].email === "ZZZ@compass.com"
                              ? lead.leadForm
                              : lead.leadReturned
                          )}
                        </TableCell>
                        {
                          lead.leadSold && (

                        <TableCell className={classes.tableCell}>
                          {lead.returnReason}
                          <br/>
                          <Button
                            onClick={() => {handleReturn(lead)}}
                            variant="outlined"
                            size="small"
                          >
                            Return
                          </Button>
                        </TableCell>
                          )
                        } 
                        {
                          !lead.leadSold && (
                            <TableCell>
                              {lead.returnReason}
                            </TableCell>
                          )
                        }
                        
                        {/* Feedback */}
                        <TableCell>
                          {lead.feedback}
                        </TableCell>
                        {!isBuyer && (
                          <TableCell className={classes.tableCell}>
                            {lead.verify !== 3 && (
                              <IconButton
                                aria-label="Trash"
                                size="small"
                                onClick={(event) =>
                                  handleTrashClick(event, lead.id)
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={parseInt(count)}
            rowsPerPage={parseInt(rowsPerPage)}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </CardContent>

      <Dialog
        open={reasonOpen}
        onClose={handleCloseReason}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Return Reason</DialogTitle>
        <DialogContent>
          <DialogContentText>Reason for return</DialogContentText>
          <FormControl className={classes.returnFormControl}>
            <Select
              labelId="returnReasonOption"
              id="returnReasonOption"
              value={returnReasonOption}
              onChange={handleReturnReasonOptionChange}
            >
              <MenuItem value={"Listed on MLS"}>Listed on MLS</MenuItem>
              <MenuItem value={"Wholesaler"}>Wholesaler</MenuItem>
              <MenuItem value={"Mobile Home"}>Mobile Home</MenuItem>
              <MenuItem value={"Land"}>Land</MenuItem>
              <MenuItem value={"Not the owner"}>Not the owner</MenuItem>
              <MenuItem value={"Not looking to sell"}>
                Not looking to sell
              </MenuItem>
              <MenuItem value={"Other"}>Other</MenuItem>
              {canReturnUnableToContact() && (
                <MenuItem value={"Unable to Contact"}>
                  Unable to Contact
                </MenuItem>
              )}
            </Select>
          </FormControl>

          {returnReasonOption === "Other" && (
            <TextField
              margin="dense"
              id="returnReason"
              label="Return Reason (more than 10 characters)"
              fullWidth
              multiline
              minRows={4}
              value={returnReason}
              onChange={(e) => setReturnReason(e.target.value)}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReason} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmitReason} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default LeadsTable;

import React from 'react';
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';


export const headCellsLead = [
  { id: 'name', numeric: false, label: 'Name', selected: true },
  { id: 'phone', numeric: false, label: 'Phone Number', selected: true },
  { id: 'email', numeric: false, label: 'Email Address', selected: true },
  { id: 'address', numeric: false, label: 'Address', selected: true },
  { id: 'city', numeric: false, label: 'City', selected: true },
  { id: 'state', numeric: false, label: 'State', selected: true },
  { id: 'zipcode', numeric: false, label: 'Zipcode', selected: false },
  { id: 'county', numeric: false, label: 'County', selected: true },
  { id: 'privateNotes', numeric: false, label: 'Private Notes', selected: false },
  { id: 'buyers', numeric: false, label: 'Buyer', selected: true },
  { id: 'action', numeric: true, label: 'Action', selected: true },
  { id: 'notes', numeric: false, label: 'Notes', selected: false },
  { id: 'verify', numeric: true, label: 'Verify', selected: false },
  { id: 'smsSent', numeric: true, label: 'SMS Sent', selected: true },
  { id: 'smsFailedReason', numeric: true, label: 'SMS FailedReason', selected: true },
  { id: 'emailSent', numeric: true, label: 'Email Sent', selected: true },
  { id: 'emailFailedReason', numeric: true, label: 'Email FailedReason', selected: true },

  { id: 'emailDate', numeric: true, label: 'Email sent date', selected: false },
  { id: 'leadReturned', numeric: true, label: 'Returned', selected: false },
  { id: 'returnReason', numeric: false, label: 'Return Reason', selected: false },
];

const EnhancedTableHead = (props) => {
  const { classes, order, headCells, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  
  return (
    <TableHead>
      <TableRow>
        {headCells.filter(e => e.selected).map((headCell) => (
          <TableCell
            key={headCell.id}
            padding='none'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;

import axios from "axios";
import { apiUrl, config } from "queries/config";



class BuyerService {

    constructor() {
        this.api = axios.create({
            baseURL: `${apiUrl}`,
        });
    }

    getAllBuyers = ({ accessToken }) => {
        config.headers["x-access-token"] = accessToken;
        return this.api.get('/api/buyers');
    };

}

// Create one instance (object) of the service
const buyerService = new BuyerService();

export default buyerService;


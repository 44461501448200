import { patchLeadSent } from "queries/leads/leads.queries";
import { NotificationManager } from "react-notifications";

export const PatchLeadSent = async ({ accessToken, patchData, id }) => {
  console.log(patchData);
  try {
    await patchLeadSent({ accessToken, patchData, id });
    NotificationManager.success("Update data success");
  } catch (e) {
    NotificationManager.error("Update lead failed");
    throw e;
  }
};

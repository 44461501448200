import moment from "moment";

const checked = (value, options) => {
  if (value !== true) {
    return options.message || "must be checked";
  }
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const truncateString = (string, maxLength = 10) => {
  if (!string) return null;
  if (string.length <= maxLength) return string;
  return `${string.substring(0, maxLength)}...`;
};

export const convertTexttoJSON = (value) => {
  const countiesData = value.split("\n");

  const countiesJSON = countiesData
    .filter((e) => e !== "")
    .map((e) => {
      const countyElement = e.split(",");
      if (countyElement.length !== 2) throw new Error("wrong format");
      return {
        county: countyElement[0].trim(),
        state: countyElement[1].trim().toUpperCase(),
      };
    });

  return countiesJSON;
};

export const convertJSONtoHTMLText = (value) => {
  if (!value) return "";
  let data = "";
  let i = 0;
  value.map((e) => {
    i = i + 1;
    if (i <= 6) {
      data = data + `${e.county}, ${e.state}<br/>`;
    }
    if (i === 7) {
      data = data + "...";
    }

    return e;
  });

  return data;
};

export const convertJSONtoCountyHTMLText = (value) => {
  if (!value) return "";
  let data = "";
  let i = 0;
  data = "";
  value.map((e) => {
    if (i % 3 === 0) {
      data = data + '<div class="row">';
      data = data + `<div class="column">${e.county}, ${e.state}</div>`;
    } else if (i % 3 === 2) {
      data = data + `<div class="column">${e.county}, ${e.state}</div>`;
      data = data + "</div>";
    } else {
      data = data + `<div class="column">${e.county}, ${e.state}</div>`;
    }
    i = i + 1;
    return e;
  });
  return data;
};

export const convertJSONtoText = (value) => {
  if (!value) return "";
  let data = "";
  value.map((e) => {
    data = data + `${e.county}, ${e.state}\n`;
    return e;
  });

  return data;
};

export const getBuyersFromCounty = (buyers, county, state) => {
  if (county === "" || county === null || state === "" || state === null)
    return [];
  if (!buyers) return [];

  const buyersData = buyers.filter((buyer) => {
    if (buyer.hold) return false;
    let isContain = false;
    if (buyer.countiesPurchased) {
      buyer.countiesPurchased.map((e) => {
        if (
          e.state.toUpperCase() === state.toUpperCase() &&
          e.county.toUpperCase() === county.toUpperCase()
        ) {
          isContain = true;
        }

        if (
          e.state.toUpperCase() === "ALL" ||
          e.county.toUpperCase() === "ALL"
        ) {
          isContain = true;
        }
        return true;
      });
    }
    return isContain;
  });

  return buyersData;
};

export const getLastLead = (leads) => {
  // Take the difference between the dates and divide by milliseconds per day.
  // Round to nearest whole number to deal with DST.

  if (!leads || leads.length === 0) {
    return "black";
  }

  var lastEmailDate = "2020-01-01T01:01:01.000Z";

  leads.forEach((lead) => {
    if (moment(lead.emailDate).toDate() > moment(lastEmailDate).toDate()) {
      lastEmailDate = lead.emailDate;
    }
  });

  return lastEmailDate;
};

export const dateDiff = (leads) => {
  // Take the difference between the dates and divide by milliseconds per day.
  // Round to nearest whole number to deal with DST.

  const second = new Date();
  if (!leads || leads.length === 0) {
    return "";
  }

  var lastEmailDate = "2020-01-01T01:01:01.000Z";

  leads.forEach((lead) => {
    if (moment(lead.emailDate).toDate() > moment(lastEmailDate).toDate()) {
      lastEmailDate = lead.emailDate;
    }
  });

  const days = Math.round(
    (second - moment(lastEmailDate).toDate()) / (1000 * 60 * 60 * 24)
  );

  if (days === 0) return "Today";
  if (days === 1) return "Yesterday";

  return `${days}`;
};

export const daysofLastLead = (leads) => {
  // Take the difference between the dates and divide by milliseconds per day.
  // Round to nearest whole number to deal with DST.
  const second = new Date();
  if (!leads || leads.length === 0) {
    return "";
  }

  var lastEmailDate = "2020-01-01T01:01:01.000Z";

  leads.forEach((lead) => {
    if (moment(lead.emailDate).toDate() > moment(lastEmailDate).toDate()) {
      lastEmailDate = lead.emailDate;
    }
  });

  const days = Math.round(
    (second - moment(lastEmailDate).toDate()) / (1000 * 60 * 60 * 24)
  );

  return days;
};

export const getDaysColorOfLastLead = (leads) => {
  if (!leads || leads.length === 0) {
    return "black";
  }

  var lastEmailDate = "2020-01-01T01:01:01.000Z";

  leads.forEach((lead) => {
    if (moment(lead.emailDate).toDate() > moment(lastEmailDate).toDate()) {
      lastEmailDate = lead.emailDate;
    }
  });
  const days = Math.round(
    (new Date() - moment(lastEmailDate).toDate()) / (1000 * 60 * 60 * 24)
  );

  if (days >= 7 && days < 14) return "#0617ff";
  if (days >= 14) return "#D50000";
  return "black";
};

export const getSubscriptionPaceColor = (buyer) => {
  if (!buyer.expiration) return "black";

  if (!buyer.subscriptions || buyer.subscriptions.length === 0) return "black";

  if (buyer.subscriptions[0].name === "NO") return "black";

  const leadsRemaining = getRemainCount(buyer, buyer.leads);

  if (leadsRemaining <= 0) {
    return "#388e3c";
  }

  const days = Math.round(
    (moment(buyer.expiration).toDate() - new Date()) / (1000 * 60 * 60 * 24)
  );
  const calculatedDays = days / 3;

  const subscriptionpace = calculatedDays - leadsRemaining;

  if (subscriptionpace < -5) return "#D50000";
  if (subscriptionpace < -1.1) return "#FF9100";
  if (subscriptionpace <= 1) return "#0617ff";
  return "green";
};

export const getSubscriptionPace = (buyer) => {
  if (!buyer.expiration) return "";

  if (!buyer.subscriptions || buyer.subscriptions.length === 0) return "";

  if (buyer.subscriptions[0].name === "NO") return "";

  const leadsRemaining = getRemainCount(buyer, buyer.leads);

  if (leadsRemaining <= 0) {
    return "Satisfied";
  }

  const days = Math.round(
    (moment(buyer.expiration).toDate() - new Date()) / (1000 * 60 * 60 * 24)
  );
  const calculatedDays = days / 3;

  const subscriptionpace = calculatedDays - leadsRemaining;

  if (subscriptionpace < -5) return "Very Behind";
  if (subscriptionpace < -1.1) return "Behind pace";
  if (subscriptionpace <= 1) return "On Pace";
  return "Ahead";
};

export const getSubscriptionPaceToNumber = (buyer) => {
  if (!buyer.expiration) return "";

  if (!buyer.subscriptions || buyer.subscriptions.length === 0) return "";

  if (buyer.subscriptions[0].name === "NO") return "";

  const leadsRemaining = getRemainCount(buyer, buyer.leads);
  if (leadsRemaining <= 0) {
    return 1;
  }

  const days = Math.round(
    (moment(buyer.expiration).toDate() - new Date()) / (1000 * 60 * 60 * 24)
  );
  const calculatedDays = days / 3;

  const subscriptionpace = calculatedDays - leadsRemaining;

  if (subscriptionpace < -5) return 2;
  if (subscriptionpace < -1.1) return 3;
  if (subscriptionpace <= 1) return 4;
  return 4;
};

export const getRemainCount = (buyer, leads) => {
  if (!leads || leads.length < 10) {
    return "-";
  }

  let countofLeads = 0;
  if (leads && leads.length > 0 && buyer) {
    leads.forEach((lead) => {
      if (lead.emailDate && lead.emailDate !== "" && !lead.leadReturned) {
        const check = moment(lead.emailDate).toDate();
        const to = new Date();
        const from = buyer.purchaseDate
          ? moment(buyer.purchaseDate).toDate()
          : moment("2020-01-01T01:00:00").toDate();

        if (
          check <= new Date() &&
          check.getTime() <= to.getTime() &&
          check.getTime() >= from.getTime()
        ) {
          countofLeads = countofLeads + 1;
        }
      }
    });
    return buyer.leadsPurchased - buyer.leadsUsed - countofLeads;
  } else if (buyer && buyer.leadsPurchased && buyer.leadsUsed) {
    return buyer.leadsPurchased - buyer.leadsUsed;
  } else if (buyer && buyer.leadsPurchased && !buyer.leadsUsed) {
    return buyer.leadsPurchased;
  }
  return 0;
};

export const getBackgroundColor = (lead) => {
  if (!lead.newLead) {
    return "lightgrey";
  }
  if (
    lead.buyers &&
    lead.buyers[0] &&
    lead.emailDate &&
    lead.buyers[0].expiration &&
    lead.buyers[0].purchaseDate
  ) {
    const leadSentDate = moment(lead.emailDate).toDate();
    const purchaseDate = moment(lead.buyers[0].purchaseDate).toDate();
    const expiration = moment(lead.buyers[0].expiration).toDate();

    if (leadSentDate.getTime() <= purchaseDate.getTime()) {
      return "#F5F5F5";
    }

    if (leadSentDate.getTime() > expiration.getTime()) {
      return "#FFEBEE";
    }
  }
  return "";
};

export const getBuyerBackgroundColor = (buyer) => {
  if (buyer) {
    const remainCount = getRemainCount(buyer, buyer.leads);

    if (
      buyer.subscriptions &&
      buyer.subscriptions.length > 0 &&
      (buyer.subscriptions[0].id === 3 || buyer.subscriptions[0].id === 0)
    ) {
      //billding buyer
      if (remainCount < 0) {
        return "#FFEBEE";
      } else {
        return "";
      }
    }
    if (!buyer.expiration) return "";

    const today = new Date();
    const expiration = moment(buyer.expiration).toDate();

    const days = Math.round((expiration - today) / (1000 * 60 * 60 * 24));

    if (days < 0) {
      return "#FFEBEE";
    }

    if (days <= 7 && remainCount <= 3) {
      return "#FFFDE7";
    }
  }
  return "";
};

export const getTransactions = (buyer) => {
  let data = "";

  if (buyer && buyer.transactions && buyer.transactions.length > 0) {
    buyer.transactions.map((e) => {
      data =
        data +
        e.name +
        ", " +
        moment(e.purchaseDate).format("MM/DD/YY") +
        ", " +
        moment(e.expiration).format("MM/DD/YY");
      data = data + "\n";
      return e;
    });
  }
  return data;
};

export const getDisputeColor = (leads) => {
  if (!leads || leads.length < 10) {
    return "black";
  }

  if (leads.length > 25) {
    leads = leads.slice(0, 25);
  }

  let countofReturn = 0;
  leads.forEach((lead) => {
    if (lead.leadReturned) {
      countofReturn += 1;
    }
  });

  const percent = Math.floor((countofReturn / leads.length) * 100);

  if (percent < 25) {
    return "#388e3c"; //green
  } else if (percent < 50) {
    return "#FF9100"; //yellow
  } else {
    return "#D50000"; //red
  }
};

export const getDispute = (leads) => {
  if (!leads || leads.length < 10) {
    return "-";
  }

  if (leads.length > 25) {
    leads = leads.slice(0, 25);
  }

  let countofReturn = 0;
  leads.forEach((lead) => {
    if (lead.leadReturned) {
      countofReturn += 1;
    }
  });

  const percent = Math.floor((countofReturn / leads.length) * 100);

  if (percent > 50) {
    return `High return ${Math.floor((countofReturn / leads.length) * 100)}%`;
  } else {
    return `${Math.floor((countofReturn / leads.length) * 100)}%`;
  }
};

export const getDisputeNumberOnly = (leads) => {
  if (!leads || leads.length < 10) {
    return -1;
  }

  if (leads.length > 25) {
    leads = leads.slice(0, 25);
  }

  let countofReturn = 0;
  leads.forEach((lead) => {
    if (lead.leadReturned) {
      countofReturn += 1;
    }
  });

  return Math.floor((countofReturn / leads.length) * 100);
};

export const getDisputeWeight = (leads) => {
  if (!leads || leads.length < 10) {
    return "normal";
  }

  if (leads.length > 25) {
    leads = leads.slice(0, 25);
  }

  let countofReturn = 0;
  leads.forEach((lead) => {
    if (lead.leadReturned) {
      countofReturn += 1;
    }
  });

  const percent = Math.floor((countofReturn / leads.length) * 100);

  if (percent > 50) {
    return "bold";
  } else {
    return "normal";
  }
};

export default {
  checked,
};

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import { Facebook, Twitter } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  },
  bgSucesss: {
    backgroundColor: '#3f51b5 !important',
    textAlign: 'center !important',
    color: '#fff !important'
  }
}));

const Landing = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <header className="masthead text-center">
        <div className="container content-lg d-flex flex-column align-items-center justify-content-center">
          <div className="row align-items-center justify-content-center text-center">
            <div className="col-lg-8 align-self-end mb-3">
              <h1 className="font-weight-bold text-white">
                Looking to Sell Your House? Get a Competitive Cash Offer
              </h1>
            </div>
            <div className="col-lg-8 align-self-baseline mb-5">
              <p className="text-white font-weight-light">
                We buy houses as is. No repairs needed. No agent commissions.
                All Cash. Just submit your address below to get an irresistible
                cash offer.
              </p>
            </div>
          </div>
          <div className="searchbar">
            <form>
              <i className="fa fa-map-marker"></i>
              <input
                id="offer_autocomplete"
                name="address"
                onfocus="geolocate()"
                className="search_input flex-grow-1 pac-target-input"
                type="text"
                placeholder="Enter your address here..."
                autocomplete="off"
              />
              <a
                href="/get-offer"
                className="btn btn-lg btn-green trigger--offer-autocomplete text-nowrap">
                REQUEST A FREE OFFER
                <i className="fa fa-chevron-right text-white pl-2"></i>
              </a>
            </form>
          </div>
        </div>
      </header>

      {/* <!-- WebuyHouse --> */}
      <section className="page-section webuyhouse">
        <div className="container">
          <Grid container spacing={3}>
            <Grid item md={6}>
              <img
                src="/images/businessman.jpg"
                className="img-fluid"
                alt="businessman"
              />
            </Grid>
            <Grid item md={6}>
              <Grid container spacing={3}>
                <Grid item md={10}>
                  <h2 className="font-weight-bold">
                    Finding a reliable buyer used to be difficult.
                  </h2>
                  <p className="py-3">
                    When you work with our trusted home buyers, you no longer
                    have to worry about mortgage approvals, open houses, or
                    closing costs.
                  </p>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item md={4}>
                  <img
                    width="32"
                    src="/images/closing-date.png"
                    alt="calendar"
                  />
                  <p className="font-weight-bold mt-3">
                    Choose your own closing date
                  </p>
                </Grid>
                <Grid item md={4}>
                  <img
                    width="32"
                    src="/images/no-showings.png"
                    alt="modern house"
                  />
                  <p className="font-weight-bold mt-3">
                    Sell As Is. No repairs needed.
                  </p>
                </Grid>
                <Grid item md={4}>
                  <img
                    width="32"
                    src="/images/sell-percentage.png"
                    alt="percentage sign"
                  />
                  <p className="font-weight-bold mt-3">
                    No commissions or closing costs.
                  </p>
                </Grid>
              </Grid>
              <div className="mt-4">
                <a
                  href="/benefits/"
                  className="btn btn-lg btn-green rounded-pill">
                  MORE BENEFITS
                  <i className="fa fa-chevron-right text-white pl-2"></i>
                </a>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>

      <section className="page-section buyhouse">
        <div className="container">
          <Grid container spacing={3}>
            <Grid item md={7}>
              <h2 className="font-weight-bold">
                No matter the condition,
                <br /> no matter the location,
                <br /> no matter the situation.
              </h2>
              <p className="pb-5">
                From a one-bedroom condo, to a suburban <br />
                abode, or a multi-acre mansion, we buy houses
                <br /> of all kinds and sizes — nationwide.
              </p>
              <Grid container spacing={3} className={classes.bgSucesss}>
                <Grid item md={3} xs={6}>
                  <img
                    width="32"
                    src="/images/icon-house-heart.png"
                    className="img-responsive"
                    alt="sweet-home"
                  />
                  <div className="font-weight-bold mt-3">Single Family</div>
                </Grid>
                <Grid item md={3} xs={6}>
                  <img
                    width="32"
                    src="/images/icon-town-house.png"
                    className="img-responsive"
                    alt="modern-house"
                  />
                  <div className="font-weight-bold mt-3">Town House</div>
                </Grid>
                <Grid item md={3} xs={6}>
                  <img
                    width="32"
                    src="/images/icon-condominum.png"
                    className="img-responsive"
                    alt="modern-house"
                  />
                  <div className="font-weight-bold mt-3">Condominium</div>
                </Grid>
                <Grid item md={3} xs={6}>
                  <img
                    width="32"
                    src="/images/icon-mobile-home.png"
                    className="img-responsive"
                    alt="modern-house"
                  />
                  <div className="font-weight-bold mt-3">Mobile Home</div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </section>

      <section className="page-section testimonials">
        <div className="container">
          <Grid container spacing={3}>
            <Grid item md={6} xs={6}>
              <img
                src="/images/sellerfamiliy.jpg"
                className="img-fluid"
                alt="family outside their residence"
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={6}>
                  <img
                    src="/images/quote-hand-drawn-symbol.png"
                    width="80"
                    className="img-fluid pl-4"
                    alt="quote-hand-drawn-symbol"
                  />

                  <div id="testimoniasl" className="carousel slide">
                    <div className="carousel-inner">
                      <div className="item carousel-item active">
                        <div className="row">
                          <Grid item sm={12}>
                            <div className="testimonial-wrapper">
                              <div className="testimonial text-grey subtitle">
                                We couldn’t have asked for better help selling
                                our townhome. Due to a recent job loss, our
                                mortgage was too much to handle. We could
                                breathe a little easier now that we moved out
                                and can start rebuilding.
                              </div>
                              <div className="media">
                                <div className="media-body">
                                  <div className="overview">
                                    <div className="name subtitle">
                                      <span>STEPHEN RUIZ</span>
                                    </div>
                                    <div className="addres text-grey subtitle">
                                      Los Angeles, CA
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item md={4}></Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </section>

      <section className="page-section steps-section">
        <div className="container content-lg">
          <Grid container spacing={3}>
            <Grid item md={12}>
              <h2 className="font-weight-bold pb-5">How it works?</h2>
            </Grid>
          </Grid>

          <div className="steps-timeline clearfix">
            <div className="steps-one">
              <div className="step-number">1</div>

              <h3 className="steps-name">CONTACT US</h3>
              <p className="steps-description mt-2 text-grey">
                The second we hear from you, we get to work on evaluating how
                much your home is worth. We’ll ask for a few details to help us
                prepare the very best cash offer for your property.
              </p>
            </div>

            <div className="steps-two">
              <div className="step-number">2</div>
              <h3 className="steps-name">VISIT YOUR HOME</h3>
              <p className="steps-description mt-2 text-grey">
                Expect a conveniently scheduled walk-through your home, so we
                can see for ourselves what kind of property we’re dealing with.
                Don’t worry, we’ll never ask you to make upgrades or repairs,
                inside or outside your home.
              </p>
            </div>

            <div className="steps-three">
              <div className="step-number">3</div>
              <h3 className="steps-name">GET AN OFFER</h3>
              <p className="steps-description mt-2 text-grey">
                Now it’s your turn to make an informed decision. We Buy House
                will extend a competitive cash offer for your property. Don’t
                worry about legal paperwork, third-party approvals or closing
                costs, we take care of everything for you.
              </p>
            </div>
          </div>
          <Grid container spacing={3}>
            <a
              href="/how-it-works/"
              className="btn btn-lg btn-green rounded-pill">
              LEARN MORE<i className="fa fa-chevron-right text-white pl-2"></i>
            </a>
          </Grid>
        </div>
      </section>

      <section className="cta">
        <div className="container-fluid bg-success">
          <Grid container spacing={3}>
            <Grid item md={12}>
              <div className="container py-5">
                <Grid container spacing={3}>
                  <Grid item md={8}>
                    <h3 className="text-white font-weight-bold">
                      Want to sell your home at a higher price?
                    </h3>
                  </Grid>
                  <Grid item md={4}>
                    <a
                      href="/get-offer/"
                      className="btn btn-light rounded-pill btn-lg">
                      REQUEST A FREE OFFER
                      <i className="fa fa-chevron-right text-dark pl-2"></i>
                    </a>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
      <footer className="footer">
        <div className="container-fluid">
          <Grid container spacing={0}>
            <Grid item md={12} spacing={0}>
              <div className="container pt-5">
                <Grid container spacing={10}>
                  <Grid item md={5} sm={12}>
                    <a href="https://Compassdatabase.net/">
                      <h3 className="text-white">CompassDatabase</h3>
                    </a>
                    <Grid item md={11}>
                      Trust our property investors to buy your house at a
                      competitive price. Enjoy a seamless process, free from
                      realtors, endless negotiations and last-minute
                      cancelations.
                    </Grid>
                  </Grid>
                  <Grid item md={7}>
                    <Grid container spacing={3}>
                      <Grid item md={3}>
                        <p className="footer--title">SERVICES</p>
                        <ul>
                          <li>
                            <a href="/how-it-works/">How It Works</a>
                          </li>
                          <li>
                            <a href="/benefits/">Benefits</a>
                          </li>
                          <li>
                            <a href="/get-offer/">Sell Your House</a>
                          </li>
                        </ul>
                      </Grid>
                      <Grid item md={4}>
                        <p className="footer--title">COMPANY</p>
                        <ul>
                          <li>
                            <a href="/about/">About Us</a>
                          </li>
                          <li>
                            <a href="/investors/">Investors</a>
                          </li>
                          <li>
                            <a href="/press/">Press</a>{' '}
                          </li>
                          <li>
                            <a href="/blog/">Blog</a>{' '}
                          </li>
                          <li>
                            <a href="/privacy/">Privacy</a>{' '}
                          </li>
                        </ul>
                      </Grid>
                      <Grid item md={5}>
                        <div className="float-right mb-3">
                          <a
                            href="https://Compassdatabase.net/get-offer/"
                            className="text-white btn btn-border rounded-pill font-weight-bolder">
                            Request a Free Offer
                          </a>
                        </div>
                        <div className="float-right">
                          <a
                            href="tel:18189188071"
                            className="text-white btn btn-border rounded-pill font-weight-bolder">
                            <i className="fa fa-phone"></i>
                            &nbsp;&nbsp;&nbsp;1-818-918-8071&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </a>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <hr className="divider my-2" />
                <div className="footer-bar">
                  <Grid container spacing={3}>
                    <Grid item md={6}>
                      <div>
                        Copyright © 2019 Compassdatabase.net | All Rights
                        Reserved
                      </div>
                    </Grid>
                    <Grid item md={6}>
                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <a href="#" className="btn btn-light rounded-circle ">
                            <Facebook fontSize="small" />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href="#" className="btn btn-light rounded-circle ">
                            <Twitter fontSize="small" />
                          </a>
                        </li>
                      </ul>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </footer>
    </div>
  );
};

export default Landing;

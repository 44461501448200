import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Button, TextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '15px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '400px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '20px',
    flexDirection: 'column'
  },

  button: {
    marginTop: '10px'
  }
}));

export default function AddSubscription({
  isEdit,
  handleClose,
  open,
  handleChangeSubscription,
  customSubscription,
  postSubs,
  rowSelected
}) {
  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}>
        <Fade in={open}>
          <div className={classes.paper}>
            <TextField
              fullWidth
              label={isEdit ? 'Edit name' : 'Add Subscription'}
              name="name"
              onChange={handleChangeSubscription}
              style={{ marginTop: '1rem' }}
              value={customSubscription}
              variant="outlined"
              margin="dense"
              defaultValue={rowSelected.name}
            />
            <TextField
              fullWidth
              label={isEdit ? 'Edit quantity' : 'Add quantity'}
              name="leadQuantity"
              onChange={handleChangeSubscription}
              style={{ marginTop: '1rem' }}
              value={customSubscription}
              variant="outlined"
              margin="dense"
              type="number"
              defaultValue={rowSelected.leadQuantity}
            />
            <Button
              onClick={postSubs}
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}>
              Add
            </Button>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

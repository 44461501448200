/* eslint-disable react/no-multi-comp */
import React from "react";
import { Switch, Redirect } from "react-router-dom";

import { RouteWithLayout } from "./components";
import { Main as MainLayout, Minimal as MinimalLayout } from "./layouts";

import {
  LeadList as LeadListView,
  AddLead as AddLeadView,
  BuyerList as BuyerListView,
  MessageLog as MessageLogView,
  AddBuyer as AddBuyerView,
  Account as AccountView,
  Settings as SettingsView,
  Calculator as CalculatorView,
  SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  Landing as LandingView,
  BuyerDashboard as BuyerDashboardView,
  Offer as OfferView,
  // TableReport as TableReportView,
} from "./views";
import SuscriptionView from "views/Subscription";
import LeadSent from "views/LeadSent";
import TableReportView from "views/TableReport";
import BuyerLeadsPerMonth from "views/BuyerLeadsPerMonth/BuyerLeadsPerMonth";
import SignInAdmin from "views/SignInAdmin/SignInAdmin";
import TooManyRequest from "utils/TooManyRequest";
import axios from "axios";
require("dotenv").config();

const logoutToken = async () => {
  const { accessToken } = JSON.parse(localStorage.getItem("token"));
  let data = {}
  const url = `${process.env.REACT_APP_BACKEND_API}/token-used`;
  const response = await axios.post(url, data,
    {
      headers: {
        'x-access-token': accessToken,
      }
    })

}

const LogoutView = () => {

  localStorage.removeItem("leads");
  localStorage.removeItem("stopApp");
  localStorage.removeItem("count");
  localStorage.removeItem("token");
  localStorage.removeItem("leadOrder");
  localStorage.removeItem("leadOrderBy");

  return <Redirect to="/sign-in" />;
};
const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/sign-in" />
      <RouteWithLayout
        component={AddLeadView}
        exact
        layout={MainLayout}
        path="/addlead"
      />
      <RouteWithLayout
        component={LeadListView}
        admin
        private
        exact
        layout={MainLayout}
        path="/leads"
      />
      <RouteWithLayout
        component={AddBuyerView}
        exact
        user
        layout={MainLayout}
        path="/addbuyer"
      />
      <RouteWithLayout
        component={BuyerLeadsPerMonth}
        exact
        user
        layout={MainLayout}
        path="/buyerleadsentpermonth"
      />
      <RouteWithLayout
        component={BuyerDashboardView}
        exact
        buyer
        layout={MainLayout}
        path="/buyer-dashboard"
      />
      <RouteWithLayout
        component={BuyerDashboardView}
        buyer
        exact
        layout={MainLayout}
        path="/buyer"
      />
      <RouteWithLayout
        component={BuyerListView}
        exact
        user
        layout={MainLayout}
        path="/buyers"
      />
      <RouteWithLayout
        component={MessageLogView}
        exact
        user
        layout={MainLayout}
        path="/logs"
      />
      <RouteWithLayout
        component={AccountView}
        exact
        private
        layout={MainLayout}
        path="/account"
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <RouteWithLayout
        component={SuscriptionView}
        exact
        layout={MainLayout}
        path="/subscriptions"
      />

      <RouteWithLayout
        component={CalculatorView}
        exact
        layout={MainLayout}
        path="/calculator"
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={SignInAdmin}
        exact
        layout={MinimalLayout}
        path="/sign-in-admin"
      />
      <RouteWithLayout
        component={LandingView}
        exact
        layout={MinimalLayout}
        path="/landing"
      />
      <RouteWithLayout
        component={OfferView}
        exact
        layout={MinimalLayout}
        path="/get-offer"
      />

      <RouteWithLayout
        component={LogoutView}
        exact
        layout={MinimalLayout}
        path="/logout"
      />
      <RouteWithLayout
        component={NotFoundView}
        layout={MinimalLayout}
        path="/verifytoken"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <RouteWithLayout
        component={LeadSent}
        exact
        layout={MainLayout}
        path="/leadSent"
      />
      <RouteWithLayout
        component={TableReportView}
        exact
        layout={MainLayout}
        path="/table-report"
      />
      <RouteWithLayout
        component={TooManyRequest}
        exact
        layout={MinimalLayout}
        path="/too-many-request"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { useRef } from "react";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    margin: theme.spacing(1),
    minWidth: 100,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 80,
  },
}));

export default function TimePickers({
  setTimePicker,
  timePicker,
  setLeads,
  index,
  lead,
  leads,
  setSendLeaderData,
  sendLeaderData,
  convertTimeToUTC,
}) {
  const classes = useStyles();
  const timeRef = useRef(null);

  const handeChangeTime = (e) => {
    setSendLeaderData({
      ...sendLeaderData,
      dateSent: convertTimeToUTC(e.target.value),
    });
  };

  return (
    <form className={classes.container} noValidate ref={timeRef}>
      <TextField
        id="time"
        type="time"
        defaultValue={lead.time}
        onChange={handeChangeTime}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5 min
        }}
      />
    </form>
  );
}

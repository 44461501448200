import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import Axios from "axios";
import { history } from "App";
import { Grid } from "@material-ui/core";
import { NotificationManager } from "react-notifications";
import { AddBuyerForm } from "./components";
import { LeadsTable } from "views/LeadList/components";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const AddBuyer = ({ buyerId }) => {
  const classes = useStyles();
  const [leads, setLeads] = useState([]);
  const [count, setCount] = useState(0);
  const [searchBuyer, setSearchBuyer] = useState(
    localStorage.getItem("searchBuyer")
      ? localStorage.getItem("searchBuyer")
      : ""
  );
  const [filterSent, setFilterSent] = useState("all");
  const [filterBilling, setFilterBilling] = useState("nobilling");
  const [searchWord, setSearchWord] = useState("");
  const [refreshKey, setRefreshKey] = useState("xxx");
  const [includeTrash, setIncludeTrash] = useState(false);
  const [filterSubscription, setFilterSubscription] = useState(-1);

  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("rowsPerPage")
      ? localStorage.getItem("rowsPerPage")
      : 10
  );
  const [page, setPage] = useState(
    localStorage.getItem("leadpage") ? localStorage.getItem("leadpage") : 0
  );
  const [order, setOrder] = useState(
    localStorage.getItem("leadorder")
      ? localStorage.getItem("leadorder")
      : "asc"
  );
  const [orderBy, setOrderBy] = useState(
    localStorage.getItem("leadorderby")
      ? localStorage.getItem("leadorderby")
      : "emailDate"
  );

  // useEffect(() => {
  //   if (buyerId !== -1) getBuyerLeads(buyerId);
  // }, [buyerId]);

  // useEffect(() => {
  //   if (buyerId !== -1) getBuyerLeads(buyerId);
  // }, [
  //   refreshKey,
  //   filterSent,
  //   includeTrash,
  //   order,
  //   orderBy,
  //   page,
  //   rowsPerPage,
  //   filterBilling,
  //   filterSubscription,
  // ]);

  // useEffect(() => {
  //   const delayDebounceFn = setTimeout(() => {
  //     if (buyerId !== -1) getBuyerLeads(buyerId);
  //     // Send Axios request here
  //   }, 1000);

  //   return () => clearTimeout(delayDebounceFn);
  // }, [searchWord, searchBuyer]);

  const onChangeSearchBuyer = React.useCallback(
    (e) => {
      localStorage.setItem("searchBuyer", e.target.value);
      setSearchBuyer(e.target.value.toLowerCase());
      localStorage.setItem("leadpage", 0);
      setPage(0);
    },
    [searchBuyer]
  );

  const onChangeFilterSubscription = React.useCallback(
    (e) => {
      localStorage.setItem("filterSubscription", e.target.value);
      setFilterSubscription(e.target.value);

      localStorage.setItem("leadpage", 0);
      setPage(0);
    },
    [filterSubscription]
  );

  const getBuyerLeads = (id) => {
    Axios.get(
      `${
        process.env.REACT_APP_BACKEND_API
      }/buyerleadlist/${id}?filterBilling=${filterBilling}&filterSubscription=${filterSubscription}&searchBuyer=${searchBuyer}&searchWord=${searchWord}&filterSent=${filterSent}&includeTrash=${
        includeTrash ? "1" : "0"
      }&order=${order}&orderBy=${orderBy}&offset=${page *
        rowsPerPage}&limit=${rowsPerPage}`
    )
      .then((response) => {
        setLeads(response.data.leads);
        setCount(response.data.count);
      })
      .catch(function(error) {
        history.push("/sign-in");
      });
  };
  //////////////
  const setRowsPerPage1 = React.useCallback(
    (e) => {
      localStorage.setItem("rowsPerPage", e);
      setRowsPerPage(e);
    },
    [rowsPerPage]
  );

  const setPage1 = React.useCallback(
    (e) => {
      if (e < 0) {
        e = 0;
      }
      localStorage.setItem("leadpage", e);
      setPage(e);
    },
    [page]
  );

  const setOrder1 = React.useCallback(
    (e) => {
      localStorage.setItem("leadorder", e);
      setOrder(e);
    },
    [order]
  );

  const setOrderBy1 = React.useCallback(
    (e) => {
      localStorage.setItem("leadorderby", e);
      setOrderBy(e);
    },
    [orderBy]
  );

  /////////////////

  const onChangeSearch = React.useCallback(
    (e) => {
      localStorage.setItem("searchWord", e.target.value);
      setSearchWord(e.target.value.toLowerCase());
      localStorage.setItem("leadpage", 0);
      setPage(0);
    },
    [searchWord]
  );

  const onChangeFilterSent = React.useCallback(
    (e) => {
      localStorage.setItem("filterSent", e.target.value);
      setFilterSent(e.target.value);
      localStorage.setItem("leadpage", 0);
      setPage(0);
    },
    [filterSent]
  );

  const onChangeFilterBilling = React.useCallback(
    (e) => {
      localStorage.setItem("filterBilling", e.target.value);
      setFilterBilling(e.target.value);

      localStorage.setItem("leadpage", 0);
      setPage(0);
    },
    [filterBilling]
  );

  const onSendLead = (id) => {
    const params = {
      id,
    };

    if (id !== -1) {
      Axios.post(`${process.env.REACT_APP_BACKEND_API}/lead/sendLead`, params)
        .then((response) => {
          NotificationManager.success("Send Lead success");
          const lead = response.data.lead;
          var index = leads.findIndex((item) => item.id === lead.id);

          // Replace the item by index.
          leads.splice(index, 1, lead);

          setRefreshKey(
            Math.random()
              .toString(36)
              .substring(2, 15)
          );
        })
        .catch(function(error) {});
    }
  };

  const onSendLeadToForm = (lead) => {
    let params = {
      id: lead.id,
    };

    if (lead) {
      Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/lead/sendLeadForm`,
        params
      )
        .then((response) => {
          const lead = response.data.lead;
          const data = response.data.data;

          if (data.hasOwnProperty("1")) {
            NotificationManager.success("Send Lead success");
          } else {
            NotificationManager.error(data["0"]);
          }
        })
        .catch(function(error) {
          NotificationManager.error("failed send lead");
        });
    }
  };

  const onChangeTrash = React.useCallback(() => {
    localStorage.setItem("includeTrash", includeTrash ? "0" : "1");
    setIncludeTrash(!includeTrash);
    localStorage.setItem("leadpage", 0);
    setPage(0);
  }, [includeTrash]);

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <AddBuyerForm key={refreshKey} leads={leads} buyerId={buyerId} />
        </Grid>
      </Grid>

      {buyerId !== -1 && leads && (
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            {/* <LeadsTable
              buyerId={buyerId}
              searchWord={searchWord}
              searchBuyer={searchBuyer}
              leads={leads}
              count={count}
              onChangeSearch={onChangeSearch}
              onChangeSearchBuyer={onChangeSearchBuyer}
              onChangeFilterSent={onChangeFilterSent}
              filterSent={filterSent}
              onChangeFilterBilling={onChangeFilterBilling}
              filterBilling={filterBilling}
              onSendLead={onSendLead}
              onSendLeadToForm={onSendLeadToForm}
              isBuyer={false}
              onChangeTrash={onChangeTrash}
              includeTrash={includeTrash}
              filterSubscription={filterSubscription}
              onChangeFilterSubscription={onChangeFilterSubscription}
              rowsPerPage={rowsPerPage}
              page={page}
              setPage={setPage1}
              setRowsPerPage={setRowsPerPage1}
              order={order}
              setOrder={setOrder1}
              orderBy={orderBy}
              setOrderBy={setOrderBy1}
            /> */}
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default AddBuyer;

import { getSubscription } from "queries/subscription/subscription.queries";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import TableSubscription from "./TableSubscription";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import AddSubscription from "./modal/AddSubscription";
import {
  useMutationDeletetSubscription,
  useMutationPatchtSubscription,
  useMutationPostSubscription,
} from "hooks/mutation/subscription/subsriptionMutation";

const Index = () => {
  const [open, setOpen] = useState(false);
  const [postSubscriptionData, setPostSubscriptionData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [rowSelected, setRowSelected] = useState({});

  const token = localStorage.getItem("token");
  const { accessToken } = JSON.parse(token);

  const { data, isLoading } = useQuery(["subscription"], () =>
    getSubscription({ accessToken })
  );

  useEffect(() => {}, [rowSelected]);

  const { mutate: mutateSubscription } = useMutationPostSubscription();

  const { mutate: mutateSubscriptionPatch } = useMutationPatchtSubscription();
  const {
    mutateDeleteIsLoading: mutateDeleteIsLoading,
    mutate: mutateSubscriptionDelete,
  } = useMutationDeletetSubscription();

  const styled = {
    container: {
      maxHeight: 600,
      backgroundColor: "#fff",
    },
    tableCell: {
      width: 300,
      textAlign: "center",
    },
  };

  const handleOpen = () => {
    setOpen(true);
    setIsEdit(false);
  };

  const handleOpenEdit = ({ data }) => {
    setRowSelected(data);

    setOpen(true);
    setIsEdit(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeSubscription = (e) => {
    setPostSubscriptionData({
      ...postSubscriptionData,
      [e.target.name]:
        e.target.type === "text" ? e.target.value : Number(e.target.value),
    });
  };

  const postSubs = () => {
    const id = rowSelected.id;
    if (!isEdit) {
      mutateSubscription({ accessToken, postSubscriptionData });
    } else {
      mutateSubscriptionPatch({ accessToken, postSubscriptionData, id });
    }
    handleClose();
    setPostSubscriptionData({});
  };

  const deleteSubs = ({ data }) => {
    setRowSelected(data);
    const id = data.id;

    mutateSubscriptionDelete({ accessToken, id });
  };

  const openModal = () => {
    if (open) {
      return (
        <AddSubscription
          isEdit={isEdit}
          handleClose={handleClose}
          handleOpen={handleOpen}
          open={open}
          handleChangeSubscription={handleChangeSubscription}
          postSubs={postSubs}
          rowSelected={rowSelected}
        />
      );
    }
  };

  return (
    <Container>
      <Title>Subscriptions List</Title>
      <Button
        onClick={handleOpen}
        color="primary"
        size="small"
        variant="contained"
        style={{ display: "flex", marginLeft: "auto" }}
        startIcon={<AddIcon />}
      >
        Add Subs
      </Button>
      <TableSubscription
        handleOpenEdit={handleOpenEdit}
        isLoading={isLoading}
        styled={styled}
        data={data}
        deleteSubs={deleteSubs}
      />
      {openModal()}
    </Container>
  );
};

export default Index;

export const Card = styled.div`
  margin: 50px 0;
`;

export const ButonContainer = styled.div`
  display: flex;
`;

const Container = styled.div`
  padding: 50px;
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 20px;
`;

import React, { useRef, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import Axios from "axios";
import { history } from "App";
import renderHTML from "react-render-html";
import moment from "moment";
import {
  Grid,
  Typography,
  Divider,
  Button,
  Dialog,
  TextField,
  DialogActions,
  DialogTitle,
  MenuItem,
  FormControl,
  Select,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { NotificationManager } from "react-notifications";
import { LeadsTable } from "views/LeadList/components";
import { convertJSONtoCountyHTMLText } from "../../common/utils";
import { Table, Input, Space } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { confirmAlert } from "react-confirm-alert";
import { feedback } from "common/constants";
import { CSVLink } from "react-csv";
require("dotenv").config();

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
    //width: "100%",
    height: "50px",
  },
}));

const BuyerDashboard = (props) => {
  const { history } = props;
  const classes = useStyles();
  const [leads, setLeads] = useState(-1);
  const [count, setCount] = useState(0);
  const [filterSent, setFilterSent] = useState("all");
  const [searchWord, setSearchWord] = useState("");
  const [refreshKey, setRefreshKey] = useState("xxx");
  const [buyer, setBuyer] = useState(-1);
  const [showEditor, setShowEditor] = useState(false);
  const [editorValue, setEditorValue] = useState("");
  const [selectedReturnLead, setSelectedReturnLead] = useState(-1);
  const [reasonOpen, setReasonOpen] = useState(false);
  const [returnReasonOption, setReturnReasonOption] = useState("");
  const [returnReason, setReturnReason] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("rowsPerPage")
      ? localStorage.getItem("rowsPerPage")
      : 25
  );
  const [page, setPage] = useState(
    localStorage.getItem("buyerpage") ? localStorage.getItem("buyerpage") : 0
  );
  const [order, setOrder] = useState(
    localStorage.getItem("buyerorder")
      ? localStorage.getItem("buyerorder")
      : "desc"
  );
  const [orderBy, setOrderBy] = useState(
    localStorage.getItem("buyerorderby")
      ? localStorage.getItem("buyerorderby")
      : "emailDate"
  );
  const [leadRemain, setLeadRemain] = useState("");
  const [disputeRate, setDisputeRate] = useState(-1);
  const [buyerCounties, setBuyerCounties] = useState([]);

  useEffect(() => {
    try {
      const token = localStorage.getItem("token");
      let buyerData = null;
      let id = -1;
      if (token) {
        buyerData = JSON.parse(token);
      }
      setBuyerCounties(buyerData.countiesPurchased);
      setBuyer(buyerData);
      getBuyer(buyerData.id);
    } catch (error) {
      console.log(error)
    }
  }, []);

  useEffect(() => {
    if (buyer && buyer.id) {
      getBuyerLeads(buyer.id);
    }
  }, [searchWord, buyer, filterSent, order, orderBy, page, rowsPerPage]);

  //////////////

  const getBuyerLeads = (id) => {
    Axios.get(
      `${process.env.REACT_APP_BACKEND_API
      }/buyerleadlist/${id}?order=${order}&orderBy=${orderBy}&offset=${page *
      rowsPerPage}&limit=200&filterSent=sent`
    )
      .then((response) => {
        const sortLeadsArray = response.data.leads;
        setLeads(sortLeadsArray);
        setCount(response.data.count);
      })
      .catch(function (error) {
        if (error.response.status === 429) {
          history.push("too-many-request");
        }
        console.log(error.response);
      });
  };

  const getBuyer = (id) => {
    Axios.get(`${process.env.REACT_APP_BACKEND_API}/buyer/${id}`)
      .then((response) => {
        setBuyer(response.data.buyer);
        setLeadRemain(response.data.leadRemain);
        setDisputeRate(response.data.disputeRate);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 429) {
          history.push("too-many-request");
        }
      });
  };

  const onChangeSearch = (e) => {
    setSearchWord(e.target.value.toLowerCase());
  };

  const onChangeFilterSent = (e) => {
    setFilterSent(e.target.value);
  };

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 5,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSubmitReason = () => {
    let reason = returnReason;
    if (returnReasonOption !== "Other") {
      reason = returnReasonOption;
    } else {
      if (reason.length < 10 || reason.length > 255) {
        NotificationManager.error("Please type more than 10 characters");
        return;
      }
    }
    if (reason === "") {
      NotificationManager.error("Please type more than 10 characters");
      return;
    }
    const params = {
      leadReturned: true,
      returnReason: reason,
    };

    Axios.patch(
      `${process.env.REACT_APP_BACKEND_API}/lead/${selectedReturnLead.key}`,
      params
    )
      .then((response) => {
        NotificationManager.success("Return lead success");
        history.go();
      })
      .catch(function (error) {
        NotificationManager.error(error);
      });
  };

  const handleReturnReasonOptionChange = (e) => {
    if (e.target.value === "Unable to Contact") {
      confirmAlert({
        title: "Alert",
        message: `Have you called the lead at least 12 times sent 5 text messages and 2 emails?`,
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              setReturnReasonOption(e.target.value);
            },
          },
          {
            label: "No",
            onClick: () => { },
          },
        ],
      });
    } else {
      setReturnReasonOption(e.target.value);
    }
  };

  const canReturnUnableToContact = () => {
    if (selectedReturnLead === -1 || selectedReturnLead.leadReturned)
      return true;
    const today = new Date();

    const days = Math.round(
      (today - moment(selectedReturnLead.emailDate).toDate()) /
      (1000 * 60 * 60 * 24)
    );
    if (days >= 5 && days <= 7) {
      return true;
    }

    return false;
  };

  const onChangeFeedback = (leadId, feedbackId) => {
    const params = {
      leadId,
      feedbackId,
    };
    Axios.post(`${process.env.REACT_APP_BACKEND_API}/lead/feedback`, params)
      .then((response) => {
        NotificationManager.success("Send feedback success");
        const lead = response.data.lead;
        var index = leads.findIndex((item) => item.id === lead.id);

        // Replace the item by index.
        leads.splice(index, 1, lead);

        setRefreshKey(
          Math.random()
            .toString(36)
            .substring(2, 15)
        );
      })
      .catch(function (error) {
        NotificationManager.error("failed send lead");
      });
  };

  const handleEditorNO = () => {
    setShowEditor(false);
  };

  const handleChangeEditor = (e) => {
    setEditorValue(e.target.value);
  };

  const handleEditorYES = () => {
    setShowEditor(false);
    const params = {
      fromEmail: "newcompasslead@gmail.com",
      toEmail: "compassdatapro@gmail.com",
      subject: `${buyer.name}(${buyer.email}) sent a ticket - ${moment(
        new Date()
      ).format("MM/DD/YY")}`,
      content: editorValue,
    };
    Axios.post(`${process.env.REACT_APP_BACKEND_API}/buyer/ticket`, params)
      .then((response) => {
        NotificationManager.success("Send ticket success");
      })
      .catch(function (error) {
        NotificationManager.error("failed send ticket");
      });
  };

  const handleOpenTicket = () => {
    setEditorValue("");
    setShowEditor(true);
  };

  const getAllowReturn = () => {
    if (buyer.allowReturn) return true;
    else {
      if (parseInt(disputeRate) > 50) {
        return false;
      } else {
        return true;
      }
    }
  };

  const handleCloseReason = () => {
    setReasonOpen(false);
  };

  const getReturned = (returned) => {
    switch (returned) {
      case false:
        return "NO";
      case true:
        return "YES";
      default:
        return "NO";
    }
  };

  const getReturnDisable = (lead) => {
    const leadEmailDate = new Date(lead.emailDate);
    const nowDate = new Date();
    const DateDiference = (nowDate - leadEmailDate) / (1000 * 60 * 60);
    return DateDiference >= 72;
  };

  const handleReturnClick = (event, lead) => {
    event.preventDefault();

    const allowReturn = getAllowReturn();

    if (!allowReturn) {
      NotificationManager.error(
        "You are trying returning over 50% of leads. 50% is the max amount of leads to return"
      );
      return;
    }

    setSelectedReturnLead(lead);
    setReasonOpen(true);
  };

  if (buyer === -1 || leads === -1) {
    return <div>loading...</div>;
  }

  const dataSource = leads.map((lead) => {
    return {
      key: lead.id,
      name: lead.name,
      phone: lead.phone,
      email: lead.email,
      emailDate: lead.emailDate,
      address: lead.address,
      city: lead.city,
      state: lead.state,
      zipcode: lead.zipcode,
      county: lead.county,
      notes: lead.notes,
      feedback: lead.feedback,
      leadReturned: lead.leadReturned,
    };
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...getColumnSearchProps("name"),
      ellipsis: true,
    },
    {
      title: "Contact information",
      dataIndex: "phone",
      key: "phone",
      width: 200,
      render: (lead, record) => {
        return <span>{`${record.phone}, ${record.email}`}</span>;
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "4",
      render: (lead, record) => {
        return (
          <span>
            {`${record.address} -
           ${record.city} -
            ${record.state} ${record.zipcode}`}{" "}
          </span>
        );
      },
    },
    {
      title: "County",
      dataIndex: "county",
      key: "county",
      sorter: (a, b) => a.county.localeCompare(b.county),
    },
    {
      title: "Received",
      dataIndex: "emailDate",
      key: "emailDate",
      sorter: (a, b) => moment(a.emailDate).unix() - moment(b.emailDate).unix(),
      defaultSortOrder: "descend",
      render: (emailDate) => {
        return moment(emailDate).format("MM/DD/YY");
      },
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      render: (lead, record) => {
        return (
          <span style={{ width: "100px", display: "block" }}>
            {record.notes}
          </span>
        );
      },
    },
    {
      title: "Returned",
      dataIndex: "leadReturned",
      key: "leadReturned",
      render: (lead, record) => {
        if (record.leadReturned === true) {
          return (
            <span>
              Returned <CheckCircleOutlined style={{ color: 'green' }} />
            </span>
          );
        } else {
          return (
            <Button
              disabled={getReturnDisable(record)}
              variant="outlined"
              size="small"
              onClick={(event) => handleReturnClick(event, record)}
            >
              Return
            </Button>
          );
        }
      },
    },
    {
      title: "Feedback",
      dataIndex: "feedback",
      key: "feedback",
      render: (lead, record) => {
        return (
          <TextField
            fullWidth
            size="small"
            name="feedback"
            onChange={(e) => {
              onChangeFeedback(record.key, e.target.value);
            }}
            required
            select
            // eslint-disable-next-line react/jsx-sort-props
            SelectProps={{ native: true }}
            value={lead.feedback}
            variant="outlined"
          >
            <option key={-1} value={-1}></option>
            {feedback.map((e) => (
              <option key={e.value} value={e.value}>
                {e.label}
              </option>
            ))}
          </TextField>
        );
      },
    },
  ];

  const csvData = leads.map((lead) => ({
    Name: lead.name,
    Phone: lead.phone,
    Email: lead.email,
    Address: lead.address,
    City: lead.city,
    State: lead.state,
    Zipcode: lead.zipcode,
    County: lead.county,
    Notes: lead.notes,
    Feedback: lead.feedback,
  }));

  return (
    <div className={classes.root} key={refreshKey}>
      <Grid style={{ marginBottom: "10px" }} lg={12} md={12} xl={12} xs={12}>
        <Typography className={classes.name} variant="h4">
          Leads Purchased: {buyer.leadsPurchased}
        </Typography>
      </Grid>
      <Grid style={{ marginBottom: "10px" }} lg={12} md={12} xl={12} xs={12}>
        <Typography className={classes.name} variant="h4">
          Leads Remaining: {leadRemain}
        </Typography>
      </Grid>
      {buyer.subscription &&
        buyer.subscription.length > 0 &&
        buyer.subscription[0].id !== 3 && (
          <Grid lg={12} md={12} xl={12} xs={12}>
            <Typography className={classes.name} variant="h4">
              Subscription Type:{" "}
              {buyer.subscription && buyer.subscription.length > 0
                ? buyer.subscription[0].name
                : "NO"}
              :{" "}
              {buyer.expiration
                ? moment(buyer.expiration).format("MM/DD/YY")
                : ""}
            </Typography>
          </Grid>
        )}
      <Grid lg={12} md={12} xl={12} xs={12}>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={{ pageSize: 100 }}
          scroll={{ y: 900, x: true }}
        />
      </Grid>
      <Divider />
      <Grid container spacing={3}>
        <Grid item lg={4} md={12} xs={4}>
          <div style={{ display: "flex" }} className={classes.root}>
            <Button
              color="primary"
              variant="contained"
              onClick={(e) => handleOpenTicket()}
              className={classes.button}
            >
              Create Ticket
            </Button>
            <Button
              color="primary"
              variant="outlined"
              className={classes.button}
            >
              <CSVLink data={csvData}>Download Leads</CSVLink>
            </Button>
          </div>
        </Grid>
      </Grid>

      <Dialog
        open={showEditor}
        keepMounted
        onClose={handleEditorNO}
        fullWidth
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Do you want to submit a Ticket?
        </DialogTitle>
        <Grid md={12} xs={12} style={{ padding: "20px" }}>
          <TextField
            label="ticket details"
            multiline
            rows={4}
            fullWidth
            name="ticket details"
            variant="outlined"
            onChange={handleChangeEditor}
            value={editorValue}
          />
        </Grid>
        <DialogActions>
          <Button onClick={handleEditorYES} color="primary">
            YES
          </Button>
          <Button onClick={handleEditorNO} color="primary">
            NO
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={reasonOpen}
        onClose={handleCloseReason}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Return Reason</DialogTitle>
        <DialogContent>
          <DialogContentText>Reason for return</DialogContentText>
          <FormControl className={classes.returnFormControl}>
            <Select
              labelId="returnReasonOption"
              id="returnReasonOption"
              value={returnReasonOption}
              onChange={handleReturnReasonOptionChange}
            >
              <MenuItem value={"Listed on MLS"}>Listed on MLS</MenuItem>
              <MenuItem value={"Wholesaler"}>Wholesaler</MenuItem>
              <MenuItem value={"Mobile Home"}>Mobile Home</MenuItem>
              <MenuItem value={"Land"}>Land</MenuItem>
              <MenuItem value={"Not the owner"}>Not the owner</MenuItem>
              <MenuItem value={"Not looking to sell"}>
                Not looking to sell
              </MenuItem>
              <MenuItem value={"Other"}>Other</MenuItem>
              {canReturnUnableToContact() && (
                <MenuItem value={"Unable to Contact"}>
                  Unable to Contact
                </MenuItem>
              )}
            </Select>
          </FormControl>

          {returnReasonOption === "Other" && (
            <TextField
              margin="dense"
              id="returnReason"
              label="Return Reason (more than 10 characters)"
              fullWidth
              multiline
              rows={4}
              value={returnReason}
              onChange={(e) => setReturnReason(e.target.value)}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReason} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmitReason} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BuyerDashboard;

import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import AddBuyer from "../../../AddBuyer/AddBuyer";
import BuyerToolbar from "../BuyerToolbar";
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  TableContainer,
  Drawer,
  Paper,
} from "@material-ui/core";
import {
  getDaysColorOfLastLead,
  getLastLead,
  getSubscriptionPace,
  getSubscriptionPaceColor,
  getRemainCount,
  dateDiff,
  getBuyerBackgroundColor,
  getDisputeColor,
  getDispute,
  getDisputeWeight,
} from "../../../../common/utils";
import EnhancedTableHead, { headCellsBuyer } from "./EnhancedTableHead";
import EnhancedTableToolbar from "./EnhancedTableToolbar";

function getStyles(name, personName) {
  return {
    fontWeight: personName.indexOf(name) === -1 ? 100 : 600,
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: theme.palette.secondary.light,
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
  searchInput: {
    marginRight: theme.spacing(1),
    minWidth: "300px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  typography: {
    padding: theme.spacing(2),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  container: {
    maxHeight: 640,
  },
}));

// eslint-disable-next-line react/no-multi-comp
const BuyerTable = (props) => {
  const {
    className,
    buyers,
    onChangeSearch,
    searchWord,
    includeHold,
    filterSubscription,
    onChangeFilterSubscription,
    onChangeHold,
    count,
    rowsPerPage,
    page,
    setPage,
    setRowsPerPage,
    order,
    orderBy,
    setOrder,
    setOrderBy,
    role,
    setLoading,
    refetch,
    dataSubs,
    setIncludeHold,
    handleChangeSearch,
    search,
    ...rest
  } = props;

  const classes = useStyles();
  const [headCells, setHeadCells] = React.useState(headCellsBuyer);
  const [toggle, toggleDrawer] = React.useState(
    localStorage.getItem("toggle")
      ? localStorage.getItem("toggle") === "1"
        ? true
        : false
      : false
  );
  const [buyerId, setBuyerId] = useState(
    localStorage.getItem("selectedBuyerId")
      ? localStorage.getItem("selectedBuyerId")
      : -1
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (buyerId) => {
    setBuyerId(buyerId);
    localStorage.setItem("selectedBuyerId", buyerId);
    toggleDrawer1(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(parseInt(newPage, 10));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const onChangeFilterHead = (event) => {
    const heads = headCells.map((e) => {
      if (e.id === event.target.name) {
        e.selected = !e.selected;
      }

      return e;
    });

    setHeadCells(heads);
  };
  const toggleDrawer1 = (value) => {
    toggleDrawer(value);
    value
      ? localStorage.setItem("toggle", "1")
      : localStorage.setItem("toggle", "0");
  };

  const getAllowReturn = (buyer) => {
    if (buyer.allowReturn) return "YES";
    else return "NO";
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <BuyerToolbar handleAddBuyer={handleClick} role={role} />
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent className={classes.content}>
          <Paper className={classes.paper}>
            <EnhancedTableToolbar
              onChange={onChangeSearch}
              searchWord={searchWord}
              includeHold={includeHold}
              onChangeHold={onChangeHold}
              headCells={headCells}
              onChangeFilterHead={onChangeFilterHead}
              filterSubscription={filterSubscription}
              onChangeFilterSubscription={onChangeFilterSubscription}
              setLoading={setLoading}
              refetch={refetch}
              page={page}
              rowsPerPage={rowsPerPage}
              dataSubs={dataSubs}
              setIncludeHold={setIncludeHold}
              handleChangeSearch={handleChangeSearch}
              search={search}
            />
            <TableContainer className={classes.container}>
              <Table stickyHeader>
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={count}
                  headCells={headCells}
                />
                <TableBody>
                  {buyers?.map((buyer) => {
                    return (
                      <TableRow
                        className={classes.tableRow}
                        key={buyer.id}
                        hover
                        style={{
                          backgroundColor: getBuyerBackgroundColor(buyer),
                        }}
                      >
                        {headCells.filter((e) => e.id === "name")[0]
                          .selected && (
                          <TableCell onClick={() => handleClick(buyer.id)}>
                            {buyer.name}
                          </TableCell>
                        )}
                        {headCells.filter((e) => e.id === "leadsPurchased")[0]
                          .selected && (
                          <TableCell onClick={() => handleClick(buyer.id)}>
                            {buyer.leadsPurchased}
                          </TableCell>
                        )}
                        {headCells.filter((e) => e.id === "leadsUsed")[0]
                          .selected && (
                          <TableCell onClick={() => handleClick(buyer.id)}>
                            {buyer.leadsUsed}
                          </TableCell>
                        )}
                        {headCells.filter((e) => e.id === "leadsRemained")[0]
                          .selected && (
                          <TableCell onClick={() => handleClick(buyer.id)}>
                            {getRemainCount(buyer, buyer.leads)}
                          </TableCell>
                        )}
                        {headCells.filter((e) => e.id === "subscriptions")[0]
                          .selected && (
                          <TableCell onClick={() => handleClick(buyer.id)}>
                            {buyer.subscriptions && buyer.subscriptions[0]
                              ? buyer.subscriptions[0].name
                              : "NO"}
                          </TableCell>
                        )}
                        {headCells.filter((e) => e.id === "expiration")[0]
                          .selected && (
                          <TableCell onClick={() => handleClick(buyer.id)}>
                            {buyer.expiration
                              ? moment(buyer.expiration).format("MM/DD/YY")
                              : ""}
                          </TableCell>
                        )}
                        {headCells.filter((e) => e.id === "lastLeadReceived")[0]
                          .selected && (
                          <TableCell onClick={() => handleClick(buyer.id)}>
                            {buyer.leads && buyer.leads.length > 0
                              ? moment(getLastLead(buyer.leads)).format(
                                  "MM/DD/YY"
                                )
                              : ""}
                          </TableCell>
                        )}
                        {headCells.filter(
                          (e) => e.id === "daysOfLastLeadReceived"
                        )[0].selected && (
                          <TableCell
                            onClick={() => handleClick(buyer.id)}
                            style={{
                              color: getDaysColorOfLastLead(
                                buyer.leads && buyer.leads.length > 0
                                  ? buyer.leads
                                  : null
                              ),
                            }}
                          >
                            {buyer.leads && buyer.leads.length > 0
                              ? dateDiff(buyer.leads)
                              : ""}
                          </TableCell>
                        )}
                        {headCells.filter((e) => e.id === "dispute")[0]
                          .selected && (
                          <TableCell
                            onClick={() => handleClick(buyer.id)}
                            style={{
                              color: getDisputeColor(buyer.leads),
                              fontWeight: getDisputeWeight(buyer.leads),
                            }}
                          >
                            {buyer.leads && buyer.leads.length > 0
                              ? getDispute(buyer.leads)
                              : "-"}
                          </TableCell>
                        )}
                        {headCells.filter((e) => e.id === "subscriptionPace")[0]
                          .selected && (
                          <TableCell
                            onClick={() => handleClick(buyer.id)}
                            style={{ color: getSubscriptionPaceColor(buyer) }}
                          >
                            {getSubscriptionPace(buyer)}
                          </TableCell>
                        )}
                        {headCells.filter((e) => e.id === "allowReturn")[0]
                          .selected && (
                          <TableCell onClick={() => handleClick(buyer.id)}>
                            {getAllowReturn(buyer)}
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 75, 100]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </CardContent>
        {toggle && (
          <Drawer
            anchor={"right"}
            open={toggle}
            onClose={() => toggleDrawer1(false)}
          >
            <AddBuyer buyerId={buyerId} toggleDrawer={toggleDrawer1} />
          </Drawer>
        )}
      </Card>
    </div>
  );
};

export default BuyerTable;

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { CircularProgress, Backdrop } from "@material-ui/core";
import { BuyerTable } from "./components";
import { useQuery, useQueryClient } from "react-query";
import { getBuyers } from "queries/buyer/buyer.queries";
import { getSubscription } from "queries/subscription/subscription.queries";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: "#fff",
  },
}));

const BuyerList = (props) => {
  const { history } = props;
  const classes = useStyles();
  const queryClient = useQueryClient();
  const [buyers, setBuyers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const [includeHold, setIncludeHold] = useState(
    localStorage.getItem("includeHold")
      ? localStorage.getItem("includeHold") === "1"
        ? true
        : false
      : false
  );
  const [filterSubscription, setFilterSubscription] = useState([]);

  const token = localStorage.getItem("token");
  const { accessToken } = JSON.parse(token);

  const role = JSON.parse(token).roles[0];

  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("rowsPerPage")
      ? localStorage.getItem("rowsPerPage")
      : 100
  );
  const [page, setPage] = useState(
    localStorage.getItem("buyerpage") ? localStorage.getItem("buyerpage") : 0
  );
  const [order, setOrder] = useState(
    localStorage.getItem("buyerorder")
      ? localStorage.getItem("buyerorder")
      : "asc"
  );
  const [orderBy, setOrderBy] = useState(
    localStorage.getItem("buyerorderby")
      ? localStorage.getItem("buyerorderby")
      : "name"
  );
  const [search, setSearch] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const { data, isLoading, refetch } = useQuery(
    [
      "buyers",
      filterSubscription,
      page,
      rowsPerPage,
      history,
      includeHold,
      order,
      orderBy,
    ],
    () =>
      getBuyers({
        accessToken: accessToken,
        filterSubscription: filterSubscription,
        includeHold: includeHold,
        page: page,
        rowsPerPage: rowsPerPage,
        history: history
      }),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const { data: dataSubs, isLoading: isLoadingSubs } = useQuery(
    ["subscription"],
    () => getSubscription({ accessToken }),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const count = data ? data.count : 0;

  useEffect(() => {
    if (!data) return;

    let resultsSearch = [];
    if (!search || search === "") {
      resultsSearch = data.buyers;
    } else {
      resultsSearch = data?.buyers.filter((dato) => {
        return dato.name.toLowerCase().includes(search.toLowerCase());
      });
    }

    setFilteredResults(resultsSearch);
  }, [data, search]);

  const onChangeFilterSubscription = async (e) => {
    // localStorage.setItem("filterSubscription", JSON.stringify(e.target.value));
    setFilterSubscription(e.target.value);
  };

  const onChangeHold = () => {
    localStorage.setItem("includeHold", includeHold ? "0" : "1");
    setIncludeHold(!includeHold);
    localStorage.setItem("buyerpage", 0);
    setPage(0);
  };

  //////////////
  const setRowsPerPage1 = React.useCallback(
    (e) => {
      localStorage.setItem("rowsPerPage", e);
      setRowsPerPage(e);
    },
    [rowsPerPage]
  );

  const setPage1 = React.useCallback(
    (e) => {
      if (e < 0) {
        e = 0;
      }
      localStorage.setItem("buyerpage", e);
      setPage(e);
    },
    [page]
  );

  const setOrder1 = React.useCallback(
    (e) => {
      localStorage.setItem("buyerorder", e);
      setOrder(e);
    },
    [order]
  );

  const setOrderBy1 = React.useCallback(
    (e) => {
      localStorage.setItem("buyerorderby", e);
      setOrderBy(e);
    },
    [orderBy]
  );

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  if (isLoading || loading) {
    return (
      <Backdrop className={classes.backdrop} open={true}>
        {" "}
        <CircularProgress color="primary" />{" "}
      </Backdrop>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <BuyerTable
          search={search}
          handleChangeSearch={handleChangeSearch}
          dataSubs={dataSubs}
          setLoading={setLoading}
          refetch={refetch}
          role={role}
          buyers={filteredResults}
          count={count}
          // onChangeSearch={onChangeSearch}
          //searchWord={searchWord}
          includeHold={includeHold}
          onChangeHold={onChangeHold}
          filterSubscription={filterSubscription}
          onChangeFilterSubscription={onChangeFilterSubscription}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage1}
          setRowsPerPage={setRowsPerPage1}
          order={order}
          setOrder={setOrder1}
          orderBy={orderBy}
          setOrderBy={setOrderBy1}
          setIncludeHold={setIncludeHold}
        />
      </div>
      <Backdrop className={classes.backdrop} open={loading || buyers === null}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Backdrop className={classes.backdrop} open={loading2 || buyers === null}>
        <CircularProgress color="primary" />
      </Backdrop>
    </div>
  );
};

export default BuyerList;

/* eslint-disable no-undef */
/* eslint-disable no-empty */
import axios from 'axios';

class AxiosConfig {
  static init() {
    axios.interceptors.request.use(config => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          const { accessToken } = JSON.parse(token);
          if (accessToken) {
            config.headers.common['x-access-token'] = accessToken;
          }
        }
      } catch (error) {}

      return config;
    });

    axios.interceptors.request.use(null, error => {
      return Promise.reject(error);
    });
  }
}

export default AxiosConfig;

export const convertTimeToUTC = (timeStr) => {
  const referenceDate = new Date();

  const [hours, minutes] = timeStr.split(":").map(Number);

  const date = new Date(referenceDate);

  date.setUTCHours(hours);
  date.setUTCMinutes(minutes);

  const utcTimeString = date.toUTCString();

  return utcTimeString;
};

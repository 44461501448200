import React, { useState } from "react";
import { Modal } from "antd";

import LeadTable from "../LeadTable";
const ModalLeadsTable = ({
  isModalOpen,
  handleOk,
  buyerId,
  handleCancel,
  showModal,
  roles,
}) => {
  return (
    <>
      <Modal
        title="Leads Sent"
        handleCancel={handleCancel}
        open={isModalOpen}
        handleOk={handleOk}
        onCancel={handleCancel}
        zIndex={99999}
        width={"95%"}
        showModal={showModal}
        maskClosable={true}
      >
        <LeadTable roles={roles} buyerId={buyerId} />
      </Modal>
    </>
  );
};
export default ModalLeadsTable;

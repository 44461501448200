import React, { useState } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { history } from 'App';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import Axios from 'axios';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  TableContainer,
  Paper,
  CardHeader,
  Divider,
  TableHead,
  TableSortLabel,
  Button
} from '@material-ui/core';
import { numberWithCommas } from '../../../../common/utils';

const descendingComparator = (a, b, orderBy) => {
  //special
  if (orderBy === 'types') {
    // equal items sort equally

    // nulls sort after anything else
    if (a[orderBy] === null) {
      return 1;
    } else if (b[orderBy] === null) {
      return -1;
    }

    if (a[orderBy].length === 0) {
      return 1;
    } else if (b[orderBy].length === 0) {
      return -1;
    }

    if (b[orderBy][0].id < a[orderBy][0].id) {
      return -1;
    }
    if (b[orderBy][0].id > a[orderBy][0].id) {
      return 1;
    }
    return 0;
  }

  // equal items sort equally
  if (a[orderBy] === b[orderBy]) {
    return 0;
  }
  // nulls sort after anything else
  else if (a[orderBy] === null) {
    return 1;
  } else if (b[orderBy] === null) {
    return -1;
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  if (array && array !== -1) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  } else {
    return [];
  }
};

const headCells = [
  { id: 'subscriptionName', numeric: false, label: 'Subscription' },
  { id: 'name', numeric: false, label: 'Amount' },
  { id: 'purchaseDate', numeric: true, label: 'Purchase Date' },
  { id: 'expiration', numeric: false, label: 'Expiration Date' },
  { id: 'action', numeric: false, label: 'Action' }
];

const EnhancedTableHead = props => {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            padding="none"
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 15,
    padding: 15
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const Transactions = props => {
  const { className, transactions, ...rest } = props;

  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('leadValidated');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {};

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteTransaction = (event, transactionId) => {
    event.preventDefault();
    Axios.delete(
      `${process.env.REACT_APP_BACKEND_API}/buyer/transactiondelete/${transactionId}`
    )
      .then(response => {
        history.go();
      })
      .catch(function(error) {
        NotificationManager.error('failed delete transaction');
      });
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader subheader="Transactions" />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <Paper className={classes.paper}>
            <TableContainer>
              <Table>
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={transactions.length}
                />
                <TableBody>
                  {stableSort(transactions, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((transaction, index) => {
                      return (
                        <TableRow
                          className={classes.tableRow}
                          key={transaction.id}
                          hover>
                          <TableCell>{transaction.subscriptionName}</TableCell>
                          <TableCell>
                            ${numberWithCommas(transaction.name)}
                          </TableCell>
                          <TableCell>
                            {transaction.purchaseDate
                              ? moment(transaction.purchaseDate).format(
                                  'MM/DD/YY'
                                )
                              : ''}
                          </TableCell>
                          <TableCell>
                            {transaction.expiration
                              ? moment(transaction.expiration).format(
                                  'MM/DD/YY'
                                )
                              : ''}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={event =>
                                handleDeleteTransaction(event, transaction.id)
                              }>
                              Delete
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={transactions.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

export default Transactions;

import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import validate from 'validate.js';
import PropTypes from 'prop-types';
import Axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { makeStyles } from '@material-ui/styles';
import queryString from 'query-string';
import moment from 'moment';
import { history } from 'App';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Switch,
  FormControlLabel,
  InputAdornment,
  Dialog,
  DialogContentText,
  DialogActions,
  DialogTitle,
  DialogContent
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  types,
  states,
  verifies,
  validates
} from '../../../../common/constants';
import TextMaskInput from '../../../../components/TextMaskInput';
import { feedback } from '../../../../common/constants';

const schema = {
  name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
};

const useStyles = makeStyles(() => ({
  root: {}
}));

const AddLeadForm = props => {
  const { className, ...rest } = props;

  const token = localStorage.getItem('token');
  const role = JSON.parse(token).roles[0];

  const classes = useStyles();
  const [refreshKey, setRefreshKey] = useState('xxx');
  const [filteredBuyers, setFilteredBuyers] = useState([]);
  const [leadId, setLeadId] = useState(-1);
  const [isBuyer, setIsBuyer] = useState(false);
  const [reasonOpen, setReasonOpen] = useState(false);
  const [returnReason, setReturnReason] = useState('');

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      state: 'AL',
      type: 0,
      leadReturned: false,
      leadSold: false,
      leadValidated: false,
      verify: 0
    },
    touched: {},
    errors: {}
  });

  const handleCloseReason = () => {
    setReasonOpen(false);
  };

  const handleReturnClick = event => {
    event.preventDefault();
    setReasonOpen(true);
  };

  const handleSubmitReason = () => {
    if (returnReason === '') {
      return;
    }
    const params = {
      leadReturned: true,
      returnReason
    };

    Axios.patch(`${process.env.REACT_APP_BACKEND_API}/lead/${leadId}`, params)
      .then(response => {
        NotificationManager.success('Return lead success');
        history.go();
      })
      .catch(function(error) {
        NotificationManager.error(error);
      });
  };

  const getReturnDisable = () => {
    if (formState.values.leadReturned) return true;
    const today = new Date();

    const days = Math.round(
      (today - moment(formState.values.emailDate).toDate()) /
        (1000 * 60 * 60 * 24)
    );

    if (days > 3) {
      return true;
    } else if (days >= 0 && days <= 3) {
      return false;
    } else {
      return true;
    }
  };

  const getLead = id => {
    Axios.get(`${process.env.REACT_APP_BACKEND_API}/lead/${id}`)
      .then(response => {
        const lead = response.data.lead;
        setFormState(formState => ({
          ...formState,
          values: {
            email: lead.email,
            phone: lead.phone,
            state: lead.state,
            zipcode: lead.zipcode,
            name: lead.name,
            city: lead.city,
            verify: lead.verify,
            emailDate: lead.emailDate,
            notes: lead.notes,
            privateNotes: lead.privateNotes,
            county: lead.county,
            buyer: lead.buyers && lead.buyers[0] ? lead.buyers[0].id : -1,
            address: lead.address,
            leadSold: lead.leadSold,
            leadReturned: lead.leadReturned,
            leadValidated: lead.leadValidated,
            leadPaid: lead.leadPaid,
            feedback: lead.feedback,
            type: lead.types && lead.types[0] ? lead.types[0].id : 0,
            subscription:
              lead.subscriptions && lead.subscriptions[0]
                ? lead.subscriptions[0].id
                : 0
          },
          touched: {}
        }));

        setRefreshKey(
          Math.random()
            .toString(36)
            .substring(2, 15)
        );
      })
      .catch(function(error) {
        NotificationManager.error('getLead failed');
      });
  };

  const getBuyerList = () => {
    Axios.get(`${process.env.REACT_APP_BACKEND_API}/buyers`)
      .then(response => {
        setFilteredBuyers(response.data.buyers);
      })
      .catch(function(error) {
        NotificationManager.error('get Buyer list failed');
      });
  };

  useEffect(() => {
    try {
      const values = queryString.parse(window.location.search);
      setLeadId(values.id ? values.id : -1);
      setIsBuyer(values.isBuyer === 'true' ? true : false);
      if (values.id) getLead(values.id);
      getBuyerList();
    } catch (error) {}
  }, []);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleEditNoteBlur = e => {
    handleSubmit(e);
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (
      !formState.values['city'] ||
      !formState.values['state'] ||
      !formState.values['county'] ||
      formState.values['city'] === '' ||
      formState.values['county'] === ''
    ) {
      NotificationManager.error('invalid city and county');
      return;
    }

    // const numString = formState.values['zipcode'].toString();

    // if (numString.length === 4) {
    //   const newNum = '0' + numString;
    //   setFormState(prevState => ({
    //     ...prevState,
    //     values: {
    //       ...prevState.values,
    //       zipcode: newNum
    //     }
    //   }));
    // }

    const params = {
      name: formState.values['name'],
      phone: formState.values['phone'].replace(/-/g, ''),
      address: formState.values['address'],
      city: formState.values['city'],
      state: formState.values['state'],
      county: formState.values['county'],
      zipcode: formState.values['zipcode'],
      email: formState.values['email'],
      type: formState.values['type'],
      verify: formState.values['verify'],
      notes: formState.values['notes'],
      privateNotes: formState.values['privateNotes'],
      buyer: formState.values['buyer'],
      leadSold: formState.values['leadSold'],
      leadReturned: formState.values['leadReturned'],
      leadPaid: formState.values['leadPaid'],
      leadValidated: formState.values['leadValidated']
    };

    if (params.zipcode.length === 4) {
      params.zipcode = '0' + params.zipcode;
    }

    if (leadId === -1) {
      Axios.post(`${process.env.REACT_APP_BACKEND_API}/lead`, params)
        .then(response => {
          if (response.data.lead && !response.data.lead.newLead) {
            NotificationManager.info('Duplicated Lead created');
          } else {
            NotificationManager.success('Success!');
            history.goBack();
          }
        })
        .catch(error => {
          NotificationManager.error('Create lead failed');
        });
    } else {
      Axios.patch(`${process.env.REACT_APP_BACKEND_API}/lead/${leadId}`, params)
        .then(response => {
          getLead(leadId);
          NotificationManager.success('Success!');
        })
        .catch(error => {
          NotificationManager.error('Edit lead failed');
        });
    }
  };

  const onChangeFeedback = feedbackId => {
    const params = {
      leadId,
      feedbackId
    };
    Axios.post(`${process.env.REACT_APP_BACKEND_API}/lead/feedback`, params)
      .then(response => {
        NotificationManager.success('Send feedback success');
        getLead(leadId);
      })
      .catch(function(error) {
        NotificationManager.error('failed send lead');
      });
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form
        key={refreshKey}
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}>
        <CardHeader
          subheader="The information can be edited"
          title={leadId === -1 ? 'Add Lead' : 'Edit Lead'}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Name"
                margin="dense"
                name="name"
                onChange={handleChange}
                required
                value={formState.values.name}
                variant="outlined"
                disabled={isBuyer}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Phone Number"
                margin="dense"
                name="phone"
                disabled={isBuyer}
                onChange={handleChange}
                required
                value={formState.values.phone}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                  inputComponent: TextMaskInput
                }}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Email Address"
                margin="dense"
                name="email"
                disabled={isBuyer}
                onChange={handleChange}
                value={formState.values.email}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Address"
                margin="dense"
                name="address"
                disabled={isBuyer}
                onChange={handleChange}
                type="text"
                value={formState.values.address}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="City"
                margin="dense"
                name="city"
                disabled={isBuyer}
                onChange={handleChange}
                type="text"
                required
                value={formState.values.city}
                variant="outlined"
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Zip Code"
                margin="dense"
                name="zipcode"
                disabled={isBuyer}
                onChange={handleChange}
                value={formState.values.zipcode}
                variant="outlined"
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Select State"
                margin="dense"
                name="state"
                disabled={isBuyer}
                onChange={handleChange}
                required
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={formState.values.state}
                variant="outlined">
                {states.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="County"
                margin="dense"
                name="county"
                disabled={isBuyer}
                onChange={handleChange}
                required
                value={formState.values.county}
                variant="outlined"
              />
            </Grid>
            {leadId === -1 || isBuyer === true ? (
              ''
            ) : (
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="Buyer"
                  margin="dense"
                  name="buyer"
                  disabled
                  onChange={handleChange}
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={formState.values.buyer}
                  variant="outlined">
                  <option key={-1} value={-1}></option>
                  {filteredBuyers.map(buyer => (
                    <option key={buyer.id} value={buyer.id}>
                      {buyer.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
            )}

            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Select Types"
                margin="dense"
                disabled={isBuyer}
                name="type"
                onChange={handleChange}
                SelectProps={{ native: true }}
                select
                value={formState.values.type}
                variant="outlined">
                {types.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>

            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Verify"
                margin="dense"
                name="verify"
                disabled={isBuyer}
                onChange={handleChange}
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={formState.values.verify}
                variant="outlined">
                {verifies.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                fullWidth
                label="Lead Validated"
                margin="dense"
                disabled={isBuyer}
                name="leadValidated"
                onChange={handleChange}
                required
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={formState.values.leadValidated}
                variant="outlined">
                {validates.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                label="Notes"
                multiline
                rows={4}
                fullWidth
                name="notes"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleEditNoteBlur}
                value={formState.values.notes}
              />
            </Grid>

            {!isBuyer && role === 'ROLE_ADMIN' && (
              <Grid item md={6} xs={12}>
                <TextField
                  label="Private Notes"
                  multiline
                  rows={4}
                  fullWidth
                  name="privateNotes"
                  variant="outlined"
                  value={formState.values.privateNotes}
                  onChange={handleChange}
                />
              </Grid>
            )}

            {!isBuyer && (
              <Grid item md={3} xs={12}>
                <FormControlLabel
                  value={formState.values.leadSold}
                  name="leadSold"
                  onChange={handleChange}
                  control={<Switch checked={formState.values.leadSold} />}
                  label="Lead Sold"
                  labelPlacement="top"
                />
              </Grid>
            )}

            {!isBuyer && formState.values.subscription === 3 && (
              <Grid item md={3} xs={12}>
                <FormControlLabel
                  value={formState.values.leadPaid}
                  name="leadPaid"
                  onChange={handleChange}
                  control={<Switch checked={formState.values.leadPaid} />}
                  label="Lead Paid"
                  labelPlacement="top"
                />
              </Grid>
            )}

            {
              <Grid item md={3} xs={12}>
                {isBuyer ? (
                  <Button
                    disabled={getReturnDisable()}
                    variant="outlined"
                    size="small"
                    onClick={event => handleReturnClick(event)}>
                    Lead Return
                  </Button>
                ) : (
                  <FormControlLabel
                    value={formState.values.leadReturned}
                    name="leadReturned"
                    onChange={handleChange}
                    control={<Switch checked={formState.values.leadReturned} />}
                    label="Lead Returned"
                    labelPlacement="top"
                  />
                )}
              </Grid>
            }

            {isBuyer && (
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  name="feedback"
                  onChange={e => onChangeFeedback(e.target.value)}
                  required
                  select
                  label="Feedback"
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={formState.values.feedback}
                  variant="outlined">
                  <option key={-1} value={-1}></option>
                  {feedback.map(e => (
                    <option key={e.value} value={e.value}>
                      {e.label}
                    </option>
                  ))}
                </TextField>
              </Grid>
            )}
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            startIcon={<ArrowBackIcon />}
            variant="contained"
            onClick={() => history.goBack()}>
            Back
          </Button>
          {!isBuyer && (
            <Button
              color="primary"
              variant="contained"
              type="submit"
              startIcon={<SaveIcon />}
              disabled={!formState.isValid}>
              {leadId === -1 ? 'Submit' : 'Update'}
            </Button>
          )}
        </CardActions>
      </form>

      <Dialog
        open={reasonOpen}
        onClose={handleCloseReason}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Return Reason</DialogTitle>
        <DialogContent>
          <DialogContentText>Please enter return reason</DialogContentText>
          <TextField
            margin="dense"
            id="returnReason"
            label="Return Reason"
            fullWidth
            multiline={4}
            value={returnReason}
            onChange={e => setReturnReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReason} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSubmitReason}
            color="primary"
            startIcon={<SaveIcon />}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

AddLeadForm.propTypes = {
  className: PropTypes.string
};

export default AddLeadForm;

/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import validate from "validate.js";
import Axios from "axios";
import DateFnsUtils from "@date-io/date-fns";
import "date-fns";
import { NotificationManager } from "react-notifications";
import { makeStyles } from "@material-ui/styles";
import { history } from "App";
import Transactions from "./Transactions";
import LoginHistory from "./LoginHistory";
import TextMaskInput from "../../../../components/TextMaskInput";

import {
  Card,
  CardHeader,
  CircularProgress,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  Switch,
  TextField,
  InputAdornment,
  FormControlLabel,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { convertTexttoJSON, convertJSONtoText } from "../../../../common/utils";
import { useQuery } from "react-query";
import { getRoles } from "queries/roles/roles.queries";
import TableChartIcon from "@material-ui/icons/TableChart";
import ModalLeadsTable from "../ModalLeadsTable";
import LeadTable from "../LeadTable";
import ModalChangePass from "../ModalChangePass";

const schema = {
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 64,
    },
  },
  name: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
};
const useStyles = makeStyles(() => ({
  root: {
    marginTop: 10,
    padding: 10,
    zIndex: 999,
  },
  input: {
    marginRight: 20,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  centerGrid: {
    display: "flex",
    alignItems: "center",
  },
}));

const AddBuyerForm = (props) => {
  const { className, leads, buyerId, ...rest } = props;

  const token = localStorage.getItem("token");
  const { email, accessToken, roles } = JSON.parse(token);

  const classes = useStyles();
  const [refreshKey, setRefreshKey] = useState("xxx");
  const [buyer, setBuyer] = useState();
  const [disputeRate, setDisputeRate] = useState();
  const [leadRemain, setLeadRemain] = useState();
  const [loading, setLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [customSubscription, setCustomSubscription] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formState, setFormState] = useState({
    isValid: false,
    values: { subscription: 0, newPassword: "", confirm: "", password: "" },
    touched: {},
    errors: {},
  });
  const [isModalOpenPass, setIsModalOpenPass] = useState(false);

  const { data, isLoading } = useQuery(["roles"], () =>
    getRoles({ accessToken })
  );

  useEffect(() => {
    try {
      if (buyerId !== -1) getBuyer(buyerId);
      getSubscriptions();
    } catch (error) {
      console.log(error);
    }
  }, [buyerId]);

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const getBuyer = (id) => {
    setLoading(true);
    Axios.get(`${process.env.REACT_APP_BACKEND_API}/buyer/${id}`)
      .then((response) => {
        const buyer = response.data.buyer;
        const countiesPurchased = JSON.parse(buyer.countiesPurchased);
        setBuyer(buyer);
        setDisputeRate(response.data.disputeRate);
        setLeadRemain(response.data.leadRemain);

        setFormState((formState) => ({
          ...formState,
          values: {
            email: buyer.email,
            phone: buyer.phone,
            name: buyer.name,
            // countiesPurchased: buyer.countiesPurchased
            //   .map((item) => {
            //     return ` ${item.county}, ${item.state}\n`;
            //   })
            //   .join(""),
            countiesPurchased: countiesPurchased
              .map((item) => {
                return ` ${item.county}, ${item.state}\n`;
              })
              .join(""),
            leadsPurchased: buyer.leadsPurchased,
            leadsUsed: buyer.leadsUsed,
            subscription:
              buyer.subscriptions && buyer.subscriptions[0]
                ? buyer.subscriptions[0].id
                : 0,
            subscriptionExpiration: buyer.expiration,
            purchaseDate: buyer.purchaseDate ? buyer.purchaseDate : null,
            otherEmail: buyer.otherEmail,
            otherPhone: buyer.otherPhone,
            privateNote: buyer.privateNote,
            hold: buyer.hold,
            allowReturn: buyer.allowReturn,
            roles: buyer.roles[0].id,
            zapier_webhook_url: buyer.zapier_webhook_url,
          },
          touched: {},
        }));
        setRefreshKey(
          Math.random()
            .toString(36)
            .substring(2, 15)
        );
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const getSubscriptions = () => {
    Axios.get(`${process.env.REACT_APP_BACKEND_API}/subscription`)
      .then((response) => {
        const subscriptions = response.data.subscriptions;
        setSubscriptions(subscriptions);
        setRefreshKey(
          Math.random()
            .toString(36)
            .substring(2, 15)
        );
      })
      .catch(function (error) { });
  };

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleDateChange = (date) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        subscriptionExpiration: date,
      },
      touched: {
        ...formState.touched,
        subscriptionExpiration: true,
      },
    }));
  };

  const handlepurchaseDateChange = (date) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        purchaseDate: date,
      },
      touched: {
        ...formState.touched,
        purchaseDate: true,
      },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let countiesPurchased = null;
    try {
      countiesPurchased = convertTexttoJSON(
        formState.values["countiesPurchased"]
      );
    } catch (error) {
      NotificationManager.error("Wrong format Counties purchased");
      return;
    }

    const params = {
      name: formState.values["name"],
      phone: formState.values["phone"]
        ? formState.values["phone"].replace(/-/g, "")
        : null,
      email: formState.values["email"],
      countiesPurchased: JSON.stringify(countiesPurchased),
      leadsPurchased: formState.values["leadsPurchased"],
      leadsUsed: formState.values["leadsUsed"],
      otherEmail: formState.values["otherEmail"],
      otherPhone: formState.values["otherPhone"],
      privateNote: formState.values["privateNote"],
      hold: formState.values["hold"],
      allowReturn: formState.values["allowReturn"],
      subscription: formState.values["subscription"],
      roles: Number(formState.values["roles"]),
      zapier_webhook_url: formState.values["zapier_webhook_url"],
    };

    if (buyerId === -1) {
      Axios.post(`${process.env.REACT_APP_BACKEND_API}/buyer`, params)
        .then((response) => {
          NotificationManager.success("Submit data success");
          localStorage.removeItem("toggle");
          history.push("/buyers");
        })
        .catch(function (error) {
          NotificationManager.error(error);
        });
    } else {
      Axios.patch(
        `${process.env.REACT_APP_BACKEND_API}/buyer/${buyerId}`,
        params
      )
        .then((response) => {
          NotificationManager.success("Submit data success");
          Axios.get(`${process.env.REACT_APP_BACKEND_API}/buyers`)
            .then((response) => {
              localStorage.setItem(
                "buyers",
                JSON.stringify(response.data.buyers)
              );
              history.go();
            })
            .catch(function (error) {
              NotificationManager.error("get buyer failed");
            });
        })
        .catch(function (error) {
          NotificationManager.error("update buyer failed");
        });
    }
  };

  const handleAddSubscription = (event) => {
    event.preventDefault();

    if (!formState.values.transaction || formState.values.transaction === "") {
      NotificationManager.info("Please input amount");
      return;
    }

    const params = {
      subscription: formState.values["subscription"],
      subscriptionExpiration: formState.values["subscriptionExpiration"],
      purchaseDate: formState.values["purchaseDate"],
      transaction: formState.values["transaction"],
      roles: Number(formState.values["roles"]),
    };

    if (buyerId !== -1) {
      Axios.patch(
        `${process.env.REACT_APP_BACKEND_API}/buyer/${buyerId}`,
        params
      )
        .then((response) => {
          NotificationManager.success("Submit data success");
          Axios.get(`${process.env.REACT_APP_BACKEND_API}/buyers`)
            .then((response) => {
              localStorage.setItem(
                "buyers",
                JSON.stringify(response.data.buyers)
              );
              history.go();
            })
            .catch(function (error) {
              NotificationManager.error(error);
            });
        })
        .catch(function (error) {
          NotificationManager.error(error);
        });
    }
  };

  const changePasswordHandler = () => {
    if (formState.values.password !== formState.values.confirm) {
      NotificationManager.info("Password Mismatch");
      return;
    }

    const params = {
      email: buyer.email,
      password: formState.values.password,
      newPassword: formState.values.newPassword,
      emailAdmin: email,
    };
    Axios.post(
      `${process.env.REACT_APP_BACKEND_API}/auth/updatepassword`,
      params
    )
      .then((response) => {
        setIsModalOpenPass(false);
        setFormState((formState) => ({
          ...formState,
          values: {
            subscription: 0,
            newPassword: "",
            confirm: "",
            password: "",
          },
        }));
        NotificationManager.success("Change password success");
      })
      .catch(function (error) {
        NotificationManager.error("Update password failed");
      });
  };
  const showModalPass = () => {
    if (formState.values.newPassword !== formState.values.confirm) {
      NotificationManager.info("Password Mismatch");
      return;
    }

    if (!formState.values.newPassword || !formState.values.confirm) {
      NotificationManager.info("Please input password");
      return;
    }
    if (
      formState.values.newPassword.length <= 7 ||
      formState.values.confirm.length <= 7
    ) {
      NotificationManager.info("Password must be at least 8 characters");
      return;
    }
    setIsModalOpenPass(true);
  };

  const handleCancelPass = () => {
    setIsModalOpenPass(false);
  };

  const addSubscriptionHandler = () => {
    if (customSubscription === "") return;

    const params = {
      name: customSubscription,
    };

    Axios.post(`${process.env.REACT_APP_BACKEND_API}/subscription`, params)
      .then((response) => {
        NotificationManager.success("Submit data success");
        history.go(0);
      })
      .catch(function (error) {
        NotificationManager.error("Add subscription type failed");
      });
  };

  const handleChangeSubscription = (e) => {
    setCustomSubscription(e.target.value);
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          zIndex: "1000",
          marginTop: "50px",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const openModal = () => {
    if (isModalOpen) {
      return (
        <ModalLeadsTable
          roles={roles}
          isModalOpen={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
          showModal={showModal}
          buyerId={buyerId}
        />
      );
    }
  };

  const OpenModalChangePass = () => {
    if (isModalOpenPass) {
      return (
        <ModalChangePass
          isModalOpenPass={isModalOpenPass}
          handleCancelPass={handleCancelPass}
          handleChange={handleChange}
          formState={formState}
          changePasswordHandler={changePasswordHandler}
        />
      );
    }
  };

  return (
    <>
      <Card {...rest} className={clsx(classes.root, className)}>
        <form
          key={refreshKey}
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
        >
          <CardHeader
            subheader="The information can be edited"
            title={buyerId === -1 ? "Add Buyer" : "Edit Buyer"}
          />
          <FormControlLabel
            value={formState.values.hold}
            name="hold"
            onChange={handleChange}
            control={<Switch checked={formState.values.hold} />}
            label="Hold"
            labelPlacement="top"
          />
          <FormControlLabel
            value={formState.values.allowReturn}
            name="allowReturn"
            onChange={handleChange}
            control={<Switch checked={formState.values.allowReturn} />}
            label="Allow Return over 50%"
            labelPlacement="top"
          />
          Dispute: {disputeRate && disputeRate}%
          {/* <Button
            style={{ marginLeft: "15px" }}
            color="primary"
            variant="contained"
            onClick={showModal}
            startIcon={<TableChartIcon />}
          >
            Leads Table
          </Button> */}
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  helperText="Please specify name"
                  label="Name"
                  margin="dense"
                  name="name"
                  onChange={handleChange}
                  required
                  value={formState.values.name}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  margin="dense"
                  name="phone"
                  onChange={handleChange}
                  required
                  value={formState.values.phone}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                    inputComponent: TextMaskInput,
                  }}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="Email Address"
                  margin="dense"
                  name="email"
                  onChange={handleChange}
                  required
                  value={formState.values.email}
                  error={hasError("email")}
                  helperText={
                    hasError("email") ? formState.errors.email[0] : null
                  }
                  variant="outlined"
                />
              </Grid>

              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="Leads Purchased"
                  margin="dense"
                  type="number"
                  name="leadsPurchased"
                  onChange={handleChange}
                  required
                  value={formState.values.leadsPurchased}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="Leads Used"
                  margin="dense"
                  name="leadsUsed"
                  type="number"
                  onChange={handleChange}
                  value={formState.values.leadsUsed}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="Leads Remaining"
                  margin="dense"
                  name="countiesPurchased"
                  disabled
                  value={leadRemain && leadRemain}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="Select Subscription"
                  margin="dense"
                  name="subscription"
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={formState.values.subscription}
                  variant="outlined"
                >
                  {subscriptions.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              {roles === "ROLE_ADMIN" && (
                <Grid item md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="Select Role"
                    margin="dense"
                    name="roles"
                    onChange={handleChange}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={formState.values.roles}
                    variant="outlined"
                  >
                    {!isLoading &&
                      data.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                  </TextField>
                </Grid>
              )}
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="Counties purchased"
                  margin="dense"
                  name="countiesPurchased"
                  multiline
                  rows={5}
                  onChange={handleChange}
                  required
                  value={formState.values.countiesPurchased}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="Other Email"
                  multiline
                  rows={5}
                  margin="dense"
                  name="otherEmail"
                  onChange={handleChange}
                  value={formState.values.otherEmail}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="Other Phone"
                  multiline
                  rows={5}
                  margin="dense"
                  name="otherPhone"
                  onChange={handleChange}
                  value={formState.values.otherPhone}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={4} xs={12}>
                <TextField
                    fullWidth
                    label="Zapier Webhook URL"
                    multiline
                    rows={5}
                    margin="dense"
                    name="zapier_webhook_url"
                    onChange={handleChange}
                    value={formState.values.zapier_webhook_url}
                    variant="outlined"
                />
              </Grid>

              {roles[0] === "ROLE_ADMIN" && (
                <Grid item md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="Private Note"
                    multiline
                    rows={5}
                    margin="dense"
                    name="privateNote"
                    onChange={handleChange}
                    value={formState.values.privateNote}
                    variant="outlined"
                  />
                </Grid>
              )}
            </Grid>
          </CardContent>
          <Divider />
          <p />
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              startIcon={<SaveIcon />}
              disabled={!formState.isValid}
            >
              {buyerId === -1 ? "Submit" : "Update"}
            </Button>
          </CardActions>
        </form>
      </Card>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader subheader="Change Password" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Password"
                name="newPassword"
                onChange={handleChange}
                type="password"
                value={formState.values.newPassword}
                variant="outlined"
                margin="dense"
              />
              <TextField
                fullWidth
                label="Confirm password"
                name="confirm"
                onChange={handleChange}
                style={{ marginTop: "1rem" }}
                type="password"
                value={formState.values.confirm}
                variant="outlined"
                margin="dense"
              />
            </Grid>
            <Grid item md={4} xs={12} className={classes.centerGrid}>
              <Button
                variant="contained"
                onClick={() => showModalPass()}
                color="primary"
              >
                Change Password
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Divider />
      <CardHeader title="Leads Sent" />

      <LeadTable roles={roles} buyerId={buyerId} />
      <Divider />

      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader subheader="Add Custom Subscription" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12} className={classes.centerGrid}>
              <TextField
                fullWidth
                label="Add Custom Subscription"
                name="customsubscription"
                onChange={handleChangeSubscription}
                style={{ marginTop: "1rem" }}
                value={customSubscription}
                variant="outlined"
                margin="dense"
              />
            </Grid>
            <Grid item md={4} xs={12} className={classes.centerGrid}>
              <Button
                variant="contained"
                onClick={addSubscriptionHandler}
                color="primary"
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {buyerId !== -1 && (
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader subheader="Add Transaction" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={2} xs={12}>
                <TextField
                  fullWidth
                  label="transaction"
                  margin="dense"
                  name="transaction"
                  type="number"
                  onChange={handleChange}
                  value={formState.values.transaction}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={3} xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    format="MM/dd/yyyy"
                    label="Purchase date"
                    value={formState.values.purchaseDate}
                    onChange={handlepurchaseDateChange}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item md={3} xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    format="MM/dd/yyyy"
                    label="Subscription Expiration"
                    value={formState.values.subscriptionExpiration}
                    onChange={handleDateChange}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item md={12} xs={12} className={classes.centerGrid}>
                <Button
                  variant="contained"
                  onClick={handleAddSubscription}
                  color="primary"
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}

      {roles[0] === "ROLE_ADMIN" && (
        <Grid item md={4} xs={12}>
          <Button
            variant="contained"
            // onClick navigate to https://www.paypal.com/billing/subscriptions
            // onClick={handleAddSubscription}

            onClick={() => {
              history.push(`/table-report/${buyerId}`);
            }}
            color="primary"
          >
            Table Report
          </Button>
        </Grid>
      )}

      {buyer && <Transactions transactions={buyer.transactions} />}
      {buyer && <LoginHistory historys={buyer.historys} />}
      {openModal()}
      {OpenModalChangePass()}
    </>
  );
};

export default AddBuyerForm;

import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { makeStyles } from '@material-ui/styles';
import { history } from 'App';
import { LogToolbar, LogTable } from './components';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const MessageLog = () => {
  const classes = useStyles();

  const [leads, setLeads] = useState(null);
  const [count, setCount] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState(
    localStorage.getItem('phoneNumber')
      ? localStorage.getItem('phoneNumber')
      : ''
  );

  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem('rowsPerPage')
      ? localStorage.getItem('rowsPerPage')
      : 25
  );
  const [page, setPage] = useState(
    localStorage.getItem('logpage') ? localStorage.getItem('logpage') : 0
  );
  const [order, setOrder] = useState(
    localStorage.getItem('logorder') ? localStorage.getItem('logorder') : 'asc'
  );
  const [orderBy, setOrderBy] = useState(
    localStorage.getItem('logorderby')
      ? localStorage.getItem('logorderby')
      : 'emailDate'
  );

  useEffect(() => {
    getLeadList();
  }, []);

  const getLeadList = () => {
    Axios.get(
      `${
        process.env.REACT_APP_BACKEND_API
      }/leadlist?log=true&filterSubscription=-1&phoneNumber=${phoneNumber}&order=${order}&orderBy=${orderBy}&offset=${page *
        rowsPerPage}&limit=${rowsPerPage}`
    )
      .then(response => {
        setLeads(response.data.leads);
        setCount(response.data.count);
      })
      .catch(function(error) {
        NotificationManager.error('failed get lead list');
        history.push('/sign-in');
      });
  };

  //////////////
  const setRowsPerPage1 = React.useCallback(
    e => {
      localStorage.setItem('rowsPerPage', e);
      setRowsPerPage(e);
    },
    [rowsPerPage]
  );

  const setPage1 = React.useCallback(
    e => {
      if (e < 0) {
        e = 0;
      }
      localStorage.setItem('logpage', e);
      setPage(e);
    },
    [page]
  );

  const setOrder1 = React.useCallback(
    e => {
      localStorage.setItem('logorder', e);
      setOrder(e);
    },
    [order]
  );

  const setOrderBy1 = React.useCallback(
    e => {
      localStorage.setItem('logorderby', e);
      setOrderBy(e);
    },
    [orderBy]
  );

  /////////////////

  const onChangeSearch = React.useCallback(
    e => {
      localStorage.setItem('phoneNumber', e.target.value);
      setPhoneNumber(e.target.value.toLowerCase());
    },
    [phoneNumber]
  );

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getLeadList();
      // Send Axios request here
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [phoneNumber]);

  if (!leads) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          zIndex: '1000',
          marginTop: '50px'
        }}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <LogToolbar />
      <div className={classes.content}>
        <LogTable
          leads={leads}
          count={count}
          onChangeSearch={onChangeSearch}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage1}
          setRowsPerPage={setRowsPerPage1}
          order={order}
          setOrder={setOrder1}
          orderBy={orderBy}
          setOrderBy={setOrderBy1}
        />
      </div>
    </div>
  );
};

export default MessageLog;

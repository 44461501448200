import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Axios from "axios";
import { history } from "App";
import { NotificationManager } from "react-notifications";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import ModalChangePass from "./ModalChangePass";

const useStyles = makeStyles(() => ({
  root: {},
}));

const Password = (props) => {
  const { className, ...rest } = props;

  const [isModalOpenPass, setIsModalOpenPass] = useState(false);

  const classes = useStyles();

  const [values, setValues] = useState({
    password: "",
    confirm: "",
    newPassword: "",
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const showModalPass = () => {
    if (values.newPassword !== values.confirm) {
      NotificationManager.info("Password Mismatch");
      return;
    }

    if (!values.newPassword || !values.confirm) {
      NotificationManager.info("Please input password");
      return;
    }
    if (values.newPassword.length <= 7 || values.confirm.length <= 7) {
      NotificationManager.info("Password must be at least 8 characters");
      return;
    }
    setIsModalOpenPass(true);
  };

  const changePasswordHandler = () => {
    const token = localStorage.getItem("token");

    try {
      const { email } = JSON.parse(token);
      const params = {
        email,
        password: values.password,
        newPassword: values.newPassword,
      };
      Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/auth/updatepassword-buyer`,
        params
      )
        .then((response) => {
          setIsModalOpenPass(false);
          setValues({
            newPassword: "",
            confirm: "",
            password: "",
          });
          NotificationManager.success("Update password success");
        })
        .catch(function (error) {
          if (error.response.status === 429) {
            history.push("too-many-request");
          }
        });
    } catch (error) {
      history.push("/sign-in");
    }
  };

  const handleCancelPass = () => {
    setIsModalOpenPass(false);
  };

  const OpenModalChangePass = () => {
    if (isModalOpenPass) {
      return (
        <ModalChangePass
          handleChange={handleChange}
          isModalOpenPass={isModalOpenPass}
          handleCancelPass={handleCancelPass}
          values={values}
          changePasswordHandler={changePasswordHandler}
        />
      );
    }
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form>
        <CardHeader subheader="Update password" title="Password" />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Password"
            name="newPassword"
            onChange={handleChange}
            type="password"
            value={values.newPassword}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Confirm password"
            name="confirm"
            onChange={handleChange}
            style={{ marginTop: "1rem" }}
            type="password"
            value={values.confirm}
            variant="outlined"
          />
        </CardContent>

        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={() => showModalPass()}
          >
            Update
          </Button>
        </CardActions>
      </form>
      {OpenModalChangePass()}
    </Card>
  );
};

Password.propTypes = {
  className: PropTypes.string,
};

export default Password;

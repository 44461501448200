import React, { useEffect } from "react";
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Checkbox,
} from "@material-ui/core";

export const headCellsLead = [
  { id: "name", numeric: false, label: "Name", selected: true },
  {
    id: "contact",
    numeric: false,
    label: "Contact",
    selected: true,
  },
  { id: "address", numeric: false, label: "Address", selected: true },
  { id: "county", numeric: false, label: "County", selected: true },
  {
    id: "privateNotes",
    numeric: false,
    label: "Private Notes",
    selected: true,
  },
  { id: "buyers", numeric: false, label: "Buyer", selected: true },
  { id: "action", numeric: true, label: "Action", selected: true },
  { id: "sendNow", numeric: true, label: "Send Now", selected: true },
  { id: "notes", numeric: false, label: "Notes", selected: true },
  
  { id: "verify", numeric: true, label: "Verify", selected: true },

  {
    id: "emailDate",
    numeric: true,
    label: "Email sent date",
    selected: true,
  },
  {
    id: "leadReturned",
    numeric: true,
    label: "Returned",
    selected: true,
  },
  {
    id: "returnReason",
    numeric: false,
    label: "Return Reason",
    selected: true,
  },
  { id: "feedback", numeric: true, label: "Feedback", selected: true },
  { id: "trash", numeric: true, label: "Trash", selected: true },
  // { id: 'leadReturned', numeric: true, disablePadding: false, label: 'Lead Returned' },
  // { id: 'LeadValidated', numeric: true, disablePadding: false, label: 'Lead Validated' },
];

export const headCellsBuyer = [
  { id: "name", numeric: false, label: "Name", selected: true },
  { id: "phone", numeric: false, label: "Phone Number", selected: true },
  {
    id: "email",
    numeric: false,
    label: "Email Address",
    selected: true,
  },
  { id: "address", numeric: false, label: "Address", selected: true },
  { id: "city", numeric: false, label: "City", selected: true },
  { id: "state", numeric: false, label: "State", selected: true },
  { id: "zipcode", numeric: false, label: "Zipcode", selected: true },
  { id: "county", numeric: false, label: "County", selected: true },
  {
    id: "privateNotes",
    numeric: false,
    label: "Private Notes",
    selected: true,
  },
  //{ id: "action", numeric: true, label: "Action", selected: true },
  //{ id: "sendNow", numeric: true, label: "Send Now", selected: true },
  { id: "notes", numeric: false, label: "Notes", selected: true },
  { id: "verify", numeric: true, label: "Verify", selected: true },

  {
    id: "emailDate",
    numeric: true,
    label: "Email sent date",
    selected: true,
  },
  {
    id: "subscriptions",
    numeric: false,
    label: "Subscription",
    selected: true,
  },
  {
    id: "leadReturned",
    numeric: true,
    label: "Returned",
    selected: true,
  },
  {
    id: "returnReason",
    numeric: false,
    label: "Return Reason",
    selected: true,
  },
  { id: "feedback", numeric: true, label: "Feedback", selected: true },
  { id: "trash", numeric: true, label: "Trash", selected: true },
];

export const headCellsZZZ = [
  { id: "name", numeric: false, label: "Name", selected: true },
  { id: "phone", numeric: false, label: "Phone Number", selected: true },
  { id: "email", numeric: false, label: "Email Address", selected: true },
  { id: "address", numeric: false, label: "Address", selected: true },
  { id: "city", numeric: false, label: "City", selected: true },
  { id: "state", numeric: false, label: "State", selected: true },
  { id: "zipcode", numeric: false, label: "Zipcode", selected: true },
  { id: "county", numeric: false, label: "County", selected: true },
  {
    id: "privateNotes",
    numeric: false,
    label: "Private Notes",
    selected: true,
  },
  { id: "action", numeric: true, label: "Action", selected: true },
  { id: "notes", numeric: false, label: "Notes", selected: true },
  { id: "verify", numeric: true, label: "Verify", selected: true },
  { id: "emailDate", numeric: true, label: "Sent date", selected: true },
  {
    id: "subscriptions",
    numeric: false,
    label: "Subscription",
    selected: true,
  },
  { id: "leadReturned", numeric: true, label: "Accepted", selected: true },
  { id: "returnReason", numeric: false, label: "Reason", selected: true },
  { id: "feedback", numeric: true, label: "Feedback", selected: true },
  { id: "trash", numeric: true, label: "Trash", selected: true },
];

export const headCellsUser = [
  { id: "name", numeric: false, label: "Name", selected: true },
  { id: "phone", numeric: false, label: "Phone Number", selected: true },
  {
    id: "email",
    numeric: false,
    label: "Email Address",
    selected: true,
  },
  { id: "address", numeric: false, label: "Address", selected: true },
  { id: "city", numeric: false, label: "City", selected: true },
  { id: "state", numeric: false, label: "State", selected: true },
  { id: "zipcode", numeric: false, label: "Zipcode", selected: true },
  { id: "county", numeric: false, label: "County", selected: true },

  // { id: "action", numeric: true, label: "Action", selected: true },
  { id: "notes", numeric: false, label: "Notes", selected: true },
  // {
  //   id: "privateNotes",
  //   numeric: false,
  //   label: "Private Notes",
  //   selected: true,
  // },
  { id: "verify", numeric: true, label: "Verify", selected: true },
  { id: "emailDate", numeric: true, label: "Sent date", selected: true },
  {
    id: "subscriptions",
    numeric: false,
    label: "Subscription",
    selected: true,
  },
  {
    id: "leadReturned",
    numeric: true,
    label: "Accepted",
    selected: true,
  },
  {
    id: "returnReason",
    numeric: false,
    label: "Reason",
    selected: true,
  },
  { id: "feedback", numeric: true, label: "Feedback", selected: true },
  { id: "trash", numeric: true, label: "Trash", selected: true },
];

const EnhancedTableHead = (props) => {
  const {
    classes,
    order,
    headCells,
    orderBy,
    onRequestSort,
    onSelectAllClick,
    numSelected,
    rowCount,
    leads,
    setLeads,
  } = props;

  const createSortHandler = (property) => async (event) => {
    await onRequestSort(event, property);
  };
  
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells
          .filter((e) => e.selected)
          .map((headCell) => (
            <TableCell
              style={{ textAlign: "center" }}
              key={headCell.id}
              padding="none"
              sortDirection={
                orderBy === headCell.id ? order.toLowerCase() : false
              }
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={
                  orderBy === headCell.id ? order.toLowerCase() : "asc"
                }
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;

import { useMutation, useQueryClient } from 'react-query';
import {
  DeleteSubscription,
  PatchSubscription,
  PostSubscription
} from './actionSubscription';

export const useMutationPostSubscription = () => {
  const queryClient = useQueryClient();

  return useMutation(PostSubscription, {
    onSuccess: () => {
      queryClient.invalidateQueries('subscription');
    }
  });
};

export const useMutationPatchtSubscription = () => {
  const queryClient = useQueryClient();

  return useMutation(PatchSubscription, {
    onSuccess: () => {
      queryClient.invalidateQueries('subscription');
    }
  });
};

export const useMutationDeletetSubscription = () => {
  const queryClient = useQueryClient();

  return useMutation(DeleteSubscription, {
    onSuccess: () => {
      queryClient.invalidateQueries('subscription');
    }
  });
};

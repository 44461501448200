/* eslint-disable react/prop-types */
import React from 'react';
import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { Card } from './index';

const TableSubscription = ({
  isLoading,
  styled,
  data,
  handleOpenEdit,
  deleteSubs
}) => {
  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          zIndex: '1000',
          marginTop: '50px'
        }}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <Card>
      <TableContainer style={styled.container}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={styled.tableCell}>Id</TableCell>
              <TableCell style={styled.tableCell}>Name</TableCell>
              <TableCell style={styled.tableCell}>Lead Quantity</TableCell>
              <TableCell style={styled.tableCell}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map(data => {
                return (
                  <TableRow hover key={data.id} tabIndex={-1}>
                    <TableCell style={styled.tableCell}>{data.id}</TableCell>
                    <TableCell style={styled.tableCell}>{data.name}</TableCell>
                    <TableCell style={styled.tableCell}>
                      {data.leadQuantity}
                    </TableCell>
                    <TableCell style={styled.tableCell}>
                      <Button
                        onClick={() => handleOpenEdit({ data })}
                        startIcon={<EditIcon />}
                      />
                      <Button
                        onClick={() => deleteSubs({ data })}
                        startIcon={<DeleteIcon />}></Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default TableSubscription;

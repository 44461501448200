import { getleadSent, patchLeadSent } from "queries/leads/leads.queries";
import { useRef, useState } from "react";
import React from "react";
import { useQuery, useQueryClient } from "react-query";
import { Table, Input, Space } from "antd";
import { Backdrop, Button, CircularProgress, Tooltip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import EditLead from "./modalEditLead/editLead";
import { useEffect } from "react";
import styled from "styled-components";
import { useMutationPatchLeadSent } from "hooks/mutation/leads/leadMutation";
import { convertTimeToUTC } from "utils/convertTimeToUTC";
import { getTimeZone } from "utils/getTimeZone";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import CancelScheduleSendIcon from "@material-ui/icons/CancelScheduleSend";
import Swal from "sweetalert2";
import { getBuyerById } from "queries/buyer/buyer.queries";
import { makeStyles } from "@material-ui/styles";
import { getDispute, getRemainCount } from "common/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: "#fff",
  },
}));

const LeadSent = () => {
  const searchInput = useRef(null);
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [patchData, setPatchData] = useState({
    id: "",
    emailDate: "",
    buyers: "",
    emailSent: "",
  });
  const [buyerMatched, setBuyerMatched] = useState([]);
  const [shouldMutate, setShouldMutate] = useState(false);
  const [state, setState] = useState("");
  const [newData, setNewData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [loadNotification, setLoadNotification] = useState(
    "Loading leads sent..."
  );
  const [infoBuyer, setInfoBuyer] = useState({});

  const token = localStorage.getItem("token");
  const { accessToken } = JSON.parse(token);

  const { data, isLoading } = useQuery(["leadSent"], () =>
    getleadSent({ accessToken })
  );

  useEffect(() => {
    if (shouldMutate) {
      mutatePatchLeadSent({ accessToken, patchData, id: patchData.id });
      setOpen(false);
      setShouldMutate(false);
    }
  }, [patchData, shouldMutate]);

  const { mutate: mutatePatchLeadSent } = useMutationPatchLeadSent();

  const handleOpen = (lead) => {
    console.log(lead);
    const buyers = lead.buyerMatched;

    const leadsBuyer = lead.buyerMatched.filter(
      (buyer) => buyer.id === lead.buyers[0].id
    );

    const leadsArray = leadsBuyer.length > 0 ? leadsBuyer[0].leads : [];

    const leadRemain = getRemainCount(lead.buyers[0], leadsArray);
    const disputeRate = getDispute(leadsArray);
    const subscriptionPrefix = getBuyerSubscriptionPrefix(lead.buyers[0]);

    const dateStr = lead.emailDate;
    console.log(dateStr);
    const dateObj = new Date(dateStr);
    const timeZoneOffset = getTimeZone(lead.state);
    const adjustedTime = dateObj.getTime() + timeZoneOffset * 60 * 60 * 1000;
    dateObj.setTime(adjustedTime);

    const hour = dateObj.getUTCHours();
    const minutes = dateObj.getUTCMinutes();

    let hourStr = hour.toString();
    let minutesStr = minutes.toString();

    if (hourStr.length === 1) {
      hourStr = "0" + hourStr;
    }

    if (minutesStr.length === 1) {
      minutesStr = `0${minutesStr}`;
    }

    const emailDate = `${hourStr}:${minutesStr}`;

    setState(lead.state);
    setPatchData({
      ...patchData,
      id: lead.key,
      emailDate: emailDate,
      buyers: lead.buyers,
      emailSent: lead.emailSent,
    });
    setBuyerMatched(buyers);
    setInfoBuyer({
      leadRemain: leadRemain,
      disputeRate: disputeRate,
      subscriptionPrefix: subscriptionPrefix,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleConfirm = async () => {
    const zoneTime = getTimeZone(state);
    const originalDate = convertTimeToUTC(patchData.emailDate);
    console.log(patchData.emailDate);
    console.log(originalDate);

    if (
      patchData.emailDate.length === 5 &&
      patchData.emailDate.charAt(2) === ":"
    ) {
      const convertedTime = convertTimeToUTC(patchData.emailDate);
      console.log(convertedTime);

      setPatchData((prevData) => ({
        ...prevData,
        emailDate: convertedTime,
      }));
      setShouldMutate(true);
    } else {
      mutatePatchLeadSent({ accessToken, patchData, id: patchData.id });
      setOpen(false);
    }
  };

  const cancelSent = async (lead) => {
    console.log(lead);
    const leadId = lead.key;

    const updatedPatchData = {
      id: lead.key,
      emailDate: null,
      buyers: lead.buyers,
      emailSent: true,
    };

    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You're going to cancel sent this lead!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3f51b5",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      customClass: {
        title: "my-swal-title-class",
        text: "my-swal-text-class",
      },
    });
    if (result.isConfirmed) {
      try {
        mutatePatchLeadSent({
          accessToken,
          patchData: updatedPatchData,
          id: leadId,
        });
        Swal.fire({
          title: "Success!",
          text: "Cancel sent",
          icon: "success",
          confirmButtonColor: "#3f51b5",
          customClass: {
            title: "my-swal-title-class",
            text: "my-swal-text-class",
          },
        });
      } catch (error) {
        throw error;
      }
    }
  };

  const getBuyerSubscriptionPrefix = (buyer) => {
    if (buyer.subscriptions && buyer.subscriptions[0]) {
      const subscriptionName = buyer.subscriptions[0].name;
      if (subscriptionName === "NO") return "";
      return `(${subscriptionName.substring(0, 1).toUpperCase()})`;
    }
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 6 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 5,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 80,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const openModal = () => {
    if (open) {
      return (
        <EditLead
          open={open}
          handleClose={handleClose}
          patchData={patchData}
          buyerMatched={buyerMatched}
          setPatchData={setPatchData}
          handleConfirm={handleConfirm}
          state={state}
          getRemainCount={getRemainCount}
          getDispute={getDispute}
          getBuyerSubscriptionPrefix={getBuyerSubscriptionPrefix}
          infoBuyer={infoBuyer}
        />
      );
    }
  };

  const rows = data?.map((lead) => {
    return {
      key: lead.id,
      name: lead.name,
      phone: lead.phone,
      email: lead.email,
      address: lead.address,
      city: lead.city,
      state: lead.state,
      zipcode: lead.zipcode,
      county: lead.county,
      privateNotes: lead.privateNotes,
      emailDate: lead.emailDate,
      buyers: lead.buyers,
      buyerMatched: lead.buyerMatched,
      emailSent: lead.emailSent,
      additionalInfo: lead.additionalInfo,
    };
  });

  const columns = [
    {
      title: "Action",
      //dataIndex: "leadReturned",
      key: "action",
      align: "center",
      justify: "center",
      render: (lead, record) => {
        return (
          <div style={{ display: "flex", width: "90px" }}>
            <Tooltip title="Edit">
              <Button
                onClick={() => handleOpen(record)}
                startIcon={<EditIcon />}
              />
            </Tooltip>
            <Tooltip title="Cancel sent">
              <Button
                onClick={() => cancelSent(record)}
                startIcon={<CancelScheduleSendIcon />}
              />
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...getColumnSearchProps("name"),
      ellipsis: true,
    },
    {
      title: "Contact information",
      dataIndex: "phone",
      key: "phone",
      width: 200,
      render: (lead, record) => {
        return <span>{`${record.phone}, ${record.email.toLowerCase()}`}</span>;
      },
    },

    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: 300,
      render: (lead, record) => {
        return (
          <span>{`${record.address}, ${record.city}, ${record.state}, ${record.zipcode}`}</span>
        );
      },
    },
    {
      title: "County",
      dataIndex: "county",
      key: "county",
    },

    {
      title: "Private Notes",
      dataIndex: "privateNotes",
      key: "privateNotes",
    },
    {
      title: "Email Date",
      dataIndex: "emailDate",
      key: "emailDate",
      width: 100,
      render: (lead, record) => {
        const dateStr = lead;
        const state = record.state;

        const dateObj = new Date(dateStr);
        const timeZoneOffset = getTimeZone(state);

        // Ajustar manualmente la hora según el desfase horario.
        const adjustedTime =
          dateObj.getTime() + timeZoneOffset * 60 * 60 * 1000;
        dateObj.setTime(adjustedTime);

        const hour = dateObj.getUTCHours();
        const minutes = dateObj.getUTCMinutes();

        const hourStr = hour.toString().padStart(2, "0");
        const minutesStr = minutes.toString().padStart(2, "0");

        return <>{`${hourStr}:${minutesStr}`}</>;
      },
      defaultSortOrder: "ascend",
      sorter: (a, b) => {
        const dateA = new Date(a.emailDate).getTime();
        const dateB = new Date(b.emailDate).getTime();
        return dateA - dateB;
      },
    },

    {
      title: "Buyer Assigned",
      dataIndex: "buyers",
      key: "buyers",

      sorter: (a, b) => {
        const buyerA = a.buyers ? a.buyers[0].name : "";
        const buyerB = b.buyers ? b.buyers[0].name : "";
        return buyerA.localeCompare(buyerB);
      },
      render: (lead, record) => {
        const subscriptionPrefix = getBuyerSubscriptionPrefix(lead[0]);

        const leadsBuyer = record.buyerMatched.filter(
          (buyer) => buyer.id === record.buyers[0].id
        );

        const leadsArray = leadsBuyer.length > 0 ? leadsBuyer[0].leads : [];

        const leadRemain = getRemainCount(record.buyers[0], leadsArray);
        const disputeRate = getDispute(leadsArray);

        if (lead === undefined) {
          return <>No Buyer Assigned</>;
        } else {
          return (
            <span style={{ width: "500px" }}>
              {lead[0].name}
              {`\n`}
              {disputeRate}/ {leadRemain}/ {subscriptionPrefix}
            </span>
          );
        }
      },
    },
  ];

  return (
    <Container>
      <Title>Lead Sent</Title>
      <Table
        dataSource={rows}
        columns={columns}
        pagination={{ pageSize: 100 }}
        scroll={{ y: 900, x: true }}
      />
      <Backdrop className={classes.backdrop} open={isLoading}>
        {loadNotification} <CircularProgress color="primary" />
      </Backdrop>
      {openModal()};
    </Container>
  );
};

export default LeadSent;

export const Card = styled.div`
  margin: 50px 0;
`;

export const ButonContainer = styled.div`
  display: flex;
`;

const Container = styled.div`
  padding: 50px;
  max-width: 1600px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 20px;
  margin-bottom: 20px;
`;

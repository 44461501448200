import axios from "axios";
import { apiUrl, config } from "queries/config";

export const getRoles = async ({ accessToken }) => {
  const url = `${apiUrl}/roles`;
  config.headers["x-access-token"] = accessToken;

  const { data } = await axios.get(url, config);

  return data.roles;
};

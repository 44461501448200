import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

const RouteWithLayout = (props) => {
  const { layout: Layout, component: Component, ...rest } = props;

  // private router for auth admin
  // eslint-disable-next-line react/no-multi-comp
  const AdminRoute = ({ component: Comp, ...rest }) => {
    const token = localStorage.getItem("token");
    let accessToken = null;
    let role = null;
    if (token) {
      accessToken = JSON.parse(token).accessToken;
      role = JSON.parse(token).roles[0];
    }
    return (
      <Route
        {...rest}
        render={(props) =>
          accessToken && role === "ROLE_ADMIN" ? (
            <Layout>
              <Component {...props} />
            </Layout>
          ) : (
            <Redirect
              to={{ pathname: "/sign-in", state: { from: props.location } }}
            />
          )
        }
      />
    );
  };

  // private router for auth admin
  // eslint-disable-next-line react/no-multi-comp
  const UserRoute = ({ component: Comp, ...rest }) => {
    console.log(props.user);
    const token = localStorage.getItem("token");
    let accessToken = null;
    let role = null;
    if (token) {
      accessToken = JSON.parse(token).accessToken;
      role = JSON.parse(token).roles[0];
    }
    return (
      <Route
        {...rest}
        render={(props) =>
          accessToken && (role === "ROLE_USER" || role === "ROLE_ADMIN") ? (
            <Layout>
              <Component {...props} />
            </Layout>
          ) : (
            <Redirect
              to={{ pathname: "/sign-in", state: { from: props.location } }}
            />
          )
        }
      />
    );
  };

  // private router for auth users
  // eslint-disable-next-line react/no-multi-comp
  const BuyerRoute = ({ component: Comp, ...rest }) => {
    const token = localStorage.getItem("token");
    let accessToken = null;
    let role = null;
    if (token) {
      accessToken = JSON.parse(token).accessToken;
      role = JSON.parse(token).roles[0];
    }
    return (
      <Route
        {...rest}
        render={(props) =>
          accessToken && (role === "ROLE_BUYER" || role === "ROLE_ADMIN") ? (
            <Layout>
              <Component {...props} />
            </Layout>
          ) : (
            <Redirect
              to={{ pathname: "/sign-in", state: { from: props.location } }}
            />
          )
        }
      />
    );
  };

  if (props.admin) {
    return (
      <AdminRoute
        {...rest}
        render={(matchProps) => (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        )}
      />
    );
  }

  if (props.user) {
    return (
      <UserRoute
        {...rest}
        render={(matchProps) => (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        )}
      />
    );
  }

  if (props.buyer) {
    return (
      <BuyerRoute
        {...rest}
        render={(matchProps) => (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        )}
      />
    );
  }
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
  private: PropTypes.bool,
};

export default RouteWithLayout;

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Axios from "axios";
import { makeStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { CSVLink } from "react-csv";
import moment from "moment";
import { history } from "App";
import { useQuery } from "react-query";
import { getCountiesWithBuyers } from "queries/buyer/buyer.queries";
import {
  getLastLead,
  getSubscriptionPace,
  getRemainCount,
  dateDiff,
  getDispute,
} from "../../../../common/utils";

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
}));

const headers = [
  { label: "Name", key: "name" },
  { label: "Leads Purchased", key: "leadsPurchased" },
  { label: "Leads Used", key: "leadsUsed" },
  { label: "Leads Remaining", key: "leadRemaining" },
  { label: "Subscription", key: "subscription" },
  { label: "Subscription Expiration", key: "subscriptionExpiration" },
  { label: "Last lead received", key: "lastLeadReceived" },
  { label: "Days since last lead received", key: "daysOfLastLeadReceived" },
  { label: "Dispute %", key: "dispute" },
  { label: "Subscription pace", key: "subscriptionPace" },
  { label: "Allow return over 50%", key: "allowReturn" },
];

const BuyerToolbar = (props) => {
  const { className, role, handleAddBuyer, ...rest } = props;
  const csvInstance = useRef();
  const classes = useStyles();
  const [csvData, setCsvData] = useState(false);
  const token = localStorage.getItem("token");
  const { accessToken } = JSON.parse(token);

  const { data, isLoading } = useQuery(["buyer"], () => getCountiesWithBuyers(accessToken));
  const [csvDataCounties, setCsvCounties] = useState([]);

  const csvHeaders = [
    { label: "County", key: "County" },
    { label: "Buyers", key: "Buyers" },
  ];
  const handleDownloadCSV = () => {
    Axios.get(`${process.env.REACT_APP_BACKEND_API}/buyers`)
      .then((response) => {
        let buyers = response.data.buyers;
        localStorage.setItem("buyers", JSON.stringify(buyers));

        buyers = buyers
          .filter((buyer) => !buyer.hold)
          .map((buyer) => {
            return {
              name: buyer.name,
              leadsPurchased: buyer.leadsPurchased ? buyer.leadsPurchased : "",
              leadsUsed: buyer.leadsUsed ? buyer.leadsUsed : "",
              leadRemaining: getRemainCount(buyer, buyer.leads),
              subscription:
                buyer.subscriptions && buyer.subscriptions[0]
                  ? buyer.subscriptions[0].name
                  : "NO",
              subscriptionExpiration: buyer.expiration
                ? moment(buyer.expiration).format("MM/DD/YY")
                : "",
              lastLeadReceived:
                buyer.leads && buyer.leads.length > 0
                  ? moment(getLastLead(buyer.leads)).format("MM/DD/YY")
                  : "",
              daysOfLastLeadReceived:
                buyer.leads && buyer.leads.length > 0
                  ? dateDiff(buyer.leads)
                  : "",
              dispute:
                buyer.leads && buyer.leads.length > 0
                  ? getDispute(buyer.leads)
                  : "-",
              subscriptionPace: getSubscriptionPace(buyer),
              allowReturn: getAllowReturn(buyer),
            };
          });

        setCsvData(buyers);
      })
      .catch(function (error) { });
  };

  useEffect(() => {
    if (csvData && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCsvData(false);
      });
    }
  }, [csvData]);

  const getAllowReturn = (buyer) => {
    if (buyer.allowReturn) return "YES";
    else return "NO";
  };
  const handleLeadSentPerMonth = () => {
    history.push("/buyerleadsentpermonth");
  };

  const handleCountiesWithBuyers = () => {
    setCsvCounties(data.data);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />
        {role === "ROLE_ADMIN" && (
          <>
            <Button
              color="primary"
              className={classes.importButton}
              onClick={() => handleCountiesWithBuyers()}
              size="small"
            >
              <CSVLink
                data={csvDataCounties}
                headers={csvHeaders}
                filename="CountiesBuyers.csv"
              >
                Counties With Buyers
              </CSVLink>
            </Button>

            <Button
              color="primary"
              className={classes.importButton}
              onClick={() => handleLeadSentPerMonth()}
              size="small"
            >
              Leads sent per month
            </Button>
          </>
        )}

        <Button
          color="primary"
          variant="contained"
          onClick={() => handleAddBuyer(-1)}
          className={classes.importButton}
          size="small"
          startIcon={<AddIcon />}
        >
          Add Buyer
        </Button>


        <Button
          color="primary"
          variant="contained"
          onClick={() => handleDownloadCSV()}
          className={classes.importButton}
          startIcon={<CloudDownloadIcon />}
          size="small"
        >
          Download CSV
        </Button>

        {csvData ? (
          <CSVLink
            data={csvData}
            headers={headers}
            filename="buyers.csv"
            ref={csvInstance}
          />
        ) : (
          undefined
        )}
      </div>
    </div>
  );
};

BuyerToolbar.propTypes = {
  className: PropTypes.string,
};

export default BuyerToolbar;

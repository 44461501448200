import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
} from '@material-ui/core';

import AddLeadForm from './components/AddLeadForm';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  },
  bgSucesss: {
    backgroundColor: '#3f51b5 !important',
    textAlign: 'center !important',
    color: '#fff !important',
  }
}));

const Offer = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        >
          <AddLeadForm />
        </Grid>
      </Grid>

    </div>
  );
};

export default Offer;

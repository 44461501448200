import React, { useEffect } from 'react';
import clsx from 'clsx';
import Axios from 'axios';
import { NotificationManager } from 'react-notifications';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { history } from 'App';
import {
  truncateString,
  getBackgroundColor,
} from '../../../../common/utils';
import moment from 'moment';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Button,
  TablePagination,
  Tooltip,
  TableContainer,
  Paper,
} from '@material-ui/core';

import EnhancedTableToolbar from './EnhancedTableToolbar';
import EnhancedTableHead, { headCellsLead } from './EnhancedTableHead';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableCell: {
    maxWidth: 40,
    padding: "5px",
    overflowWrap: "break-word",
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  timepickRadio: {
    padding: '20px'
  },
  container: {
    maxHeight: 640,
  },
}));

const LogTable = props => {
  const {
    className,
    searchWord,
    leads,
    filterSent,
    onChangeFilterSent,
    filterBilling,
    onChangeFilterBilling,
    onChangeSearch,
    onSendLead,
    onChangeBuyer,
    onChangeFeedback,
    buyers,
    buyerId,
    isBuyer,
    onChangeTrash,
    onSendLeadToForm,
    includeTrash,
    filterSubscription,
    onChangeFilterSubscription,
    count,
    rowsPerPage,
    page,
    setPage,
    setRowsPerPage,
    order,
    orderBy,
    setOrder,
    setOrderBy,
    ...rest
  } = props;

  const classes = useStyles();

  const [headCells, setHeadCells] = React.useState([]);

  useEffect(() => {
    let headcell = headCellsLead

    setHeadCells(headcell)
  }, []);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    event.preventDefault();
    history.push(`/addlead?id=${name}&isBuyer=${isBuyer}`)
  };


  const handleResendClick = (event, lead) => {
    if (lead.buyers && lead.buyers[0]) {
      if (!lead.smsSent) {
        Axios.post(`${process.env.REACT_APP_BACKEND_API}/lead/sendSMS`, { id: lead.id, sms: '1' })
          .then(response => {
            NotificationManager.success('Send Lead success');

          })
          .catch(function (error) {
            // console.log(error)
            NotificationManager.error("failed send lead");
          });
      }

      if (!lead.emailSent) {
        Axios.post(`${process.env.REACT_APP_BACKEND_API}/lead/sendSMS`, { id: lead.id, email: '1' })
          .then(response => {
            NotificationManager.success('Send Lead success');

          })
          .catch(function (error) {
            // console.log(error)
            NotificationManager.error("failed send lead");
          });
      }
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getVerify = verify => {
    switch (verify) {
      case 0: return 'NO';
      case 1: return 'Not - verified';
      case 2: return 'Yes - verified';
      case 3: return 'Trash';
      default: return 'NO';
    }
  }

  const convertBooleanToText = returned => {
    switch (returned) {
      case false: return 'NO';
      case true: return 'YES';
      default: return 'NO';
    }
  };

  const onChangeFilterHead = event => {


    const heads = headCells.map(e => {
      if (e.id === event.target.name) {
        e.selected = !e.selected
        // console.log(e.selected)
      }

      return e
    })

    setHeadCells(heads)
  }

  if (headCells.length < 10) return <div />
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <Paper className={classes.paper}>
          <EnhancedTableToolbar
            searchWord={searchWord}
            onChange={onChangeSearch}
            headCells={headCells}
            onChangeFilterHead={onChangeFilterHead}
          />
          <TableContainer className={classes.container}>
            <Table className={classes.table} stickyHeader>
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                buyerId={buyerId}
                isBuyer={isBuyer}
                onRequestSort={handleRequestSort}
                rowCount={count}
                headCells={headCells}
              />
              <TableBody>
                {leads && leads.map((lead, index) => {

                  return (
                    <TableRow
                      className={classes.tableRow}
                      key={lead.id}
                      hover
                      style={{ backgroundColor: getBackgroundColor(lead) }}
                    >

                      {headCells.filter(e => e.id === 'name')[0].selected && <TableCell className={classes.tableCell} onClick={(event) => handleClick(event, lead.id)}>{lead.name}</TableCell>}
                      {headCells.filter(e => e.id === 'phone')[0].selected && <TableCell className={classes.tableCell} onClick={(event) => handleClick(event, lead.id)}>{lead.phone}</TableCell>}
                      {headCells.filter(e => e.id === 'email')[0].selected && <TableCell className={classes.tableCell} onClick={(event) => handleClick(event, lead.id)}>{lead.email}</TableCell>}
                      {headCells.filter(e => e.id === 'address')[0].selected && <TableCell className={classes.tableCell} onClick={(event) => handleClick(event, lead.id)}>{lead.address}</TableCell>}
                      {headCells.filter(e => e.id === 'city')[0].selected && <TableCell className={classes.tableCell} onClick={(event) => handleClick(event, lead.id)}>{lead.city}</TableCell>}
                      {headCells.filter(e => e.id === 'state')[0].selected && <TableCell className={classes.tableCell} onClick={(event) => handleClick(event, lead.id)}>{lead.state}</TableCell>}
                      {headCells.filter(e => e.id === 'zipcode')[0].selected && <TableCell className={classes.tableCell} onClick={(event) => handleClick(event, lead.id)}>{lead.zipcode}</TableCell>}
                      {headCells.filter(e => e.id === 'county')[0].selected && <TableCell className={classes.tableCell} onClick={(event) => handleClick(event, lead.id)}>{lead.county}</TableCell>}
                      {headCells.filter(e => e.id === 'privateNotes')[0].selected && <Tooltip title={lead.privateNotes}><TableCell className={classes.tableCell} onClick={(event) => handleClick(event, lead.id)}>{truncateString(lead.privateNotes)}</TableCell></Tooltip>}
                      {headCells.filter(e => e.id === 'buyers')[0].selected && <TableCell className={classes.tableCell} onClick={(event) => handleClick(event, lead.id)}>{lead.buyers && lead.buyers[0] ? lead.buyers[0].name : ''}</TableCell>}
                      {headCells.filter(e => e.id === 'action')[0].selected && <TableCell className={classes.tableCell} >
                        <Button variant="outlined"
                          size="small"
                          onClick={(event) => handleResendClick(event, lead)}>
                          ReSend
                        </Button>

                      </TableCell>}
                      {headCells.filter(e => e.id === 'smsSent')[0].selected && <TableCell className={classes.tableCell} onClick={(event) => handleClick(event, lead.id)}>{convertBooleanToText(lead.smsSent)}</TableCell>}
                      {headCells.filter(e => e.id === 'smsFailedReason')[0].selected && <TableCell className={classes.tableCell} onClick={(event) => handleClick(event, lead.id)}>{lead.smsFailedReason}</TableCell>}
                      {headCells.filter(e => e.id === 'emailSent')[0].selected && <TableCell className={classes.tableCell} onClick={(event) => handleClick(event, lead.id)}>{convertBooleanToText(lead.emailSent)}</TableCell>}
                      {headCells.filter(e => e.id === 'emailFailedReason')[0].selected && <TableCell className={classes.tableCell} onClick={(event) => handleClick(event, lead.id)}>{lead.emailFailedReason}</TableCell>}

                      {!isBuyer && headCells.filter(e => e.id === 'notes')[0].selected && <Tooltip title={lead.notes}><TableCell className={classes.tableCell} onClick={(event) => handleClick(event, lead.id)}>{truncateString(lead.notes)}</TableCell></Tooltip>}
                      {!isBuyer && headCells.filter(e => e.id === 'verify')[0].selected && <TableCell className={classes.tableCell} onClick={(event) => handleClick(event, lead.id)}>{getVerify(lead.verify)}</TableCell>}

                      {headCells.filter(e => e.id === 'emailDate')[0].selected && <TableCell className={classes.tableCell} onClick={(event) => handleClick(event, lead.id)}>{lead.emailDate ? isBuyer ? moment(lead.emailDate).format('MM/DD/YY') : moment(lead.emailDate).format('MM/DD/YY HH:mm') : ''}</TableCell>}
                      {buyerId && headCells.filter(e => e.id === 'subscriptions')[0].selected && <TableCell>{lead.subscriptions && lead.subscriptions[0] ? lead.subscriptions[0].name : "NO"}</TableCell>}
                      {isBuyer && headCells.filter(e => e.id === 'leadSold')[0].selected && <TableCell className={classes.tableCell}>
                        {convertBooleanToText(lead.leadSold)}
                      </TableCell>}
                      {headCells.filter(e => e.id === 'leadReturned')[0].selected && <TableCell className={classes.tableCell}>
                        {convertBooleanToText((lead.buyers && lead.buyers.length > 0 && lead.buyers[0].email === 'ZZZ@compass.com') ? lead.leadForm : lead.leadReturned)}
                      </TableCell>}
                      {headCells.filter(e => e.id === 'returnReason')[0].selected && <TableCell className={classes.tableCell}>
                        {(lead.buyers && lead.buyers.length > 0 && lead.buyers[0].email === 'ZZZ@compass.com') ? lead.resultForm : lead.returnReason}
                      </TableCell>}

                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 75, 100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </CardContent>

    </Card>
  );
};

LogTable.propTypes = {
  className: PropTypes.string,
  filterSent: PropTypes.string,
  leads: PropTypes.array.isRequired,
  onChangeSearch: PropTypes.func,
  onChangeFilterSent: PropTypes.func,
};
LogTable.whyDidYouRender = true;
export default LogTable;

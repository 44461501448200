import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import validate from 'validate.js';
import PropTypes from 'prop-types';
import Axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  InputAdornment
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { states } from '../../../common/constants';
import TextMaskInput from '../../../components/TextMaskInput';

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
};

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 10,
    padding: 10
  }
}));

const AddLeadForm = props => {
  const { className, history, ...rest } = props;
  
  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: { state: 'AL' },
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (
      !formState.values['city'] ||
      !formState.values['state'] ||
      !formState.values['county'] ||
      formState.values['city'] === '' ||
      formState.values['county'] === ''
    ) {
      NotificationManager.error('invalid city and county');
      return;
    }

    const params = {
      name: formState.values['name'],
      phone: formState.values['phone'],
      address: formState.values['address'],
      city: formState.values['city'],
      state: formState.values['state'],
      county: formState.values['county'],
      zipcode: formState.values['zipcode'],
      email: formState.values['email']
    };
    Axios.post(`${process.env.REACT_APP_BACKEND_API}/lead/create_lead`, params)
      .then(response => {
        NotificationManager.success('Submit data success');
        setFormState(formState => ({
          ...formState,
          values: {
            email: '',
            phone: '',
            state: 'AL',
            zipcode: '',
            name: '',
            city: '',
            county: '',
            address: ''
          },
          touched: {}
        }));
      })
      .catch(function(error) {
        NotificationManager.error('Create Lead failed');
        if(error.response.status === 429){
          history.push("too-many-request");
        }
      });
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div class="container content-lg">
      <div class="text-center mt-5 mb-3 mb-md-5">
        <h1 class="heading ">Get a cash offer for your house</h1>
        <p class="subheading">
          We are buying houses in your area. Tell us about the house you are
          selling so we can make you an offer.
        </p>
      </div>
      <Card {...rest} className={clsx(classes.root, className)}>
        <form action="https://usebasin.com/f/bf9934f50e1b" method="post" >
          <CardHeader
            subheader="The information can be edited"
            title="Add Lead"
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  helperText="Please specify name"
                  label="Name"
                  margin="dense"
                  name="name"
                  onChange={handleChange}
                  required
                  value={formState.values.name}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  margin="dense"
                  name="phone"
                  onChange={handleChange}
                  required
                  value={formState.values.phone}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                    inputComponent: TextMaskInput
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Email Address"
                  margin="dense"
                  name="email"
                  onChange={handleChange}
                  required
                  value={formState.values.email}
                  error={hasError('email')}
                  helperText={
                    hasError('email') ? formState.errors.email[0] : null
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Address"
                  margin="dense"
                  name="address"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.address}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="City"
                  margin="dense"
                  name="city"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.city}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Select State"
                  margin="dense"
                  name="state"
                  onChange={handleChange}
                  required
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={formState.values.state}
                  variant="outlined">
                  {states.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Zip Code"
                  margin="dense"
                  name="zipcode"
                  onChange={handleChange}
                  required
                  value={formState.values.zipcode}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="County"
                  margin="dense"
                  name="county"
                  onChange={handleChange}
                  required
                  value={formState.values.county}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              startIcon={<SaveIcon />}
              disabled={!formState.isValid}>
              Submit
            </Button>
          </CardActions>
        </form>
      </Card>
    </div>
  );
};

AddLeadForm.propTypes = {
  className: PropTypes.string
};

export default AddLeadForm;

import {
  deleteSubscription,
  patchSubscription,
  postSubscription,
} from "queries/subscription/subscription.queries";
import { NotificationManager } from "react-notifications";

export const PostSubscription = async ({
  accessToken,
  postSubscriptionData,
}) => {
  try {
    await postSubscription({ accessToken, postSubscriptionData });
    NotificationManager.success("Create data success");
  } catch (e) {
    NotificationManager.error("Create subs failed");
    throw e;
  }
};

export const PatchSubscription = async ({
  accessToken,
  postSubscriptionData,
  id,
}) => {
  try {
    await patchSubscription({ accessToken, postSubscriptionData, id });
    NotificationManager.success("Update data success");
  } catch (e) {
    NotificationManager.error("Update subs failed");
    throw e;
  }
};

export const DeleteSubscription = async ({ accessToken, id }) => {
  try {
    await deleteSubscription({ accessToken, id });
    NotificationManager.success("Delete data success");
  } catch (e) {
    NotificationManager.error("Delete subs failed");
    throw e;
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';

export const headCellsBuyer = [
  { id: 'name', numeric: false, label: 'Name', selected: true },
  // { id: 'email', numeric: false, label: 'Email', selected: true },
  // { id: 'phone', numeric: false, label: 'Phone', selected: true },
  // { id: 'countiesPurchased', numeric: true, label: 'Counties Purchased', selected: true },
  { id: 'leadsPurchased', numeric: true, label: 'Leads Purchased', selected: true },
  { id: 'leadsUsed', numeric: false, label: 'Leads Used', selected: true },
  { id: 'leadsRemained', numeric: true, label: 'Leads Remaining', selected: true },
  { id: 'subscriptions', numeric: false, label: 'Subscription', selected: true },
  { id: 'expiration', numeric: false, label: 'Subscription Expiration', selected: true },
  // { id: 'otherEmail', numeric: false, label: 'Other Email', selected: true },
  { id: 'lastLeadReceived', numeric: false, label: 'Last lead received', selected: true },
  { id: 'daysOfLastLeadReceived', numeric: false, label: 'Days since last lead received', selected: true },
  { id: 'dispute', numeric: false, label: 'Dispute %', selected: true },
  { id: 'subscriptionPace', numeric: false, label: 'Subscription pace', selected: true },
  { id: 'allowReturn', numeric: false, label: 'Allow return over 50%', selected: true },
];

const EnhancedTableHead = (props) => {
  const { classes, headCells, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.filter(e => e.selected).map((headCell) => (
          <TableCell
            key={headCell.id}
            padding='none'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default EnhancedTableHead;

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { SearchInput } from 'components';
import {
  Typography,
  Toolbar,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
  Tooltip,
  IconButton,
  Box,
  Popover,
} from '@material-ui/core';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import FilterListIcon from '@material-ui/icons/FilterList';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontSize: '12px'
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.light,
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
  searchInput: {
    marginRight: theme.spacing(1),
    minWidth: '300px'
  },
  columne: {
    display: 'flex',
    alignItems: 'center'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 90,
  },
  typography: {
    padding: theme.spacing(2),
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    searchWord,
    onChange,
    headCells,
    onChangeFilterHead,
  } = props;

  return (
    <Toolbar className={classes.root}>
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        Failed sending sms/email Leads
        </Typography>

      <div className={classes.columne}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search lead"
          onChange={e => onChange(e)}
          value={searchWord}
        />
        { <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => (
              <div>

                <Tooltip title="Filter list">
                  <IconButton aria-label="filter list" {...bindTrigger(popupState)}>
                    <FilterListIcon />
                  </IconButton>

                </Tooltip>

                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Box p={2}>
                    <FormControl component="fieldset" className={classes.formControl}>
                      <FormLabel component="legend">Show Columns</FormLabel>
                      <FormGroup>
                        {headCells.map(headCell => {
                          return <FormControlLabel
                            control={<Checkbox checked={headCell.selected} onChange={onChangeFilterHead} name={headCell.id} />}
                            label={headCell.label}
                          />
                        })}

                      </FormGroup>
                    </FormControl>
                  </Box>
                </Popover>
              </div>
            )}
          </PopupState>}
      </div>
    </Toolbar>
  );
};

export default EnhancedTableToolbar

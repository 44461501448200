import { useQueryClient, useMutation } from "react-query";
import { PatchLeadSent } from "./actionLeads";

export const useMutationPatchLeadSent = () => {
  const queryClient = useQueryClient();

  return useMutation(PatchLeadSent, {
    onSuccess: () => {
      queryClient.invalidateQueries("leadSent");
    },
  });
};

import React, { useState, useEffect } from "react";
import clsx from "clsx";
import Axios from "axios";
import { makeStyles } from "@material-ui/styles";
import { SearchInput } from "components";
import {
  Typography,
  Switch,
  Toolbar,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
  MenuItem,
  Select,
  InputLabel,
  Tooltip,
  IconButton,
  Box,
  Popover,
} from "@material-ui/core";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import FilterListIcon from "@material-ui/icons/FilterList";
import { Delete as DeleteIcon, Send as SendIcon } from "@material-ui/icons";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontSize: "12px",
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: "white",
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
  searchInput: {
    marginRight: theme.spacing(1),
    minWidth: "300px",
  },
  columne: {
    display: "flex",
    alignItems: "center",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 90,
  },
  typography: {
    padding: theme.spacing(2),
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    searchWord,
    onChange,
    searchBuyer,
    onChangeBuyer,
    filterSent,
    onChangeFilterSent,
    includeTrash,
    onChangeTrash,
    filterBilling,
    isBuyer,
    onChangeFilterBilling,
    filterSubscription,
    onChangeFilterSubscription,
    headCells,
    onChangeFilterHead,
    numSelected,
    role,
    onDeleteAll,
  } = props;

  const [subscriptions, setSubscriptions] = useState([]);

  const getSubscriptions = React.useCallback(() => {
    Axios.get(`${process.env.REACT_APP_BACKEND_API}/subscription`)
      .then((response) => {
        const subscriptions = response.data.subscriptions;
        setSubscriptions(subscriptions);
      })
      .catch(function(error) {});
  }, []);
  useEffect(() => {
    try {
      getSubscriptions();
    } catch (error) {}
  }, [getSubscriptions]);
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Leads
        </Typography>
      )}

      {numSelected > 0 && !isBuyer ? (
        <>
          <IconButton
            aria-label="Trash"
            size="small"
            style={{ marginLeft: "10px" }}
            // disabled={lead.leadSold}
            // {...bindTrigger(popupState)}
          >
            <SendIcon />
          </IconButton>
          <Tooltip title="Delete">
            <IconButton aria-label="delete" onClick={onDeleteAll}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <div className={classes.columne}>
          <SearchInput
            className={classes.searchInput}
            placeholder={"Search lead"}
            onChange={(e) => onChange(e)}
            value={searchWord}
          />
          {!isBuyer && (
            <SearchInput
              className={classes.searchInput}
              placeholder="Search Buyer"
              onChange={(e) => onChangeBuyer(e)}
              value={searchBuyer}
            />
          )}
          {!isBuyer && (
            <>
              <FormControl
                size="small"
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel id="demo-simple-select-filled-label">
                  Lead Sent
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={filterSent}
                  onChange={onChangeFilterSent}
                  label="Lead Sent"
                >
                  {role === "ROLE_ADMIN" && (
                    <MenuItem value={"all"}>All</MenuItem>
                  )}
                  {role === "ROLE_ADMIN" && (
                    <MenuItem value={"unsent"}>Unsent</MenuItem>
                  )}
                  <MenuItem value={"sent"}>Sent</MenuItem>
                </Select>
              </FormControl>

              <FormControl
                size="small"
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel id="demo-simple-select-filled-label">
                  Billing
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={filterBilling}
                  onChange={onChangeFilterBilling}
                  label="Lead Billing"
                >
                  <MenuItem value={"nobilling"}>No Billing</MenuItem>
                  <MenuItem value={"paid"}>Paid</MenuItem>
                  <MenuItem value={"unpaid"}>Unpaid</MenuItem>
                </Select>
              </FormControl>

              <FormControl
                size="small"
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel id="demo-simple-select-filled-label">
                  Subscription
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={filterSubscription}
                  onChange={onChangeFilterSubscription}
                  label="Lead Subscription"
                >
                  <MenuItem value={-1}>All</MenuItem>
                  {subscriptions.map((subscription) => {
                    return (
                      <MenuItem value={subscription.id} key={subscription.id}>
                        {subscription.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <FormControlLabel
                value={includeTrash}
                name="leadSold"
                onChange={onChangeTrash}
                control={<Switch checked={includeTrash} />}
                size="small"
                label="Include Trash"
              />

              <PopupState variant="popover" popupId="demo-popup-popover">
                {(popupState) => (
                  <div>
                    <Tooltip title="Filter list">
                      <IconButton
                        aria-label="filter list"
                        {...bindTrigger(popupState)}
                      >
                        <FilterListIcon />
                      </IconButton>
                    </Tooltip>

                    <Popover
                      {...bindPopover(popupState)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <Box p={2}>
                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                        >
                          <FormLabel component="legend">Show Columns</FormLabel>
                          <FormGroup>
                            {headCells.map((headCell) => {
                              return (
                                <FormControlLabel
                                  key={headCell.id}
                                  control={
                                    <Checkbox
                                      checked={headCell.selected}
                                      onChange={onChangeFilterHead}
                                      name={headCell.id}
                                    />
                                  }
                                  label={headCell.label}
                                />
                              );
                            })}
                          </FormGroup>
                        </FormControl>
                      </Box>
                    </Popover>
                  </div>
                )}
              </PopupState>
            </>
          )}
        </div>
      )}
    </Toolbar>
  );
};

export default EnhancedTableToolbar;

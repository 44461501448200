import React from "react";

const TooManyRequest = () => {

    return (
        <div>
            <h1>Too many request</h1>
            <h2>you are blocked for 15 minutes</h2>
        </div>
    )
}

export default TooManyRequest;

import React, { useState, useEffect, useRef } from "react";
import Axios from "axios";
import { makeStyles } from "@material-ui/styles";
import styled from "styled-components";
import {
  CircularProgress,
  Backdrop,
  Grid,
  Paper,
  Button,
} from "@material-ui/core";
import ToolbarLeadPerMonth from "./components/ToolbarLeadPerMonth";
import { SearchOutlined } from "@ant-design/icons";
import { Divider, Input, Space, Table } from "antd";
import Highlighter from "react-highlight-words";
import { CSVLink } from "react-csv";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: "#fff",
  },
}));
const BuyerLeadsPerMonth = (props) => {
  const { history } = props;
  const [buyers, setBuyers] = useState([]);
  const [loadNotification, setLoadNotification] = useState(
    "Get buyers with leads sent per month"
  );
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  useEffect(() => {
    getBuyerLeadsPerMonth();
  }, []);

  const getBuyerLeadsPerMonth = () => {
    setLoading(true);
    Axios.get(`${process.env.REACT_APP_BACKEND_API}/getleadspermonth`)
      .then((response) => {
        setBuyers(response.data.buyers);
        setLoading(false);
      })
      .catch(function(error) {
        console.log(error, "ERROR");
        if(error.response.status === 429){
          history.push("too-many-request");
        }
      });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Buyer",
      dataIndex: "buyer",
      key: "buyer",
      ...getColumnSearchProps("buyer"),
      sorter: (a, b) => a.buyer.localeCompare(b.buyer),
    },
    {
      title: "January",
      dataIndex: "January",
      key: "January",
      sorter: (a, b) => a.January - b.January,
    },
    {
      title: "February",
      dataIndex: "February",
      key: "February",
    },
    {
      title: "March",
      dataIndex: "March",
      key: "March",
    },
    {
      title: "April",
      dataIndex: "April",
      key: "April",
    },
    {
      title: "May",
      dataIndex: "May",
      key: "May",
    },
    {
      title: "June",
      dataIndex: "June",
      key: "June",
    },
    {
      title: "July",
      dataIndex: "July",
      key: "July",
    },
    {
      title: "August",
      dataIndex: "August",
      key: "August",
    },
    {
      title: "September",
      dataIndex: "September",
      key: "September",
    },
    {
      title: "October",
      dataIndex: "October",
      key: "October",
    },
    {
      title: "November",
      dataIndex: "November",
      key: "November",
    },
    {
      title: "December",
      dataIndex: "December",
      key: "December",
    },
  ];
  const csvHeaders = [
    { label: "Buyer", key: "buyer" },
    { label: "January", key: "January" },
    { label: "February", key: "February" },
    { label: "March", key: "March" },
    { label: "April", key: "April" },
    { label: "May", key: "May" },
    { label: "June", key: "June" },
    { label: "July", key: "July" },
    { label: "August", key: "August" },
    { label: "September", key: "September" },
    { label: "October", key: "October" },
    { label: "November", key: "November" },
    { label: "December", key: "December" },
  ];

  const csvData = buyers.map((buyer) => ({
    buyer: buyer.buyer,
    January: buyer.January,
    February: buyer.February,
    March: buyer.March,
    April: buyer.April,
    May: buyer.May,
    June: buyer.June,
    July: buyer.July,
    August: buyer.August,
    September: buyer.September,
    October: buyer.October,
    November: buyer.November,
    December: buyer.December,
  }));

  return (
    <Container>
      <Title>Leads sent per month</Title>

      {/*<ToolbarLeadPerMonth buyers={buyers} />*/}


      <Table dataSource={buyers} columns={columns} />
      <Divider />

      <Button color="primary" variant="outlined" className={classes.button}>
        <CSVLink
          data={csvData}
          headers={csvHeaders}
          filename="leads_per_month.csv"
        >
          Download CSV
        </CSVLink>
      </Button>
      <Backdrop className={classes.backdrop} open={loading}>
        {loadNotification} <CircularProgress color="primary" />
      </Backdrop>
    </Container>
  );
};

export default BuyerLeadsPerMonth;

const Container = styled.div`
  padding: 50px;
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 20px;
  margin-bottom: 20px;
`;

import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Divider, Drawer } from "@material-ui/core";
import PeopleIcon from "@material-ui/icons/People";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import BugReportIcon from "@material-ui/icons/BugReport";
import TabIcon from "@material-ui/icons/Tab";
import DnsIcon from "@material-ui/icons/Dns";
import SendIcon from "@material-ui/icons/Send";

import { Profile, SidebarNav } from "./components";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 160,
    [theme.breakpoints.up("lg")]: {
      marginTop: 64,
      height: "calc(100% - 64px)",
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}));

const Sidebar = (props) => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const token = localStorage.getItem("token");
  let accessToken = null;
  let role = null;
  if (token) {
    accessToken = JSON.parse(token).accessToken;
    role = JSON.parse(token).roles[0];
  }

  let pages = [];

  if (accessToken && role === "ROLE_ADMIN") {
    pages = [
      {
        title: "Leads",
        href: "/leads",
        icon: <ShoppingBasketIcon />,
      },
      {
        title: "Buyer",
        href: "/buyers",
        icon: <PeopleIcon />,
      },
      {
        title: "Lead Sent",
        href: "/leadSent",
        icon: <SendIcon />,
      },
      {
        title: "Subscription",
        href: "/subscriptions",
        icon: <DnsIcon />,
      },
      {
        title: "Calculator",
        href: "/calculator",
        icon: <TabIcon />,
      },
      {
        title: "logs",
        href: "/logs",
        icon: <BugReportIcon />,
      },
      {
        title: "Account",
        href: "/account",
        icon: <AccountBoxIcon />,
      },
      {
        title: "Settings",
        href: "/settings",
        icon: <SettingsIcon />,
      },
      {
        title: "Log Out",
        href: "/logout",
        icon: <ExitToAppIcon />,
      },
    ];
  } else if (accessToken && role === "ROLE_USER") {
    pages = [
      {
        title: "Buyer",
        href: "/buyers",
        icon: <PeopleIcon />,
      },
      {
        title: "Calculator",
        href: "/calculator",
        icon: <TabIcon />,
      },
      {
        title: "logs",
        href: "/logs",
        icon: <BugReportIcon />,
      },
      {
        title: "Account",
        href: "/account",
        icon: <AccountBoxIcon />,
      },
      {
        title: "Settings",
        href: "/settings",
        icon: <SettingsIcon />,
      },
      {
        title: "Log Out",
        href: "/logout",
        icon: <ExitToAppIcon />,
      },
    ];
  } else if (accessToken && role === "ROLE_BUYER") {
    pages = [
      {
        title: "Dashboard",
        href: "/buyer-dashboard",
        icon: <ShoppingBasketIcon />,
      },
      {
        title: "Account",
        href: "/account",
        icon: <AccountBoxIcon />,
      },
      {
        title: "Settings",
        href: "/settings",
        icon: <SettingsIcon />,
      },
      {
        title: "Log Out",
        href: "/logout",
        icon: <ExitToAppIcon />,
      },
    ];
  }

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages} />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;

export const getTimeZone = (state) => {
  switch (state) {
    case "AL":
    case "FL":
    case "GA":
    case "IN":
    case "KY":
    case "MI":
    case "MS":
    case "NC":
    case "OH":
    case "SC":
    case "TN":
    case "VT":
    case "VA":
    case "WV":
    case "CT":
    case "DE":
    case "DC":
    case "ME":
    case "MD":
    case "MA":
    case "NH":
    case "NJ":
    case "NY":
    case "PA":
    case "RI":
      // return "EST";
      return -4;
    case "AR":
    case "IL":
    case "IA":
    case "LA":
    case "MN":
    case "MO":
    case "NE":
    case "OK":
    case "TX":
      // return "CST";
      return -5;
    case "CO":
    case "KS":
    case "MT":
    case "NE":
    case "NM":
    case "WI":
    case "ND":
    case "SD":
    case "WY":
    case "AZ":
    case "UT":
      // return "MST";
      return -6;
    case "CA":
    case "NV":
    case "ID":
    case "WA":
    case "OR":
      // return "PST";
      return -7;
    case "AK":
      // return "AKST";
      return -9;
    case "GU":
    case "HI":
      // return "HST";
      return -10;
    case "MP":
      // return "ChST";
      return -10;
    case "AS":
      // return "SST";
      return -11;
    default:
      return "Unknown time zone";
  }
};

import axios from "axios";
import { apiUrl, config } from "queries/config";

export const getleadSent = async ({ accessToken }) => {
  const url = `${apiUrl}/leadSent`;

  config.headers["x-access-token"] = accessToken;

  const { data } = await axios.get(url, config);

  return data.leads;
};

export const patchLeadSent = async ({ accessToken, patchData, id }) => {
  console.log(patchData);
  const url = `${apiUrl}/leadSent/${id}`;

  config.headers["x-access-token"] = accessToken;

  const body = patchData;
  console.log(body);

  const { data } = await axios.patch(url, body);

  return data.subscriptions;
};

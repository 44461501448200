import styled from "styled-components";
import React, { useEffect } from "react";
import buyerService from "services/buyer.services";


const TableReport = () => {

  const token = localStorage.getItem("token");
  const accessToken = JSON.parse(token);

  useEffect(() => {
    buyerService.getAllBuyers({ accessToken }).then((res) => {
      console.log(res.data);
    });
  }, []);


  return (
    <Container>
      <Title>Table Report</Title>
    </Container>
  );
};

export default TableReport;

const Container = styled.div`
  padding: 50px;
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 20px;
  margin-bottom: 20px;
`;

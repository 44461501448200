/***
 * Order leads alphabetically or depending 
 * on the type of data different order 
 * dinamic for data of same type.
*/

const orderLeads = async (property) => {
    
    // Order by name of leads
    const order = localStorage.getItem('leadorder');
    const leads = JSON.parse(localStorage.getItem('leads'));
    if (property === 'name') {
        if (order === 'asc') {
            function SortArray(x, y) {
                return x.name.localeCompare(y.name);
            }
            var leadsOrder = leads.sort(SortArray);
            await localStorage.setItem('leads', JSON.stringify(leadsOrder));
        } else if (order === 'desc') {
            function SortArray(x, y) {
                return y.name.localeCompare(x.name);
            }
            var leadsOrder = leads.sort(SortArray);
            await localStorage.setItem('leads', JSON.stringify(leadsOrder));
        }
    }
    // Order by phone 
    if (property === 'phone') {
        if (order === 'asc') {
            function SortArray(x, y) {
                return x.phone.localeCompare(y.phone);
            }
            var leadsOrder = leads.sort(SortArray);
            await localStorage.setItem('leads', JSON.stringify(leadsOrder));
        } else if (order === 'desc') {
            function SortArray(x, y) {
                return y.phone.localeCompare(x.phone);
            }
            var leadsOrder = leads.sort(SortArray);
            await localStorage.setItem('leads', JSON.stringify(leadsOrder));
        }

    }
    // Order by email leads
    if (property === 'email') {
        if (order === 'asc') {
            function SortArray(x, y) {
                return x.email.localeCompare(y.email);
            }
            var leadsOrder = leads.sort(SortArray);
            await localStorage.setItem('leads', JSON.stringify(leadsOrder));
        } else if (order === 'desc') {
            function SortArray(x, y) {
                return y.email.localeCompare(x.email);
            }
            var leadsOrder = leads.sort(SortArray);
            await localStorage.setItem('leads', JSON.stringify(leadsOrder));
        }
    }
    // Order by Address
    if (property === 'address') {
        if (order === 'asc') {
            function SortArray(x, y) {
                return x.address.localeCompare(y.address);
            }
            var leadsOrder = leads.sort(SortArray);
            await localStorage.setItem('leads', JSON.stringify(leadsOrder));
        } else if (order === 'desc') {
            function SortArray(x, y) {
                return y.address.localeCompare(x.address);
            }
            var leadsOrder = leads.sort(SortArray);
            await localStorage.setItem('leads', JSON.stringify(leadsOrder));
        }
    }
    // Order by city
    if (property === 'city') {
        if (order === 'asc') {
            function SortArray(x, y) {
                return x.city.localeCompare(y.city);
            }
            var leadsOrder = leads.sort(SortArray);
            await localStorage.setItem('leads', JSON.stringify(leadsOrder));
        } else if (order === 'desc') {
            function SortArray(x, y) {
                return y.city.localeCompare(x.city);
            }
            var leadsOrder = leads.sort(SortArray);
            await localStorage.setItem('leads', JSON.stringify(leadsOrder));
        }
    }
    // Order by state
    if (property === 'state') {
        if (order === 'asc') {
            function SortArray(x, y) {
                return x.state.localeCompare(y.state);
            }
            var leadsOrder = leads.sort(SortArray);
            await localStorage.setItem('leads', JSON.stringify(leadsOrder));
        } else if (order === 'desc') {
            function SortArray(x, y) {
                return y.state.localeCompare(x.state);
            }
            var leadsOrder = leads.sort(SortArray);
            await localStorage.setItem('leads', JSON.stringify(leadsOrder));
        }
    }
    // Order by Zipcode
    if (property === 'zipcode') {
        if (order === 'asc') {
            function SortArray(x, y) {
                return x.zipcode.localeCompare(y.zipcode);
            }
            var leadsOrder = leads.sort(SortArray);
            await localStorage.setItem('leads', JSON.stringify(leadsOrder));
        } else if (order === 'desc') {
            function SortArray(x, y) {
                return y.zipcode.localeCompare(x.zipcode);
            }
            var leadsOrder = leads.sort(SortArray);
            await localStorage.setItem('leads', JSON.stringify(leadsOrder));
        }
    }
    // Order by county
    if (property === 'county') {
        if (order === 'asc') {
            function SortArray(x, y) {
                return x.county.localeCompare(y.county);
            }
            var leadsOrder = leads.sort(SortArray);
            await localStorage.setItem('leads', JSON.stringify(leadsOrder));
        } else if (order === 'desc') {
            function SortArray(x, y) {
                return y.county.localeCompare(x.county);
            }
            var leadsOrder = leads.sort(SortArray);
            await localStorage.setItem('leads', JSON.stringify(leadsOrder));
        }
    }
    // Order by private notes
    if (property === 'privateNotes') {
        if (order === 'asc') {
            function SortArray(x, y) {
                return x.privateNotes.localeCompare(y.privateNotes);
            }
            var leadsOrder = leads.sort(SortArray);
            await localStorage.setItem('leads', JSON.stringify(leadsOrder));
        } else if (order === 'desc') {
            function SortArray(x, y) {
                return y.privateNotes.localeCompare(x.privateNotes);
            }
            var leadsOrder = leads.sort(SortArray);
            await localStorage.setItem('leads', JSON.stringify(leadsOrder));
        }
    }
    
    
    if (property === 'buyers') {
        function SortArray(x, y) {
             
                if (order === 'asc') {
                    if (!x.buyerMatched) {
                        return x.buyers[0].name.localeCompare(y.buyers[0].name);
                    } else {

                        return x.buyerMatched[0].name.localeCompare(y.buyerMatched[0].name);
                    }
                } else if (order === 'desc') {

                    
                    if (!x.buyerMatched) {
                        return y.buyers[0].name.localeCompare(x.buyers[0].name);
                    } else {

                        return y.buyerMatched[0].name.localeCompare(x.buyerMatched[0].name);
                    }
                }
            
        }
        var leadsOrder = leads.sort(SortArray);
    
        await localStorage.setItem('leads', JSON.stringify(leadsOrder));
    }

}

export default orderLeads;
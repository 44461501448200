import axios from "axios";
import { apiUrl, config } from "queries/config";

export const patchCounties = async ({ accessToken, postCountiesData }) => {
  const url = `${apiUrl}/update-counties`;

  config.headers["x-access-token"] = accessToken;

  const body = postCountiesData;

  const { data } = await axios.patch(url, body);

  return data.subscriptions;
};

export const getBuyerById = async ({ accessToken, id }) => {
  const url = `${apiUrl}/buyer/${id}`;

  config.headers["x-access-token"] = accessToken;

  const { data } = await axios.get(url, config);

  return data;
};

export const getBuyers = async ({
  accessToken,
  searchWord,
  filterSubscription,
  includeHold,
  page,
  rowsPerPage,
  history
}) => {
  const url = `${apiUrl}/buyersP?filterSubscription=${filterSubscription}
        &includeHold=${includeHold}&order=desc&orderBy=leadsRemained&offset=${page *
    rowsPerPage}&limit=${rowsPerPage}`;

  config.headers["x-access-token"] = accessToken;

  const { data, error } = await axios.get(url, config);
  
  if(error && error.response.status === 429){
    history.push("too-many-request");
  }

  return data;
};

export const getCountiesWithBuyers = async ( accessToken ) => {
  const url = `${apiUrl}/buyerwithcounties`;

  config.headers["x-access-token"] = accessToken;
  const { data } = await axios.get(url, config);

  return data;
};

// export const getBuyers = async ({ accessToken }) => {
//   const url = `${apiUrl}/buyersP?includeHold=${false}&limit=100`;

//   config.headers["x-access-token"] = accessToken;

//   const { data } = await axios.get(url, config);

//   return data;
// };

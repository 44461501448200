import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
	TextField,

	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,

	useTheme,
	useMediaQuery
} from '@material-ui/core';



const useStyles = makeStyles(() => ({
	root: {
		marginTop: 10,
		padding: 10
	},
	input: {
		marginRight: 20,
		flex: 1
	},
	iconButton: {
		padding: 10
	},
	centerGrid: {
		display: 'flex',
		alignItems: 'center'
	}
}));


const CalculatorForm = props => {

	const { className, ...rest } = props;
	const classes = useStyles();

	const theme = useTheme();
	const showSpaces = useMediaQuery(theme.breakpoints.up('md'));

	const [formState, setFormState] = useState({
		isValid: false,
		values: {
			zillow: 253000.0,//0,
			realtor: 0,
			redfin: 0,
			repairs: 0,
			mortgage: 52000.0,
			catchup: 0,
			needs: 0,
			rent: 0,

			//factors

			/*state: 'AL',
			type: 0,
			leadReturned: false,
			leadSold: false,
			leadValidated: false,
			verify: 0*/
		},
		//touched: {},
		//errors: {}
	});

	const [resultState, setResultState] = useState({
		arv: 0,
		factor_achor: 0.0,
		factor_low: 0.0,
		factor_mid: 0.0,
		factor_high: 0.0,
		factor_approval: 0.0,
		make_offer: false,

		sub2_offer_amount: 0.0,
		sub2_max_offer_amount: 0.0,
		cash_at_closing: 0.0,

		monthly_payment1_a: 0,
		monthly_payment1_b: 0,

		some_now_low: '',
		some_now_mid: '',
		some_now_high: '',

		cash_at_closing2_low: 0,

		purchase_price: 0,
		monthly_payment_will: 0,
		purchase_price_will: 0,

		//constants
		SUB2_MATH: 0.8, //B56
		MONTHS: 120, // B45
		//Some Now Some Later High
		SOME_NOW_LATER: 0, //C64 calculated
		SOME_NOW_HIGH: 0.9, //D68
	});

	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	});

	useEffect(() => {
		calculateValues();
	}, [formState]);

	const handleChange = event => {

		event.persist();

		//// console.log(formState);
		setFormState(formState => ({
			...formState,
			values: {
				...formState.values,
				[event.target.name]: parseFloat(event.target.value.replace(',', ''))
				/*, [event.target.name]:
				  event.target.type === 'checkbox'
					? event.target.checked
					: event.target.value*/
			}/*,
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }*/
		}));
	};

	const average = (array) => array.reduce((a, b) => a + b) / array.length;

	const calculateAverage = () => {

		let vals = [];
		//formState.values.zillow = formState.values.zillow);
		//formState.values.realtor = formState.values.realtor);
		//formState.values.redfin = parseFloat(formState.values.redfin);

		if (formState.values.zillow > 0.0) {
			vals.push(formState.values.zillow);
		}
		if (formState.values.realtor > 0.0) {
			vals.push(formState.values.realtor);
		}
		if (formState.values.redfin > 0.0) {
			vals.push(formState.values.redfin);
		}

		//// console.log(vals);  		

		if (vals.length > 0) {
			//// console.log(vals);
			//// console.log(average(vals));
			return average(vals);//formState.values.arv = average(vals);
		}
		else
			return 0;
		//formState.values.arv = 0;

	}

	const mround = (number, roundto) => {
		return roundto * Math.round(number / roundto);
	}

	const calculateValues = () => {
		let t_arv = calculateAverage();
		//let t_factor_mid = t_arv<100000?0.525:0.68
		let t_factor_high = t_arv < 100000 ? 0.55 : 0.7
		let t_factor_low = t_arv < 100000 ? 0.5 : 0.65;
		let t_factor_approval = t_arv < 100000 ? 0.6 : 0.725;

		let l56 = ((t_arv - formState.values.repairs) * resultState.SUB2_MATH);
		let t_cash_at_closing = (l56 - formState.values.mortgage) > 10000 ? 10000 : 0;
		let l64 = (formState.values.mortgage < 5000 ? t_arv * 0.1 : 0);

		let t_cash_at_closing2_low = ((l64) > 20000) ? (20000 * (l64 > 1000 ? 1 : 0)) : (l64 * (l64 > 1000 ? 1 : 0));
		let t_purchase_price = ((formState.values.needs > t_arv * 0.9) ? (formState.values.needs) : (t_arv * 0.9));
		resultState.SOME_NOW_LATER = (l64 > 1000 ? 1 : 0);


		setResultState(resultState => ({
			...resultState,

			arv: t_arv,
			factor_achor: t_arv < 100000 ? 0.4 : 0.5,
			factor_low: t_factor_low,
			factor_mid: t_arv < 100000 ? 0.525 : 0.68,
			factor_high: t_factor_high,
			factor_approval: t_factor_approval,
			make_offer: formState.values.mortgage < (t_arv * t_factor_high - formState.values.repairs),

			//=IF($L$56>$M$56,$F$49,$M$56) => 
			//  $L$56 = (arv - repair)*SUB2_MATH
			//  $M$56 = mortgage
			//  $F$49 = resultState.arv*resultState.factor_low  - formState.values.repairs

			sub2_offer_amount: (l56 > formState.values.mortgage) ? (t_arv * t_factor_low - formState.values.repairs) : (formState.values.mortgage),

			//=IF($L$56>$M$56,$L$56,$M$56)

			sub2_max_offer_amount: ((l56 > formState.values.mortgage) ? (l56) : (formState.values.mortgage)),
			//=IF(($L$56-$M$56)>10000,10000,0)
			cash_at_closing: t_cash_at_closing,
			monthly_payment1_a: 0,


			//=IF(I82*I83>500,I83*I82,500*I82)
			//I82 => =IF(H62>2500,1,0)   , H62 = cash_at_closing
			//I83 => =IF($L56>$M56,I81,0)
			//  $L56 = (arv - repair)*SUB2_MATH
			//  $M56 = mortgage
			//I81 => =(L56-M56-N56)/B45
			//  N56 = (L56-M56)*0.1
			//  B45 = Months for Monthly Payment

			monthly_payment1_b: (t_cash_at_closing > 2500 ? 1 : 0) * ((l56 > formState.values.mortgage) ? (/*i81*/  (l56 - formState.values.mortgage - (l56 - formState.values.mortgage) * 0.1) / resultState.MONTHS) : 0),

			//Some Now Some Later
			//F66 =IF($B$41>1000,"Doesn't Work",N66) 
			//  B41 = mortgage
			//  N66 = F52 = ($B$39*$B52)-$B$40 = (arv*$B52)-$B$40
			//  B52 = if(B39<100000,0.6,0.725) => if(arv<100000,0.6,0.725)
			//  B40 = repairs

			some_now_low: (formState.values.mortgage > 1000) ? ("Doesn't Work") : formatter.format(t_arv * (t_arv < 100000 ? 0.6 : 0.725) - formState.values.mortgage),

			//Some Now Some Later Mid
			//F67 = IF($B$41>1000,"Doesn't Work",N67)
			//N67 = L56 

			some_now_mid: (formState.values.mortgage > 1000 ? "Doesn't Work" : formatter.format(l56)),

			//Some Now Some Later High
			//F68 = IF($B$41>1000,"Doesn't Work",N68)
			//  B41 = mortgage
			//  N68 = IF(B43<B39*D68,B43,B39*D68)
			//    B43 = needs = formState.values.needs
			//    B39 = arv
			//    D68 = SOME_NOW_HIGH

			some_now_high: (formState.values.mortgage > 1000 ? "Doesn't Work" : formatter.format((formState.values.needs < (t_arv * resultState.SOME_NOW_HIGH)) ? (formState.values.needs) : (t_arv * resultState.SOME_NOW_HIGH))),

			//Cash At Closing Low 2
			//H66 =IF($L$64>20000,20000*$C$64,$L$64*$C$64)
			//  L64 = IF(B41<5000,B39*0.1,0) = IF( mortgage < 5000, t_arv*0.1,0)
			//  C64 = Some Now Some Later = IF(L64>1000,1,0)

			cash_at_closing2_low: t_cash_at_closing2_low,
			cash_at_closing2_mid: 0, // same as low
			cash_at_closing2_high: 0, // same as low

			//Monthly Payment 2
			//I66 = MROUND(L66,50) 
			//  L66 = (N66-H66)/$B$45
			//    N66 = F52 = ($B$39*$B52)-$B$40 = (t_arv*t_factor_approval)-repairs
			//    H66 = t_cash_at_closing2_low
			//    B45 = MONTHS

			monthly_payment2_a: mround((((t_arv * t_factor_approval) - formState.values.repairs) - (t_cash_at_closing2_low)) / resultState.MONTHS, 50),

			//Monthly Payment 2 mid
			//I66 = MROUND(L67,50) 
			//  L67 = (N67-H67)/$B$45
			//    N67 = L56 = ($B$39-$B$40)*B56 = (t_arv-repairs)*SUB2_MATH
			//    H67 = t_cash_at_closing2_low
			//    B45 = MONTHS

			monthly_payment2_b: mround(((t_arv - formState.values.repairs) * resultState.SUB2_MATH - t_cash_at_closing2_low) / resultState.MONTHS, 50),

			//Monthly Payment 2 high
			//I68 = MROUND(L68,50)
			//  L68 = (N68-H68)/$B$45
			//    N68 = IF(B43<B39*D68,B43,B39*D68) = IF(needs <  arv * SOME_NOW_HIGH,needs,arv*SOME_NOW_HIGH) 
			//    H68 = IF($L$64>20000,20000*$C$64,$L$64*$C$64) => IF(l64>20000,20000*SOME_NOW_LATER,l64* SOME_NOW_LATER )
			//    B45 = MONTHS
			monthly_payment2_c: mround((((formState.values.needs < t_arv * resultState.SOME_NOW_HIGH) ? formState.values.needs : t_arv * resultState.SOME_NOW_HIGH) - (l64 > 20000 ? 20000 * resultState.SOME_NOW_LATER : l64 * resultState.SOME_NOW_LATER)) / resultState.MONTHS, 50),

			//purchase_price = IF(B43>(B39*0.9),B43,(B39*0.9))
			//    B43 = needs = formState.values.needs
			//    B39 = t_arv

			purchase_price: t_purchase_price,

			// monthly_payment_will = IF(B44<L74,B44,MROUND(L74,100))
			// B44 = rent
			// L74 = B73*0.0075 = purchase_price*0.0075
			monthly_payment_will: (formState.values.rent < t_purchase_price * 0.0075 ? formState.values.rent : mround(t_purchase_price * 0.0075, 100))

		}));
	}

	return (
		<>
			<Card {...rest} className={clsx(classes.root, className)}>
				<form
					autoComplete="off"
					noValidate>

					<CardHeader
						subheader="Please fill the required fields to calculate"
						title="Sell Me The House Offer Calculator"
					/>

					<Divider />

					<CardContent>
						<Grid container spacing={3}>

							<Grid item md={4} xs={12}>
								<TextField
									fullWidth
									label="Seller Name"
									margin="dense"
									name="name"
									required
									value={formState.values.name}
									variant="outlined"

								/>
							</Grid>

							{showSpaces && <Grid item md={8}> </Grid>}

							<Grid item md={4} xs={12}>

								<CurrencyTextField
									label="Zillow"
									variant="outlined"
									fullWidth
									currencySymbol="$"
									minimumValue="0"
									outputFormat="number"
									margin="dense"
									decimalCharacter="."
									digitGroupSeparator=","
									name="zillow"
									defaultValue={formState.values.zillow}
									//value={formState.values.zillow}
									onChange={handleChange}
								//onChange={(event, value)=> setValue(value)}
								/>

							</Grid>

							{/*showSpaces && <Grid item md={8}> </Grid>*/}

							<Grid item md={4} xs={12}>
								<CurrencyTextField
									label="Realtor"
									variant="outlined"
									fullWidth
									currencySymbol="$"
									minimumValue="0"
									outputFormat="number"
									margin="dense"
									decimalCharacter="."
									digitGroupSeparator=","
									name="realtor"
									//value={formState.values.realtor}
									//onChange={(event, value)=> setValue(value)}
									onChange={handleChange}
								/>

							</Grid>

							{/*showSpaces && <Grid item md={8}> </Grid>*/}

							<Grid item md={4} xs={12}>
								<CurrencyTextField
									label="RedFin"
									variant="outlined"
									fullWidth
									currencySymbol="$"
									minimumValue="0"
									outputFormat="number"
									margin="dense"
									decimalCharacter="."
									digitGroupSeparator=","
									name="redfin"
									//value={formState.values.redfin}
									onChange={handleChange}
								/>
							</Grid>

							{/*showSpaces && <Grid item md={8}> </Grid>*/}


							<Grid item md={4} xs={12}>
								<CurrencyTextField
									label="ARV"
									variant="outlined"
									fullWidth
									currencySymbol="$"
									//minimumValue="0"
									//outputFormat="number"
									margin="dense"
									decimalCharacter="."
									digitGroupSeparator=","
									value={resultState.arv}
									name="arv"
									readOnly={true}
								//onChange={handleChange}
								/>
							</Grid>

							<Grid item md={4} xs={12}>
								<CurrencyTextField
									label="Repairs"
									variant="outlined"
									fullWidth
									currencySymbol="$"
									minimumValue="0"
									outputFormat="number"
									margin="dense"
									decimalCharacter="."
									digitGroupSeparator=","
									//value={formState.values.repairs}
									//onChange={handleChange}
									name="repairs"
									onChange={handleChange}
								/>
							</Grid>
							<Grid item md={4} xs={12}>
								<CurrencyTextField
									label="Mortgage Amount"
									variant="outlined"
									fullWidth
									currencySymbol="$"
									minimumValue="0"
									outputFormat="number"
									margin="dense"
									decimalCharacter="."
									digitGroupSeparator=","
									defaultValue={formState.values.mortgage}
									name="mortgage"
									onChange={handleChange}
								/>
							</Grid>

							<Grid item md={4} xs={12}>
								<CurrencyTextField
									label="Mortgage Catchup/Liens"
									variant="outlined"
									fullWidth
									currencySymbol="$"
									minimumValue="0"
									outputFormat="number"
									margin="dense"
									decimalCharacter="."
									digitGroupSeparator=","
									name="catchup"
									//value={formState.values.catchup}
									onChange={handleChange}
								/>
							</Grid>

							<Grid item md={4} xs={12}>
								<CurrencyTextField
									label="Needs to have to make a deal"
									variant="outlined"
									fullWidth
									currencySymbol="$"
									minimumValue="0"
									outputFormat="number"
									margin="dense"
									decimalCharacter="."
									digitGroupSeparator=","
									//value={formState.values.needs}
									name="needs"
									onChange={handleChange}
								/>
							</Grid>

							<Grid item md={4} xs={12}>
								<CurrencyTextField
									label="Rent Amount"
									variant="outlined"
									fullWidth
									currencySymbol="$"
									minimumValue="0"
									outputFormat="number"
									margin="dense"
									decimalCharacter="."
									digitGroupSeparator=","
									//value={formState.values.rent}
									name="rent"
									onChange={handleChange}
								/>
							</Grid>


						</Grid>

					</CardContent>
				</form>
			</Card>


			<Card {...rest} className={clsx(classes.root, className)}>

				<TableContainer component={Paper}>
					<Table className={classes.table}>

						<TableHead>
							<TableRow>
								<TableCell> </TableCell>
								<TableCell align="right">Set The</TableCell>
								<TableCell align="right">Low</TableCell>
								<TableCell align="right">Mid</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>

							<TableRow >
								<TableCell component="th" scope="row">

									Cash Offer :

									{resultState.make_offer && <b>Make Offer</b>}
									{!resultState.make_offer && <b style={{ color: 'red' }}>Won't work</b>}

								</TableCell>
								<TableCell align="right"> {formatter.format(resultState.arv * resultState.factor_achor - formState.values.repairs)} </TableCell>
								<TableCell align="right"> {formatter.format(resultState.arv * resultState.factor_low - formState.values.repairs)} </TableCell>
								<TableCell align="right"> {formatter.format(resultState.arv * resultState.factor_mid - formState.values.repairs)} </TableCell>

							</TableRow>

						</TableBody>
					</Table>
				</TableContainer>
			</Card>

			<Card {...rest} className={clsx(classes.root, className)}>
				<TableContainer component={Paper}>
					<Table className={classes.table} >

						<TableHead>
							<TableRow>
								<TableCell> </TableCell>
								<TableCell align="right">Offer Amount </TableCell>
								<TableCell align="right">Sub2 High Offer</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>

							<TableRow >
								<TableCell component="th" scope="row">
									Sub2 Offer
								</TableCell>
								<TableCell align="right">

									{formState.values.mortgage > 25000 &&

										<span>
											{formatter.format(resultState.sub2_offer_amount)}
										</span>

									}
									{(!(formState.values.mortgage > 25000)) && <span>Doesn't Work"</span>}


								</TableCell>
								<TableCell align="right">
									{formState.values.mortgage > 25000 &&

										<span>
											{formatter.format(resultState.sub2_max_offer_amount)}
										</span>

									}
									{(!(formState.values.mortgage > 25000)) && <span>Doesn't Work"</span>}


								</TableCell>
							</TableRow>

							<TableRow >
								<TableCell component="th" scope="row">
									Sub2 Loan
								</TableCell>
								<TableCell align="right">
									{formatter.format(formState.values.mortgage)}
								</TableCell>
								<TableCell align="right">
									{formatter.format(formState.values.mortgage)}
								</TableCell>
							</TableRow>

							<TableRow >
								<TableCell component="th" scope="row">
									Cash at Closing
								</TableCell>
								<TableCell align="right"> {formatter.format(resultState.cash_at_closing)} </TableCell>
								<TableCell align="right"> {formatter.format(resultState.cash_at_closing)} </TableCell>
							</TableRow>

							<TableRow >
								<TableCell component="th" scope="row">
									Monthly Payment (if any) until paid
								</TableCell>
								<TableCell align="right"> (need review) {formatter.format(resultState.monthly_payment1_a)} </TableCell>
								<TableCell align="right"> {formatter.format(resultState.monthly_payment1_b)} </TableCell>
							</TableRow>

						</TableBody>
					</Table>
				</TableContainer>

			</Card>
			<Card {...rest} className={clsx(classes.root, className)}>
				<TableContainer component={Paper}>
					<Table className={classes.table} >

						<TableHead>
							<TableRow>
								<TableCell> </TableCell>
								<TableCell align="right">Low </TableCell>
								<TableCell align="right">Mid</TableCell>
								<TableCell align="right">Need</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>

							<TableRow >
								<TableCell component="th" scope="row">
									Some Now Some Later
								</TableCell>
								<TableCell align="right"> {resultState.some_now_low} </TableCell>
								<TableCell align="right"> {resultState.some_now_mid}  </TableCell>
								<TableCell align="right"> {resultState.some_now_high}  </TableCell>
							</TableRow>

							<TableRow >
								<TableCell component="th" scope="row">
									Cash At Closing
								</TableCell>
								<TableCell align="right"> {formatter.format(resultState.cash_at_closing2_low)} </TableCell>
								<TableCell align="right"> {formatter.format(resultState.cash_at_closing2_low)} </TableCell>
								<TableCell align="right"> {formatter.format(resultState.cash_at_closing2_low)} </TableCell>
							</TableRow>

							<TableRow >
								<TableCell component="th" scope="row">
									Monthly Payment
								</TableCell>
								<TableCell align="right"> {formatter.format(resultState.monthly_payment2_a)} </TableCell>
								<TableCell align="right"> {formatter.format(resultState.monthly_payment2_b)} </TableCell>
								<TableCell align="right"> {formatter.format(resultState.monthly_payment2_c)} </TableCell>
							</TableRow>

						</TableBody>
					</Table>
				</TableContainer>

			</Card>
			<Card {...rest} className={clsx(classes.root, className)}>
				<Grid container spacing={3}>
					<Grid item md={2} xs={12}>
						<CardHeader title="Lease purcharse" />

					</Grid>
					<Grid item md={2} xs={12}>
						<CardHeader title="Purcharse price" />
						<CardContent className="locations-container">
							<b>{formatter.format(resultState.purchase_price)}</b>

						</CardContent>
					</Grid>
					<Grid item md={8} xs={12}>
						<CardHeader title="Terms" />
						<CardContent className="locations-container">
							We will lease house for 12 months and buy the house before the end of the 12 months
							<br />
							Monthly Payment will be: {formatter.format(resultState.monthly_payment_will)}
							<br />
							<i>For one year, after one year we will pay the balance below in full</i>
							<br />
							Purchase Price will be:	{formatter.format(resultState.purchase_price)}
						</CardContent>
					</Grid>
				</Grid>
			</Card>
		</>
	)
}

export default CalculatorForm;
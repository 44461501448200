import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { getBuyersFromCounty, getDispute } from "common/utils";
import moment from "moment";
import { set } from "date-fns";

const useStyles = makeStyles((theme) => ({
  formControl: {
    //margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(14),
  },
  select: {
    paddingRight: 0,
  },
}));

export default function SelectBuyerModal({
  buyerMatched,
  patchData,
  setPatchData,
  getRemainCount,
  getBuyerSubscriptionPrefix,
  getDispute,
}) {
  const [open, setOpen] = useState(false);
  const [close, setClose] = useState(true);

  const classes = useStyles({
    select: {
      menuItem: {
        width: "100px",
      },
    },
  });

  const handleChange = async (e) => {
    const leadFilter = buyerMatched.filter(
      (buyer) => buyer.id === e.target.value
    );
    setPatchData({
      ...patchData,
      buyers: leadFilter,
    });
  };

  const onOpen = () => {
    setOpen(true);
    setClose(false);
  };

  const onClose = () => {
    setOpen(false);
    setClose(true);
  };

  console.log(open);
  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="input-select-buyer">Assign Buyer</InputLabel>
        <Select
          key={patchData.buyers[0].id}
          className={classes.select}
          labelId="select-buyer-label"
          id="select-buyer"
          inputProps={{
            id: "uncontrolled-native",
          }}
          defaultValue={patchData.buyers[0].id}
          onChange={handleChange}
          onOpen={onOpen}
          onClose={onClose}
        >
          {buyerMatched.map((buyer) => {
            return (
              <MenuItem
                key={buyer.id}
                className={classes.menuItem}
                value={buyer.id}
              >
                {open ? (
                  <span>
                    {buyer.name} {"    "}
                    {`(${getRemainCount(buyer.leads ? buyer.leads : [])})`}
                    {"  "}
                    {`(${getDispute(buyer.leads ? buyer.leads : [])})`}
                    {"  "}
                    {getBuyerSubscriptionPrefix(buyer)}
                    {"  "}
                  </span>
                ) : (
                  <span>{buyer.name}</span>
                )}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}

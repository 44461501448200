import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import React, { Component } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Chart } from "react-chartjs-2";
import { ThemeProvider } from "@material-ui/styles";
import validate from "validate.js";
import { NotificationContainer } from "react-notifications";
import { chartjs } from "./helpers";
import theme from "./theme";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./assets/scss/index.scss";
import "react-notifications/lib/notifications.css";
import "./App.css";
import validators from "./common/utils";
import Routes from "./Routes";
import AxiosConfig from "./AxiosConfig";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
export const history = createBrowserHistory();
const queryClient = new QueryClient();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw,
});

validate.validators = {
  ...validate.validators,
  ...validators,
};
// console.log(history);

export default class App extends Component {
  componentDidMount() {
    AxiosConfig.init();
  }
  render() {
    return (
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Router history={history}>
            <Routes />
          </Router>
          <NotificationContainer />
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>
      </ThemeProvider>
    );
  }
}

import React from "react";
import clsx from "clsx";
import { SearchInput } from "components";
import {
  Grid,
  Input,
  Typography,
  Toolbar,
  MenuItem,
  Switch,
  FormControlLabel,
  Select,
  InputLabel,
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
  Tooltip,
  IconButton,
  Box,
  Popover,
  Button,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { makeStyles } from "@material-ui/styles";
import { history } from "App";
const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: theme.palette.secondary.light,
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
  searchInput: {
    marginRight: theme.spacing(1),
    minWidth: "300px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  typography: {
    padding: theme.spacing(2),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  container: {
    maxHeight: 640,
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    searchWord,
    includeHold,
    onChangeHold,
    headCells,
    onChangeFilterHead,
    filterSubscription,
    onChangeFilterSubscription,
    setLoading,
    refetch,
    page,
    rowsPerPage,
    dataSubs,
    setIncludeHold,
    handleChangeSearch,
    search,
  } = props;

  const onClickFilter = async () => {
    setLoading(true);

    const info = await refetch({
      searchWord: searchWord,
      filterSubscription: filterSubscription,
      includeHold: includeHold,
      page: page,
      rowsPerPage: rowsPerPage,
    });
    if (info.status === "success") {
      setLoading(false);
    }
  };

  return (
    <Toolbar className={clsx(classes.root)}>
      <Grid
        spacing={1}
        container
        direction="row"
        alignItems="center"
        justify="space-between"
        sm={12}
        md={12}
      >
        <Grid item md={1} sm={4}>
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Buyers
          </Typography>
        </Grid>
        <Grid item md={3} sm={4}>
          <SearchInput
            className={classes.searchInput}
            placeholder="Search buyer"
            onChange={handleChangeSearch}
            value={search}
          />
        </Grid>
        <Grid item md={2} sm={4}>
          <FormControl
            size="small"
            variant="outlined"
            className={classes.formControl}
          >
            <InputLabel id="demo-simple-select-filled-label">
              Subscription
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={filterSubscription}
              onChange={onChangeFilterSubscription}
              input={<Input id="select-multiple-chip" />}
              label="Lead Subscription"
              // renderValue={(selected) => (
              //   <div className={classes.chips}>
              //     {selected.map((value) => {
              //       return (
              //         <Chip
              //           key={value}
              //           label={value.name}
              //           className={classes.chip}
              //         />
              //       );
              //     })}
              //   </div>
              // )}
              MenuProps={MenuProps}
              inputProps={{ "aria-label": "Without label" }}
            >
              {dataSubs?.map((subscription) => (
                <MenuItem
                  key={subscription.id}
                  value={subscription.id}
                  // style={getStyles(subscription, filterSubscription)}
                >
                  {subscription.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item md={2} sm={4}>
          <FormControlLabel
            value={includeHold}
            name="includeHold"
            onChange={onChangeHold}
            control={<Switch checked={includeHold} />}
            size="small"
            label="Hold"
            labelPlacement="right"
          />
        </Grid>
        <Grid
          md={2}
          sm={4}
          container
          direction="row"
          alignItems="center"
          justify="space-around"
        >
          <Grid item md={1} sm={4}>
            <Button
              style={{ height: "45px", lineHeight: "0" }}
              onClick={onClickFilter}
              color="primary"
              variant="contained"
            >
              Filter
            </Button>
          </Grid>
          <Grid item md={2} sm={4}>
            <Button
              style={{ height: "45px", lineHeight: "1" }}
              color="primary"
              variant="outlined"
              onClick={async () => {
                localStorage.setItem("buyerpage", 0);
                localStorage.setItem("includeHold", 0);
                setIncludeHold(false);

                history.push("/buyers");
              }}
            >
              Clear filter
            </Button>
          </Grid>
        </Grid>

        <Grid item md={1} sm={4}>
          <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => (
              <div>
                <Tooltip title="Filter list">
                  <IconButton
                    aria-label="filter list"
                    {...bindTrigger(popupState)}
                  >
                    <FilterListIcon />
                  </IconButton>
                </Tooltip>

                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <Box p={2}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormLabel component="legend">Show Columns</FormLabel>
                      <FormGroup>
                        {headCells.map((headCell) => {
                          return (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={headCell.selected}
                                  onChange={onChangeFilterHead}
                                  name={headCell.id}
                                />
                              }
                              label={headCell.label}
                            />
                          );
                        })}
                      </FormGroup>
                    </FormControl>
                  </Box>
                </Popover>
              </div>
            )}
          </PopupState>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export default EnhancedTableToolbar;

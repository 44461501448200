import axios from 'axios';
import { apiUrl, config } from 'queries/config';

export const getSubscription = async ({ accessToken }) => {
  const url = `${apiUrl}/subscription`;
  config.headers['x-access-token'] = accessToken;

  const { data } = await axios.get(url, config);

  return data.subscriptions;
};

export const postSubscription = async ({
  accessToken,
  postSubscriptionData
}) => {
  const url = `${apiUrl}/subscription`;

  config.headers['x-access-token'] = accessToken;

  const body = postSubscriptionData;

  const { data } = await axios.post(url, body);

  return data.subscriptions;
};

export const patchSubscription = async ({
  accessToken,
  postSubscriptionData,
  id
}) => {
  const url = `${apiUrl}/subscription/${id}`;

  config.headers['x-access-token'] = accessToken;

  const body = postSubscriptionData;

  const { data } = await axios.patch(url, body);

  return data.subscriptions;
};

export const deleteSubscription = async ({ accessToken, id }) => {
  const url = `${apiUrl}/subscription/${id}`;

  config.headers['x-access-token'] = accessToken;

  const { data } = await axios.delete(url);

  return data.subscriptions;
};

import React, { useState, useEffect } from "react";
import Axios from "axios";
import { NotificationManager } from "react-notifications";
import { makeStyles } from "@material-ui/styles";
import { LeadsToolbar, LeadsTable } from "./components";
import clone from "lodash.clonedeep";
import { CircularProgress, Backdrop, Grid, Paper } from "@material-ui/core";
import { getDispute, getLastLead, getRemainCount } from "common/utils";
import { history } from "App";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: "#fff",
  },
}));
let delayDebounceFn;

const LeadList = (props) => {
  const { history } = props;
  
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [leads, setLeads] = useState(JSON.parse(localStorage.getItem("leads")));
  const [buyers, setBuyers] = useState(
    JSON.parse(localStorage.getItem("buyers"))
  );
  const [count, setCount] = useState(localStorage.getItem("count"));
  const [stopApp, setStopApp] = useState(
    localStorage.getItem("stopApp") === "true" ? true : false
  );
  const [loadNotification, setLoadNotification] = useState("Get Leads");

  const token = localStorage.getItem("token");
  const role = JSON.parse(token).roles[0];

  const [filterSent, setFilterSent] = useState(
    role === "ROLE_ADMIN" ? "unsent" : "sent"
  );
  const [filterBilling, setFilterBilling] = useState(
    localStorage.getItem("filterBilling")
      ? localStorage.getItem("filterBilling")
      : "nobilling"
  );
  const [filterSubscription, setFilterSubscription] = useState(
    localStorage.getItem("leadfilterSubscription")
      ? Number(localStorage.getItem("leadfilterSubscription"))
      : -1
  );
  const [searchWord, setSearchWord] = useState(
    localStorage.getItem("searchWord") ? localStorage.getItem("searchWord") : ""
  );
  const [searchBuyer, setSearchBuyer] = useState(
    localStorage.getItem("searchBuyer")
      ? localStorage.getItem("searchBuyer")
      : ""
  );

  const [refreshKey, setRefreshKey] = useState("xxx");
  const [includeTrash, setIncludeTrash] = useState(
    localStorage.getItem("includeTrash")
      ? localStorage.getItem("includeTrash") === "1"
        ? true
        : false
      : false
  );

  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("rowsPerPage")
      ? localStorage.getItem("rowsPerPage")
      : 100
  );

  const [page, setPage] = useState(
    localStorage.getItem("leadpage") ? +localStorage.getItem("leadpage") : 0
  );

  const [order, setOrder] = useState(
    localStorage.getItem("leadorder")
      ? localStorage.getItem("leadorder")
      : "asc"
  );

  const [orderBy, setOrderBy] = useState(
    localStorage.getItem("leadorderby")
      ? localStorage.getItem("leadorderby")
      : "name"
  );

  useEffect(() => {
    (async () => {
      if(localStorage.getItem("leads") && localStorage.getItem("leads")){
        console.log('There are data');
      } else {
        console.log("Not data");
        if (delayDebounceFn) clearTimeout(delayDebounceFn);
        delayDebounceFn = setTimeout(async () => {

          if(filterSent === 'sent'){
            setLoadNotification('Get leads');
            await getLeadList();
          } else {
            await getLeadList();
            setLoadNotification('Get buyers');
            await getBuyers();
          }
          // Send Axios request here
        }, 1500);
      }
    })();
    return () => clearTimeout(delayDebounceFn);
  }, [
    refreshKey,
    filterSent,
    includeTrash,
    page,
    rowsPerPage,
    filterBilling,
    filterSubscription,
    searchWord,
    searchBuyer,
    leads
  ]);

  /////////////////////////////////////////

  const setRowsPerPage1 = React.useCallback(
    (e) => {
      localStorage.setItem("rowsPerPage", e);
      setRowsPerPage(e);
    },
    [rowsPerPage]
  );

  const setPage1 = React.useCallback(
    (e) => {
      if (e < 0) {
        e = 0;
      }
      localStorage.setItem("leadpage", e);
      setPage(e);
    },
    [page]
  );

  const setOrder1 = React.useCallback(
    (e) => {
      localStorage.setItem("leadorder", e);
      setOrder(e);
    },
    [order]
  );

  const setOrderBy1 = React.useCallback(
    (e) => {
      localStorage.setItem("leadorderby", e);
      setOrderBy(e);
    },
    [orderBy]
  );

  /////////////////
  const onChangeSearch = React.useCallback(
    (e) => {
      localStorage.setItem("searchWord", e.target.value);
      setSearchWord(e.target.value.toLowerCase());
      localStorage.setItem("leadpage", 0);
      setPage(0);
    },
    [searchWord]
  );

  const onChangeSearchBuyer = React.useCallback(
    (e) => {
      localStorage.setItem("searchBuyer", e.target.value);
      setSearchBuyer(e.target.value.toLowerCase());
      localStorage.setItem("leadpage", 0);
      setPage(0);
    },
    [searchBuyer]
  );

  const onChangeFilterSent = React.useCallback(
    (e) => {
      localStorage.setItem("filterSent", e.target.value);
      setFilterSent(e.target.value);
      localStorage.setItem("leadpage", 0);
      setPage(0);
      setLeads(null);
      setBuyers(null);
      localStorage.removeItem('leads');
      localStorage.removeItem('buyers');
    },
    [filterSent]
  );

  const onChangeFilterSubscription = React.useCallback(
    (e) => {
      localStorage.setItem("leadfilterSubscription", e.target.value);
      setFilterSubscription(e.target.value);

      localStorage.setItem("leadpage", 0);
      setPage(0);
    },
    [filterSubscription]
  );

  const onChangeFilterBilling = React.useCallback(
    (e) => {
      localStorage.setItem("filterBilling", e.target.value);
      setFilterBilling(e.target.value);

      localStorage.setItem("leadpage", 0);
      setPage(0);
    },
    [filterBilling]
  );

  const assignDataToBuyers = async (buyers) => {

    let leads = JSON.parse(localStorage.getItem('leads'));
    if(buyers){
      buyers.map(async (buyer) => {
        if (buyer.leads.length > 0) {
          let dispute = getDispute(buyer.leads);
          let remainCount = getRemainCount(buyer, buyer.leads);
          let lastLead = getLastLead(buyer.leads);
          buyer["dispute"] = dispute;
          buyer["remainCount"] = remainCount;
          buyer["lastLead"] = lastLead;
          delete buyer['leads'];
          if (leads) {
            leads.map((lead) => {
              lead.buyerMatched.map((buyerMatched) => {
                if (buyerMatched.id === buyer.id) {
                  buyerMatched["dispute"] = dispute;
                  buyerMatched["remainCount"] = remainCount;
                  buyerMatched["lastLead"] = lastLead;
                }
              });
            });
          }
        }   
      })
      
      localStorage.setItem('buyers', JSON.stringify(buyers));
      localStorage.setItem('leads', JSON.stringify(leads));
      setLeads(leads)
    } 
  }

  const getBuyers = async () => {
    setLoading(true);

    Axios.get(`${process.env.REACT_APP_BACKEND_API}/buyers`)
      .then(async (response) => {

        if (response.data.message === "sucess!") {
          //localStorage.setItem("buyers", JSON.stringify(response.data.buyers));
          setLoadNotification("Assigning data to buyers");
          await assignDataToBuyers(response.data.buyers);
          
          setLoading(false);
        } else {
          NotificationManager.error("failed get buyers list");
        }
      })
      .catch(function(error) {
        if(error.response.data.name && error.response.data.name === "TokenExpiredError"){
          NotificationManager.error(error.response.data.message);
          history.push("/logout");
        }
        setLoading(false);
      });
  };

  const getLeadList = async () => {
    setLoading(true);
  
    Axios.get(
      `${
        process.env.REACT_APP_BACKEND_API
      }/leadlist?filterBilling=${filterBilling}&filterSubscription=${filterSubscription}&searchBuyer=${searchBuyer}&searchWord=${searchWord}&filterSent=${filterSent}&includeTrash=${
        includeTrash ? "1" : "0"
      }&offset=${page * rowsPerPage}&limit=${rowsPerPage}`
    )
      .then(async (response) => {
        localStorage.setItem("leads", JSON.stringify(response.data.leads));
        localStorage.setItem("count", response.data.count);
        if (response.data.stopApp[0].stopApp === true) {
          setStopApp(true);
        } else if (response.data.stopApp[0].stopApp === false) {
          setStopApp(false);
        }
        localStorage.setItem("stopApp", response.data.stopApp[0].stopApp);
        await setLeads(response.data.leads);
        setCount(response.data.count);
      })
      .catch(function(error) {
        if(error.response.data.name && error.response.data.name === "TokenExpiredError"){
          NotificationManager.error(error.response.data.message);
          history.push("/logout");
        }
        setLoading(false);
        if(error.response.status === 429){
          history.push("too-many-request");
        }
      });
  };

  const onSendLeadToForm = (lead) => {
    let params = {
      id: lead.id,
    };

    if (lead) {
      Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/lead/sendLeadForm`,
        params
      )
        .then((response) => {
          const lead = response.data.lead;
          const data = response.data.data;

          if (data.hasOwnProperty("1")) {
            NotificationManager.success("Send Lead success");
            const index = leads.findIndex((item) => item.id === lead.id);

            // Replace the item by index.
            leads.splice(index, 1, lead);

            setLeads(leads);
            setRefreshKey(
              Math.random()
                .toString(36)
                .substring(2, 15)
            );
          } else {
            NotificationManager.error(data["0"]);
            const index = leads.findIndex((item) => item.id === lead.id);

            // Replace the item by index.
            leads.splice(index, 1, lead);

            setLeads(leads);
            setRefreshKey(
              Math.random()
                .toString(36)
                .substring(2, 15)
            );
          }
        })
        .catch(function(error) {
          history.push("/logout");
          NotificationManager.error("failed send lead");
          if(error.response.status === 429){
            history.push("too-many-request");
          }
        });
    }
  };

  const onSendLead = (id, dateSent) => {
    let params;

    if (dateSent) {
      params = {
        id,
        dateSent,
      };
    } else {
      params = {
        id,
      };
    }

    if (id !== -1) {
      Axios.post(`${process.env.REACT_APP_BACKEND_API}/lead/sendLead`, params)
        .then(async (response) => {
          NotificationManager.success("Send Lead success");
          const lead = response.data.lead;
          var index = leads.findIndex((item) => item.id === lead.id);
          var newLead = clone(leads);
          // Replace the item by index.
          if (filterSent === "all" || filterSent === "sent") {
            // console.log('replace');
            newLead.splice(index, 1, lead);
          } else {
            // console.log('remove', index);
            newLead.splice(index, 1);
          }

          // console.log(newLead);
          setLeads(newLead);
          // getBuyerList();
          await handleUpdateLeadsLocal(index);
        })
        .catch(function(error) {
          // console.log(error);
          history.push("/logout");
          NotificationManager.error("failed send lead");
          if(error.response.status === 429){
            history.push("too-many-request");
          }
        });
    }
  };

  /**
   * Update the ocal storage with the index
   * of lead, after update the array of leads
   */

  const handleUpdateLeadsLocal = async (index) => {
    await leads.splice(index, 1);
    localStorage.setItem("leads", JSON.stringify(leads));
  };

  const onChangeBuyer = (id, buyerId, dateSent) => {
    const params = {
      id,
      buyerId,
    };

    if (id !== -1) {
      Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/lead/assignBuyer`,
        params
      )
        .then((response) => {
          if ((response.status = 200)) {
            // const lead = response.data.lead;

            // var newLead = clone(leads);

            // var index = newLead.findIndex(item => item.id === lead.id);

            // Replace the item by index.
            // newLead.splice(index, 1, lead);
            //setLeads(newLead);

            onSendLead(params.id, dateSent);
          } else {
            NotificationManager.error("failed assign buyer");
          }
        })
        .then((res) => {
          // console.log(res);
        })
        .catch(function(error) {
          NotificationManager.error("Assign buyer failed");
          if(error.response.status === 429){
            history.push("too-many-request");
          }
        });
    }
  };

  const onChangeTrash = React.useCallback(() => {
    localStorage.setItem("includeTrash", includeTrash ? "0" : "1");
    setIncludeTrash(!includeTrash);
    localStorage.setItem("leadpage", 0);
    setPage(0);
  }, [includeTrash]);

  return (
    <div className={classes.root}>
      <LeadsToolbar stopApp={stopApp} setStopApp={setStopApp} setLeads={setLeads} role={role} />
      <div className={classes.content}>
        <LeadsTable
          role={role}
          setLeads={setLeads}
          searchWord={searchWord}
          searchBuyer={searchBuyer}
          leads={leads}
          count={count}
          onChangeSearch={onChangeSearch}
          onChangeSearchBuyer={onChangeSearchBuyer}
          onChangeFilterSent={onChangeFilterSent}
          filterSent={filterSent}
          onChangeFilterBilling={onChangeFilterBilling}
          filterBilling={filterBilling}
          onSendLead={onSendLead}
          onSendLeadToForm={onSendLeadToForm}
          isBuyer={false}
          onChangeBuyer={onChangeBuyer}
          onChangeTrash={onChangeTrash}
          includeTrash={includeTrash}
          filterSubscription={filterSubscription}
          onChangeFilterSubscription={onChangeFilterSubscription}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage1}
          setRowsPerPage={setRowsPerPage1}
          order={order}
          setOrder={setOrder1}
          orderBy={orderBy}
          setOrderBy={setOrderBy1}
        />
      </div>
      <Backdrop className={classes.backdrop} open={!leads || loading}>
        {loadNotification} <CircularProgress color="primary" />
      </Backdrop>
    </div>
  );
};

export default LeadList;

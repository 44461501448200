import React, { useState } from "react";
import { Button, Modal } from "@material-ui/core";
import { Input } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "15px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "400px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
    flexDirection: "column",
  },

  button: {
    marginTop: "10px",
    width: "150px",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    margin: theme.spacing(1),
    minWidth: 100,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 120,
  },
}));
const ModalChangePass = (props) => {
  const classes = useStyles();
  const {
    isModalOpenPass,
    handleCancelPass,
    changePasswordHandler,
    handleChange,
    formState,
  } = props;
  return (
    <>
      <Modal zIndex={999999} open={isModalOpenPass} className={classes.modal}>
        <>
          <div className={classes.paper}>
            <h3
              style={{
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              }}
            >
              Enter your password to confirm
            </h3>
            <Input.Password
              name="password"
              onChange={handleChange}
              value={formState.values.password}
              placeholder="input password"
            />

            <div
              style={{
                display: "flex",
                gap: "10px",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={changePasswordHandler}
                variant="contained"
                color="primary"
                size="small"
              >
                Confirm
              </Button>
              <Button
                onClick={handleCancelPass}
                variant="outline"
                color="primary"
                size="small"
              >
                Cancel
              </Button>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};
export default ModalChangePass;

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { getBuyersFromCounty, getDispute } from "common/utils";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  formControl: {
    //margin: theme.spacing(1),
    minWidth: 100,
  },
  selectEmpty: {
    marginTop: theme.spacing(14),
  },
  select: {
    paddingRight: 0,
  },
}));

export default function SelectBuyer({
  leads,
  lead,
  setSendLeaderData,
  sendLeaderData,
  setIsApi,
  isApi,
  setLeads,
  getBuyerSubscriptionPrefix,
  role,
}) {
  const [open, setOpen] = useState(false);
  const [close, setClose] = useState(true);

  const classes = useStyles({});
  const [buyerMatched, setBuyerMatched] = useState(lead.buyerMatched);
  const tab = "\u00A0"; // White space

  const updateLeadAndLeads = async (updatedBuyerMatched) => {
    const updatedLead = { ...lead, buyerMatched: updatedBuyerMatched };
    const updatedLeads = await leads.map((l) =>
      l.id === lead.id ? updatedLead : l
    );
    await localStorage.setItem("leads", JSON.stringify(updatedLeads));
    await setLeads(updatedLeads);
  };

  const handleChange = async (event) => {
    if (event.target.value === undefined) {
      const updatedBuyerMatched = [{ id: "", name: "" }, ...buyerMatched];
      setBuyerMatched(updatedBuyerMatched);
      await updateLeadAndLeads(updatedBuyerMatched);
    } else {
      if (buyerMatched[0].name === "") {
        await buyerMatched.splice(0, 1);
        await updateLeadAndLeads(buyerMatched);
      } else {
        const selectedBuyer = lead.buyerMatched.find(
          (buyer) => buyer.id === event.target.value
        );
        const updatedBuyerMatched = [selectedBuyer, ...buyerMatched];
        setBuyerMatched(updatedBuyerMatched);
        await updateLeadAndLeads(updatedBuyerMatched);
      }

      setSendLeaderData({
        ...sendLeaderData,
        id: event.target.value,
      });

      if (event.target.value === "isApi") {
        setIsApi(true);
      }
    }
  };

  const onOpen = () => {
    setOpen(true);
    setClose(false);
  };

  const onClose = () => {
    setOpen(false);
    setClose(true);
  };
  // D = SUBSCRIPTION INITIAL

  // LR = LEADS REMAINING
  
  // DR = DAYS REMAINING
  
  // ##% = DISPUTE RATE
  
  // DLL = DAYS SINCE LAST LEAD
  // Buyer Name (D) (25 LR) (13 DR) (56%) (2DLL)
  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="input-select-buyer">Assign Buyer</InputLabel>
        <Select
          disabled={role === "ROLE_USER" ? true : false}
          key={lead.buyerMatched[0].id}
          className={classes.select}
          labelId="select-buyer-label"
          id="select-buyer"
          inputProps={{
            id: "uncontrolled-native",
          }}
          defaultValue={buyerMatched[0].id}
          onChange={handleChange}
          onOpen={onOpen}
          onClose={onClose}
        >
          <MenuItem key={999666}>{tab}</MenuItem>

          {lead.buyerMatched.map((buyer) => {
            return (
              <MenuItem
                key={buyer.id}
                className={classes.menuItem}
                value={buyer.id}
              >
                {open ? (
                  <>
                    {" "}

                    {buyer.name} {"    "}
                      {getBuyerSubscriptionPrefix(buyer)}
                    {"    "}
                    ({buyer.remainCount})
                    ({moment(buyer.lastLead).format("MM/DD/YY")}){"  "}
                    ({buyer.dispute})
                    {"    "} 
                    ({buyer.expiration
                      ? moment(buyer.expiration).format("MM/DD/YY")
                      : ""})
                  </>
                ) : (
                  <>{buyer.name}</>
                )}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}
